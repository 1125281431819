import Enhancer, {Generate} from "src/redux/enhancer";
import {signalR, EnhancersFrom} from "src/enums";

export const signalRActions = (storeName: string) => {
    return {
        retrieveVendors: () =>
            Generate(storeName, {lookup: true}, "retrieveVendors", EnhancersFrom.signalR, signalR.shared.retrieveVendors),

        retrieveCountries: () =>
            Generate(storeName, {lookup: true}, "retrieveCountries", EnhancersFrom.signalR, signalR.shared.retrieveCountries),

        getHelp: (endpoint: string) =>
            Generate(storeName, {endpoint}, "getHelp", EnhancersFrom.signalR, signalR.shared.getHelp),

        validateEmail: (email: string) =>
            Generate(storeName, {e_mail: email}, "validateEmail", EnhancersFrom.signalR, signalR.shared.validateEmail),

        verifyToken: (token: string, pin: string) =>
            Generate(storeName, {token, pin}, "verifyToken", EnhancersFrom.signalR, signalR.shared.verifyToken),

        getAuthorize: () =>
            Generate(storeName, {is_authorize: true}, "getAuthorized", EnhancersFrom.signalR, signalR.shared.isAuthorize),

        retrieveCompanyInfo: (country: "CZ" | "SK", ico: string) =>
            Generate(storeName, {
                country,
                ico
            }, "retrieveCompanyInfo", EnhancersFrom.signalR, signalR.shared.retrieveCompanyInfo),

        retrieveFirstPickupDate: (country: "CZ" | "SK") =>
            Generate(storeName, {country}, "retriveFirstPickupDate", EnhancersFrom.signalR, signalR.shared.retrieveFirstPickupDate),

        validatePickupDate: (country: "CZ" | "SK", date: string) =>
            Generate(storeName, {
                country,
                date
            }, "validatePickupDate", EnhancersFrom.signalR, signalR.shared.validatePickupDate),

        getHolidays: (country: "CZ" | "SK", year: number) =>
            Generate(storeName, {
                countryCode: country,
                year,
                yearOffset: 2
            }, "getHolidays", EnhancersFrom.signalR, signalR.shared.getHolidays),
    }

}

export const fetchActions = (storeName: string) => {
    return {
        RetrieveVendors: () =>
            Generate(storeName, null, "retrieveVendors", EnhancersFrom.fetch, undefined, "/api/shared/vendors", "GET"),
    }
}

export function ActionsEnhancer<T>(action: T, storeName: string) {
    return Enhancer(action, signalRActions(storeName), fetchActions(storeName), {});
}