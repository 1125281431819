import {useRts} from "src/provider";

export function DialogSelectCustomer() {
    const {Components, useState, UseDialog, Redux, useRef} = useRts();
    const {useStore, dispatch, actions} = Redux();
    const data = useStore.service_order.lookup.ocrd;
    const {InputGroup, Input, Flex, Lu, DialogCloseTrigger, Text, Spinner, Card, Avatar} = Components;
    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handleSelectItem = (e: string) => {
        setFilter(e);
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (e.length > 2) {
            timeoutRef.current = setTimeout(() => {
                setLoading(true);
                dispatch(actions.service_order.fetch.getOcrd({
                    ...data,
                    search: e
                })).then(() => setLoading(false)).catch(() => setLoading(false));
            }, 1000);
        }
    }

    return <Flex direction={"column"} minH={"100px"} gap={6}>
        <InputGroup startElement={<Lu.LuSearch/>} endElement={
            <DialogCloseTrigger
                onClick={() => UseDialog({open: false})}
                position={"absolute"}
                top={0}
                mt={"2px"}
                right={1}/>
        }>
            <Input placeholder={"Začni psát a spustí se vyhledávání..."} value={filter}
                   onChange={(e) => handleSelectItem(e.target.value)} autoFocus/>
        </InputGroup>
        {!loading && data.data.length === 0 && <Text ms={6}>Kde nic tu nic...</Text>}
        {loading && <Flex direction={"row"} gap={4}><Spinner ms={6}/> Hledám zákazníky...</Flex>}
        <Flex direction={"column"} gap={2}>
            {data.data.map((x) => (
                <Card isHoverable={true} key={x.cardCode}
                      bodyElement={<Flex direction={"row"} gap={6} align={"center"}>
                          <Avatar icon={<></>}><Lu.LuUser/></Avatar>
                          <Flex direction={"column"}>
                              <Text fontSize={"xs"}>{x.cardCode}</Text>
                              <Text fontSize={"sm"}>{x.cardName}</Text>
                          </Flex>

                      </Flex>} onClick={() => {
                }}/>
            ))}
        </Flex>
    </Flex>
}