import { createAnatomy } from "@zag-js/anatomy";
import { defineRecipe, defineSlotRecipe } from "@chakra-ui/react";
import { selectAnatomy as arkSelectAnatomy } from "@ark-ui/react"

export const recipe = defineRecipe;
export const slotRecipe = defineSlotRecipe;
export const alertAnatomy = createAnatomy<"root" | "content" | "title" | "description" | "indicator">("alertSlotRecipe");
export const recipeAnatomy = createAnatomy<"root" | "content" | "title" | "description" | "indicator">("recipeAnatomy");
export const radioCardAnatomy = createAnatomy<"root" | "item" | "itemContent" | "itemIndicator" | "indicator" | "label" | "itemText" | "itemControl" | "itemAddon" | "itemDescription">("radioCardSlotRecipe");
export const menuAnatomy = createAnatomy<"item" | "itemIndicator" | "positioner" | "content" | "separator" | "indicator" | "trigger" | "itemGroup" | "arrow" | "arrowTip" | "contextTrigger" | "itemGroupLabel" | "itemText" | "triggerItem" | "itemCommand">("menuSlotRecipe");
export const cardAnatomy = createAnatomy<"root" | "title" | "description" | "header" | "body" | "footer">("cardSlotRecipe");
export const dialogAnatomy = createAnatomy<"positioner" | "content" | "closeTrigger" | "title" | "description" | "header" | "body" | "footer" | "trigger" | "backdrop">("dialogSlotRecipe");
export const numberInputAnatomy = createAnatomy<"root" | "label" | "input" | "control" | "valueText" | "incrementTrigger" | "decrementTrigger" | "scrubber">("numberInputSlotRecipe");
export const selectAnatomy = createAnatomy<"root" | "item" | "itemIndicator" | "positioner" | "content" | "indicator" | "list" | "control" | "label" | "trigger" | "itemGroup" | "clearTrigger" | "itemGroupLabel" | "itemText" | "valueText" | "indicatorGroup">("selectSlotRecipe");
export const datepickerAnatomy = createAnatomy<"root" | "content" | "trigger" | "header" | "body" | "footer" | "calendar" | "month" | "year" | "table" | "tableRow" | "tableCell" | "tableCellTrigger" | "tableHeader" | "tableBody" | "tableHead" | "tableBodyRow" | "tableBodyCell" | "tableBodyCellTrigger" | "tableBodyHeader" | "tableBodyRowTrigger" | "input">("datepickerSlotRecipe");
export const nativeSelectAnatomy = arkSelectAnatomy.extendWith("indicatorGroup")