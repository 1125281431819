import Cam from "react-webcam";
import React, {forwardRef} from "react";
import {
    Text,
    Tag,
    Button,
    Image,
    ClipboardIconButton,
    ClipboardInput,
    ClipboardRoot,
    Fa,
    DialogRoot,
    DialogContent,
    DialogHeader,
    Box,
    InputGroup,
    DialogCloseTrigger,
    DialogBody,
    MenuContent,
    MenuTrigger,
    MenuRoot,
    MenuRadioItemGroup,
    MenuRadioItem,
    Flex,
    Lu,
    Badge,
    DrawerRoot,
    DrawerContent,
    DrawerHeader,
    DrawerCloseTrigger,
    DrawerBody,
    Card, Input, FileUploadRoot,
} from "src/components";
import {useLocation} from "src/core";
import {QrCode} from "@ark-ui/react";
import RtsOverlay from "../../assets/img/carriers/favicon.png";
import {Camera} from "src/types";
import {FACING_MODE_ENVIRONMENT, FACING_MODE_USER} from "./enums";
import {useRedux} from "src/redux/provider";
import {sendMessage} from "src/lib/signalR";
import {handleGetFileName} from "src/components/camera/handlers";
import {CameraFile} from "src/types/camera";
import {FileUploadTrigger} from "src/components/ui/file-button";

const ConnectState = () => {
    const {useStore, dispatch} = useRedux();
    if (useStore.core.camera.isConnectParent) {
        return (
            <Text>
                Stav připojení: <Tag colorPalette="green">Připojeno</Tag>
            </Text>
        );
    } else {
        return (
            <Text>
                Stav připojení: <Tag colorPalette="red">Neřipojeno</Tag>
            </Text>
        );
    }
};

export const MobileConnect = () => {
    const {useStore, dispatch, actions} = useRedux();
    return (
        <>
            {!useStore.core.camera.asChild && (
                <Button
                    borderRadius={"50%"}
                    mt={3}
                    py={6}
                    border={"1px solid #f8f8f8"}
                    variant={"ghost"}
                    color={"white"}
                    onClick={() => dispatch(actions.core.setCameraOpenMobile(true))}
                >
                    <Fa.FaMobileAlt/>
                </Button>
            )}
            <DialogRoot open={useStore.core.camera.openMobile}>
                <DialogContent>
                    <DialogHeader>
                        Focení s použitím mobilního zařízení
                        <DialogCloseTrigger
                            onClick={() => dispatch(actions.core.setCameraOpenMobile(false))}/>
                    </DialogHeader>
                    <DialogBody>
                        <Text>
                            Focení s použitím mobilního zařízení je možné pouze na mobilním
                            zařízení. Pro pokračování využijte mobilní zařízení. Naskenujte QR
                            kód nebo otevřete odkaz v mobilním zařízení. <br></br>
                            <br></br>
                            <b>
                                Toto okno můžeš zavřít jakmile se spojíš s mobilním zařízením.
                            </b>
                        </Text>
                        <Box bg={"white"} width={"100%"} mt={6} mb={4}>
                            <QrCode.Root
                                value={`${window.location.origin}/#/mobile/scan?connectionId=${useStore.core.signalr.connection_id}`}
                                encoding={{ecc: "H"}}
                            >
                                <QrCode.Frame>
                                    <QrCode.Pattern/>
                                </QrCode.Frame>
                                <QrCode.Overlay>
                                    <img src={RtsOverlay} alt="RtsOverlay"/>
                                </QrCode.Overlay>
                            </QrCode.Root>
                        </Box>
                        <ConnectState/>
                        <ClipboardRoot
                            mt={6}
                            value={`${window.location.origin}/#/mobile/scan?connectionId=${useStore.core.signalr.connection_id}`}
                        >
                            <InputGroup
                                width="full"
                                endElement={<ClipboardIconButton me="-2"/>}
                            >
                                <ClipboardInput/>
                            </InputGroup>
                        </ClipboardRoot>
                    </DialogBody>
                </DialogContent>
            </DialogRoot>
        </>
    );
};

export const CameraModule = forwardRef<Cam, {
    resolution: {
        width: number;
        height: number;
        facingMode: string;
    }
}>((props, ref) => {
    return <Cam
        style={{
            position: "absolute",
            textAlign: "center",
            right: 0,
            top: 0,
            zIndex: 0,
            height: "100vh",
            width: "100%",
            objectFit: "fill",
        }}
        audio={false}
        ref={ref}
        width={props.resolution.width}
        height={props.resolution.height}
        forceScreenshotSourceSize={true}
        screenshotQuality={1}
        videoConstraints={props.resolution}
        screenshotFormat="image/jpeg"
    />
});

export const CameraCaptureEffect = () => {
    return (<div
        style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "gray",
            zIndex: 9999,
            opacity: 0.6,
        }}
    ></div>);
};

export const SelectDocumentType = () => {
    const {useStore, dispatch, actions} = useRedux();
    return (
        <MenuRoot
            positioning={{placement: "top"}}
            closeOnSelect={false}
            open={useStore.core.camera.openDoctypeMenu}
            onEscapeKeyDown={() => dispatch(actions.core.setCameraOpenDoctypeMenu(false))}
        >
            <MenuTrigger>
                <Button
                    variant="ghost"
                    borderRadius={"50%"}
                    py={6}
                    px={useStore.core.camera.openDoctypeMenu ? 5 : 4}
                    border={"1px solid #f8f8f8"}
                    size="sm"
                    color={"white"}
                    zIndex={1402}
                    onClick={() => useStore.core.camera.openDoctypeMenu ?
                        dispatch(actions.core.setCameraOpenDoctypeMenu(false)) :
                        dispatch(actions.core.setCameraOpenDoctypeMenu(true))}
                >
                    {useStore.core.camera.openDoctypeMenu ? "X" : useStore.core.camera.documentType}
                </Button>
            </MenuTrigger>
            <MenuContent zIndex={9999}>
                <MenuRadioItemGroup
                    value={useStore.core.camera.documentType}
                    onValueChange={(e: any) => {
                        sendMessage("ChangeDocType", {
                            docType: e.value,
                            groupName: useStore.core.camera.asChild ? useStore.core.camera.parentId : useStore.core.signalr.connection_id
                        });
                        dispatch(actions.core.setCameraOpenDoctypeMenu(false))
                    }}
                >
                    <MenuRadioItem value="SD">Dokumentace</MenuRadioItem>
                    <MenuRadioItem value="FA">Faktura</MenuRadioItem>
                    <MenuRadioItem value="SP">Poškození</MenuRadioItem>
                    <MenuRadioItem value="BT">Baterie</MenuRadioItem>
                    <MenuRadioItem value="SL">Servisní list</MenuRadioItem>
                    <MenuRadioItem value="WT">Watter test</MenuRadioItem>
                    <MenuRadioItem value="VT">Výstupní test</MenuRadioItem>
                    <MenuRadioItem value="SN">SN štítek</MenuRadioItem>
                </MenuRadioItemGroup>
            </MenuContent>
        </MenuRoot>
    );
};

export const CameraActionPanel = ({files, onFileRemove, onCaptureFile, onSelectFiles}: {
    files: Camera.CameraFile[]
    onFileRemove: (e: Camera.CameraFile) => void
    onCaptureFile: () => void
    onSelectFiles: (e: any) => void
}) => {
    const {useStore, dispatch, actions} = useRedux();

    return <Flex
        zIndex={1402}
        px={6}
        justifyContent={"space-between"}
        height={"70px"}
        position={"absolute"}
        width={"100%"}
        bottom={0}
        left={0}
        bg={"rgba(24,24,24,.3)"}
    >
        <Flex>
            {!useStore.core.camera.asChild && (
                <FileViewer files={files} onFileRemove={onFileRemove} onFileChange={() => {
                }}/>
            )}
            <SelectDocumentType/>
        </Flex>
        <Flex gap={2}>
            <Button
                borderRadius={"50%"}
                mt={3}
                py={6}
                border={"1px solid #f8f8f8"}
                variant={"ghost"}
                onClick={onCaptureFile}
                autoFocus={true}
                color={"white"}
            >
                <Lu.LuCamera/>
            </Button>
            <FileUploadRoot onChange={onSelectFiles}>
                <FileUploadTrigger>
                    <Button
                        borderRadius={"50%"}
                        mt={3}
                        py={6}
                        border={"1px solid #f8f8f8"}
                        variant={"ghost"}
                        color={"white"}
                    >
                        <Lu.LuFolderInput/>
                    </Button>
                </FileUploadTrigger>
            </FileUploadRoot>

        </Flex>
        <Flex gap={2}>
            <Button
                borderRadius={"50%"}
                mt={3}
                py={6}
                border={"1px solid #f8f8f8"}
                variant={"ghost"}
                color={"white"}
                onClick={() => dispatch(actions.core.setCameraResolution({
                    ...useStore.core.camera.resolution,
                    facingMode: useStore.core.camera.resolution.facingMode === FACING_MODE_ENVIRONMENT ? FACING_MODE_USER : FACING_MODE_ENVIRONMENT,
                }))}
            >
                <Lu.LuSwitchCamera/>
            </Button>
            <MobileConnect/>
        </Flex>
    </Flex>
};

export const FileViewer = ({files, onFileRemove, onFileChange}: {
    files: Camera.CameraFile[],
    onFileRemove: (e: Camera.CameraFile) => void
    onFileChange: (e: Camera.CameraFile) => void
}) => {
    const {useStore, dispatch, actions, dispatchAsync} = useRedux();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const docNo = searchParams.get("docNo") ?? searchParams.get("doc");
    const handleEditFile = (file: Camera.CameraFile) => {
        dispatch(actions.core.setEditorState({open: true, file: file}));
    };
    const handleFromClipboard = async () => {
        try {
            const clipboardItems = await navigator.clipboard.read();
            for (const item of clipboardItems) {
                if (item.types.includes('image/png') || item.types.includes('image/jpeg')) {
                    const blob = await item.getType(item.types.find(type => type.startsWith('image/') ?? '') ?? '');
                    const reader = new FileReader();
                    reader.onload = () => {
                        const img = reader.result as string;
                        const cameraFile = {
                            content: img,
                            type: useStore.core.camera.documentType,
                            name: `${handleGetFileName()}.png`,
                        } as CameraFile;
                        dispatch(actions.core.setCameraFile(cameraFile));
                    };
                    reader.readAsDataURL(blob);
                    break;
                }
            }
        } catch (error) {
            console.error('Failed to read clipboard contents: ', error);
        }
    };

    const handleSaveToDoc = async () => dispatchAsync(actions.core.extra.SaveToDoc(files, docNo ?? ""));


    return (<>
            <Flex align={"center"}>
                <Box
                    background={"rgba(255,255,255,.3)"}
                    borderRadius={"12px"}
                    width={"54px"}
                    height={"54px"}
                    onClick={() => dispatch(actions.core.setCameraOpenFileViewer(true))}
                    cursor={"pointer"}
                >
                    <Image
                        height={"54px"}
                        src={files[files.length - 1]?.content}
                    />
                </Box>
                <Badge
                    onClick={() => dispatch(actions.core.setCameraOpenFileViewer(true))}
                    cursor={"pointer"}
                    position={"relative"}
                    width="25px"
                    height={"25px"}
                    bg={"rgba(192,0,0,.6)"}
                    outlineColor="rgba(192,0,0,.6)"
                    fontWeight={"bold"}
                    color={"white"}
                    mb={"45px"}
                    ms={-2}
                >
                    {files.length}
                </Badge>
            </Flex>

            <DrawerRoot open={useStore.core.camera.openFileViewer} placement={"start"} size={"md"}>
                <DrawerContent>
                    <DrawerHeader>
                        Správa souborů
                        {docNo && (
                            <Button size={"sm"} ms={6} variant={"surface"} onClick={handleSaveToDoc}>Uložit do
                                zakázky</Button>
                        )}
                        <Button size={"sm"} ms={6} variant={"surface"} onClick={handleFromClipboard}>Vložit ze
                            schránky</Button>
                        <DrawerCloseTrigger
                            onClick={() => dispatch(actions.core.setCameraOpenFileViewer(false))}/>
                    </DrawerHeader>
                    <DrawerBody>
                        {files.map((file, index) => (
                            <Card.Root key={index}>
                                <Card.Header>
                                    <Flex justifyContent={"space-between"} align={"center"}>
                                        <Text>{file.name}</Text>
                                        <Flex>
                                            <Button
                                                onClick={() => handleEditFile(file)}
                                                variant={"ghost"}
                                            >
                                                <Lu.LuPenSquare/>
                                            </Button>
                                            <Button
                                                onClick={() => onFileRemove(file)}
                                                variant={"ghost"}
                                            >
                                                <Lu.LuTrash/>
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </Card.Header>
                                <Card.Body>
                                    <Image borderRadius={"15px"} src={file.content}/>
                                </Card.Body>
                            </Card.Root>
                        ))}
                    </DrawerBody>
                </DrawerContent>
            </DrawerRoot>
        </>
    );
};