import undraw from "../../assets/img/undraw_devices_re_dxae.svg";
import {useRts} from "src/provider";

export function ServiceRequest() {
    const {Redux, View, Enums, Components, useEffect} = useRts();
    const {Image, Flex, Alert} = Components;
    const {serviceRequest} = View;
    const {Countries} = Enums;
    const {useStore, dispatch, dispatchAsync, actions} = Redux();
    useEffect(() => {
        dispatch(actions.shared.signalR.retrieveFirstPickupDate(Countries.Czech));
        if (useStore.service_request.activeStep === -1)
            dispatchAsync(actions.shared.signalR.getAuthorize()).then(() => {
            });

        dispatch(actions.service_request.setEmail(useStore.users.logged?.email ?? ""));
    }, []);

    return (
        <Flex
            direction={"column"}
            p={6}
        >
            <Image
                display={{base: "none", md: "block"}}
                src={undraw}
                bg={"transparent"}
                position={"fixed"}
                bottom={5}
                right={0}
                width={250}
            />

            {/* Zobrazujeme stepper a ovládací tlačítka až když se zadává nový požadavek */}
            {useStore.service_request.activeStep > -1 && !useStore.service_request.is_send && (
                <>
                    <serviceRequest.components.Stepper/>
                    <serviceRequest.components.ControllerForm/>
                </>
            )}
            {useStore.service_request.is_send && <serviceRequest.components.Finish/>}
            {!useStore.service_request.is_send && (
                <>
                    <serviceRequest.components.AddressDialogIco/>
                    <serviceRequest.components.AddressDialog/>
                    <serviceRequest.components.DeviceEditor/>
                    <Flex direction={"column"} mt={6} gap={4}>
                        {useStore.service_request.any_error !== "" && (
                            <Alert status="error">
                                {useStore.service_request.any_error}
                            </Alert>
                        )}
                        {useStore.service_request.activeStep === -1 && <serviceRequest.components.Verification/>}
                        {useStore.service_request.activeStep === 0 && <serviceRequest.components.Card/>}
                        {useStore.service_request.activeStep === 0 && <serviceRequest.extenders.PosamSk/>}
                        {useStore.service_request.activeStep === 1 && <serviceRequest.components.Device/>}
                        {useStore.service_request.activeStep === 2 && <serviceRequest.components.Transport/>}
                        {useStore.service_request.activeStep === 3 && <serviceRequest.components.Summary/>}
                        {useStore.service_request.activeStep > -1 && <serviceRequest.components.ControllerForm/>}
                    </Flex>
                </>
            )}
        </Flex>
    );
}
