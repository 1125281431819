import {
    Card,
    Flex,
    Text,
    Button,
    Lu,
    Field,
    Input,
    Avatar,
    Fa,
    Tag, Md, UseForm, Tabs, Alert
} from "src/components";
import {useRedux} from "src/redux/provider";
import SoAddParts from "./addParts";
import React from "react";
import {useEffect} from "src/core";
import {AddPartsType, InputType} from "src/enums";
import {__server} from "src/config";
import {PriceProposalDefaultTexts} from "src/types/rts";
import SoPriceProposalText from "src/views/serviceOrder/components/so.detail.priceproposal.text";
import SoPriceProposalItems from "src/views/serviceOrder/components/so.detail.priceproposal.items";
import SoPriceProposalFiles from "src/views/serviceOrder/components/so.detail.priceproposal.files";


export default function SoPriceProposal() {
    const {useStore, dispatch, actions, dispatchAsync} = useRedux();
    const [useText, setText] = React.useState("");
    const {items} = useStore.service_order
    const data = useStore.service_order.detail;
    const handleChangeReason = (e: any) =>
        dispatch(actions.service_order.onChangeReason(e.value ?? ""));

    const btnText = () => data.so?.docStatus === 8825 ? "Vystavit nabídku" : "Odemknout pro změnu"

    const handleChangeText = (e: any) => {
        setText(e)
        dispatch(actions.service_order.retrieveDetailData({
            ...data,
            so: {...data.so!, priceProposalDefectDesc: types.find(x => x.value === e[0])?.text ?? ""}
        }));
    }

    const types = data.texts.map((x: PriceProposalDefaultTexts) => ({
        value: x.id,
        label: x.type,
        text: x.text,
        photo_count: x.photo_count
    }));

    const spCount = data?.files.filter(x => x.docType === 5).length;
    const reqSpCount = types.find(x => x.value === JSON.parse(data.udf?.alL_PriceText ?? "[]")[0])?.photo_count ?? 0;

    const validatePhoto = () => reqSpCount <= spCount;

    return (

        <Flex w={"100%"} direction={"column"}>
            <SoPriceProposalText/>
            <SoAddParts type={AddPartsType.Calculation}/>
            <Flex
                flexDirection={"row"}
                justifyContent={"space-between"}
                align={"center"}
            >
                <Text fontSize={"sm"} fontWeight={"bold"}>
                    Cenová nabídka
                </Text>

                {data.so?.docStatus === 8825 &&
                    <Button variant={"surface"} size={"sm"}
                            onClick={() => dispatchAsync(actions.service_order.fetch.createPriceProposal(
                                data.so?.docNo ?? 0,
                                data.priceProposal?.emailTo ?? [],
                                data.priceProposal?.emailCc ?? []
                            ))}>Vystavit nabídku</Button>}
                {(data.so?.docStatus === 8856 || data.so?.docStatus === 8839) &&
                    <Button variant={"surface"} size={"sm"}
                            onClick={() => dispatchAsync(actions.service_order.fetch.changeStatus(
                                data.so?.docNo ?? 0,
                                8825,
                                false
                            ))}><Lu.LuLock/> Odemknout pro
                        změnu</Button>}
            </Flex>
            {data.so?.docStatus !== 8825 && (
                <Alert status={"warning"}>
                    Stav zakázky musí být Příprava CN. Jinak nelze nabídku vytvořit.
                </Alert>
            )}
            <Text
                fontSize={"xs"}>{useStore.service_order.deviceRepairTypeId == 4 ? useStore.service_order.reseller : useStore.service_order.customerName}</Text>

            <Tabs.Root defaultValue="general" variant={"line"}>
                <Tabs.List>
                    <Tabs.Trigger value="general">
                        {(data.udf?.alL_PriceText === "" || data.udf?.alL_PriceText === null || data.udf?.alL_PriceText === undefined || data.so?.priceProposalDefectDesc?.length == 0) &&
                            <Fa.FaExclamationTriangle color={"orange"}/>}
                        Hlavička
                    </Tabs.Trigger>
                    <Tabs.Trigger value="items">
                        {data.work.filter(x => x.reportingPriceProp).length === 0
                            && data.items.filter(x => x.status === "A").length < 2
                            && <Fa.FaExclamationTriangle color={"orange"}/>}
                        Položky nabídky
                        <Tag
                            colorPalette={"green"}>{data.items.length + data.work.filter(x => x.reportingPriceProp).length}</Tag>
                    </Tabs.Trigger>
                    <Tabs.Trigger value="attachments">
                        {!validatePhoto() && <Fa.FaExclamationTriangle color={"orange"}/>}
                        Přílohy nabídky <Tag
                        colorPalette={"green"}>{data.files.filter(x => x.docType === 5).length + 2}</Tag>
                    </Tabs.Trigger>
                </Tabs.List>
                <Tabs.Content value="general">
                    <Flex flexDirection={"column"} gap={4} mt={4}>
                        <UseForm
                            label={"Komu"}
                            required
                            disabled={data.so?.docStatus !== 8825}
                            type={InputType.tagsinput}
                            value={data.priceProposal?.emailTo ?? []}
                            onChange={(e) => dispatch(actions.service_order.retrieveDetailData({
                                ...data,
                                priceProposal: {
                                    ...data.priceProposal!,
                                    emailTo: e
                                }
                            }))}/>
                        <UseForm
                            label={"Kopie"}
                            type={InputType.tagsinput}
                            disabled={data.so?.docStatus !== 8825}
                            value={data.priceProposal?.emailCc ?? []}
                            onChange={(e) => dispatch(actions.service_order.retrieveDetailData({
                                ...data,
                                priceProposal: {
                                    ...data.priceProposal!,
                                    emailCc: e
                                }
                            }))}/>
                        <UseForm
                            label={"Důvod cenové nabídky"}
                            type={InputType.select}
                            placeholder={"Vyber důvod nabídky"}
                            disabled={data.so?.docStatus !== 8825}
                            value={JSON.parse(data.udf?.alL_PriceText ?? "[]")}
                            collection={types}
                            onChange={(e) => {
                                dispatchAsync(actions.service_order.fetch.changePriceDesc(data.so?.docNo ?? 0, JSON.stringify(e), types.find(x => x.value === e[0])?.text ?? ""))
                            }}/>

                        <UseForm
                            label={"Textace"}
                            type={InputType.textarea}
                            rows={6}
                            disabled={data.so?.docStatus !== 8825 || useStore.service_order.detail.so?.priceProposalDefectDesc === ""}
                            value={useStore.service_order.detail.so?.priceProposalDefectDesc}
                            onChange={(e) => dispatch(actions.service_order.retrivePartialDetailData({
                                so: {
                                    ...data.so!,
                                    priceProposalDefectDesc: e
                                }
                            }))}
                            onBlur={(e) => {
                                dispatchAsync(actions.service_order.fetch.changePriceDesc(data.so?.docNo ?? 0, data.udf?.alL_PriceText ?? "[]", e))
                            }}/>

                    </Flex>
                </Tabs.Content>
                <Tabs.Content value="items">
                    <Field mt={4}
                           label={"Položky nabídky"}
                           required
                           helperText={`Celková cena nabídky je ${useStore.service_order.detail.totalPriceProposalPrice} ${useStore.service_order.detail.items[0]?.currCode}`}>
                        <SoPriceProposalItems/>
                    </Field>
                </Tabs.Content>
                <Tabs.Content value="attachments">
                    <SoPriceProposalFiles/>
                </Tabs.Content>
            </Tabs.Root>

        </Flex>
    );
}
