import {useState, useEffect, Portal} from "src/items";
import {useRedux} from "src/redux/provider";
import routes from "../rts.routes";
import Sidebar from "../components/Sidebar/Sidebar";
import MainPanel from "../components/MainPanel/mainPanel";
import PanelContent from "../components/MainPanel/panelContent";
import PanelContainer from "../components/MainPanel/panelContainer";
import Navbar from "../components/NavBar/navbar";
import {Route, Routes} from "react-router-dom";
import Footer from "../components/Footer/Footer";
import {Unauthorized} from "../views/pages";


export const MainLayout = () => {
    const {useStore} = useRedux();
    const [routeList, setRoutes] = useState<any[]>([]);
    const getRoute = () => {
        return window.location.pathname !== "/full-screen-maps";
    };

    useEffect(() => {
        let groupList = useStore.users.logged?.group_list ?? [];
        setRoutes(routes(groupList));
    }, []);

    const getActiveRoute = (r: any) => {
        let activeRoute = process.env.REACT_APP_DEFAULT_PATH;
        for (let i = 0; i < r.length; i++) {
            if (r[i].collapse) {
                let collapseActiveRoute: any = getActiveRoute(r[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else if (r[i].category) {
                let categoryActiveRoute: any = getActiveRoute(r[i].views);
                if (categoryActiveRoute !== activeRoute) {
                    return categoryActiveRoute;
                }
            } else {
                if (window.location.pathname === r[i].path) {
                    return r[i].name;
                }
            }
        }
        return activeRoute;
    };

    const getActiveNavbar = (r: any) => {
        let activeNavbar = false;
        for (let i = 0; i < routes.length; i++) {
            if (r[i].category) {
                let categoryActiveNavbar: any = getActiveNavbar(r[i].views);
                if (categoryActiveNavbar !== activeNavbar) {
                    return categoryActiveNavbar;
                }
            } else {
                if (window.location.pathname === r[i].path) {
                    if (r[i].secondaryNavbar) {
                        return r[i].secondaryNavbar;
                    }
                }
            }
        }
        return activeNavbar;
    };

    const getRoutes: any = (r: any[]) => {
        return r?.map((prop: any, key: any) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.category === "navbar") {
                return getRoutes(prop.views);
            }
            if (prop.layout === "") {
                return (
                    <Route
                        path={prop.path}
                        element={prop.component}
                        errorElement={<Unauthorized/>}
                        key={key}
                    />
                );
            } else {
                return <></>;
            }
        });
    };

    return (
        <>
            <Sidebar/>
            <MainPanel
                width={{
                    base: "100%",
                    xl: "calc(100% - 275px)",
                }}
            >
                <Portal>
                    <Navbar/>
                </Portal>
                <PanelContent>
                    <PanelContainer>
                        <Routes>{getRoutes(routeList)}</Routes>
                    </PanelContainer>
                </PanelContent>
                <Footer/>
            </MainPanel>

        </>
    );
};

