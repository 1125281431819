import {Button, Lu} from "src/components";
import React from "react";
import {useRedux} from "src/redux/provider";
import SoDetailOtherActions from "src/views/serviceOrder/components/so.detail.otheractions";

export default function SoDetailFooter() {
    const {useStore, dispatch, actions} = useRedux();
    const data = useStore.service_order.detail;
    return <>
        <SoDetailOtherActions/>
        <Button variant={"outline"}
                size={"sm"}
                gap={2}
                onClick={() => dispatch(actions.core.setCameraOpen({state: true, files: []}))}>
            <Lu.LuCamera/>
            Kamera
        </Button></>
}