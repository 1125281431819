import type {User, UserDetail} from "src/types/users";
import {__AuthToken} from "src/config";


export const fakeLogin = (state: User, action: { payload: UserDetail }) => {
    const payload: UserDetail[] = [action.payload];
    LogIn(state, {payload: payload});
};

export const LoginOAuth2 = (state: User, action: { payload: UserDetail }) => {
    state.logged = action.payload;
    if (state.logged?.access_token !== undefined) {
        state.isAuth = true;
        window.localStorage.setItem("token", state.logged?.access_token);
    }

}

export const setStatusCode = (state: User, action: { payload: number }) => {
    state.statusCode = action.payload;
};

export const GetUserDetail = (state: User, action: { payload: UserDetail }) => {
    state.logged = action.payload;
    state.isAuth = state.logged?.access_token !== undefined;
}

export const GetAuthToken = (state: User, action: {
    payload: string
}) => {
    state.token = action.payload;
    state.username = "";
    state.password = "";
};

export const LogIn = (state: User, action: { payload: UserDetail[] }) => {
    if (action.payload !== null && action.payload?.length === 1) {
        state.logged = action.payload[0];
        if (state.logged?.access_token !== undefined)
            window.localStorage.setItem("token", state.logged?.access_token);
    }

    state.isAuth = state.logged?.access_token !== undefined;
}
export const Auth = (state: User, action: { payload: UserDetail[] }) => {
    if (action.payload !== null && action.payload?.length === 1) {
        LogIn(state, action);
    } else {
        //LogOut(state);
    }
}

export const setPassWord = (state: User, action: { payload: string }) => {
    state.password = action.payload;
}
export const setUserName = (state: User, action: { payload: string }) => {
    state.username = action.payload;
}
export const LogOut = (state: User) => {
    state.isAuth = false;
    state.logged = null;
    state.token = "";
    state.statusCode = 0;
    state.username = "";
    state.password = "";
    localStorage.removeItem("token");
};
