import React from "react";
import {CardHover} from "src/theme";
import {PriceProposalCustomer} from "src/types/rts";
import {CustomerAddOrUpdate} from "./customer.addorupdate";
import {CustomerAddOrUpdateAction}
    from "./customer.addorupdate.action";
import {useRts} from "src/provider";


export function CustomerItem(props: { item: PriceProposalCustomer }) {
    const {UseDialog, Redux, Components} = useRts();
    const {Avatar, Card, Fa, Flex, Lu, Text} = Components;
    const {dispatch, actions} = Redux();
    return (
        <Card key={props.item.id} isHoverable={true} variant={"subtle"} size={"sm"}
              onClick={() => {
                  dispatch(actions.admin.setPriceProposalCustomerData(props.item))
                  UseDialog({
                      open: true,
                      title: "Upravit větu pro výrobce",
                      size: "xl",
                      noPadding: false,
                      bodyElement: <CustomerAddOrUpdate/>,
                      footerElement: <CustomerAddOrUpdateAction/>,
                      closable: true,
                  });
              }} bodyElement={<Flex
            direction={"row"}
            gap={4}
            align={"center"}
            alignItems={"center"}
            justifyContent={"space-between"}
        >
            <Flex direction={"row"} gap={4}>
                <Avatar icon={<></>}><Lu.LuPencil/></Avatar>
                <Flex direction={"column"}>
                    <Text fontSize={"sm"} fontWeight={"bold"}>
                        {props.item.customer}
                    </Text>
                    <Text fontSize={"xs"}>{props.item.query}</Text>
                </Flex>
            </Flex>
            <Fa.FaArrowRight/>
        </Flex>}/>
    )

}