import {RtsContextType} from "src/types";
import {useRedux} from "src/redux/provider";
import {useSignalR} from "src/lib/signalR.provider";
import type {UseDrawerProps, UseDialogProps} from "src/types/core"
import * as Components from "src/components";
import * as Core from "src/core";
import * as Types from "src/types";
import * as Lib from "src/lib";
import * as Enums from "src/enums";
import * as View from "src/views";

const RtsContext = Core.createContext<RtsContextType | undefined>(
    undefined
);

export const useRts = () => {
    const context = Core.useContext(RtsContext);
    if (!context) {
        throw new Error("useRts must be used within an DispatchContext");
    }
    return context;
};
export const RtsProvider:
    React.FC<{ children: React.ReactNode }> = (props) => {
    const [drawer, setDrawer] = Core.useState<UseDrawerProps>({
        open: false
    });

    const [dialog, setDialog] = Core.useState<UseDialogProps>({
        open: false
    });

    return (
        <RtsContext.Provider value={{
            Components,
            Core,
            Types: Types,
            Lib,
            Enums,
            View,
            SignalR: useSignalR,
            Redux: useRedux,
            UseForm: Components.UseForm,
            UseDrawer: setDrawer,
            UseDialog: setDialog,
            useRef: Core.useRef,
            useState: Core.useState,
            useEffect: Core.useEffect,
        }}>
            <Components.UseDrawerHelper {...drawer}
                                        internalSetSidebarItem={(e) => {
                                            if (e === null) console.log(e);
                                            setDrawer({...drawer, sidebarItem: e});
                                        }}
                                        internalClose={() => setDrawer({open: false})}/>

            <Components.UseDialogHelper {...dialog}
                                        internalClose={() => setDialog({open: false})}/>

            {props.children}
        </RtsContext.Provider>
    );
};
