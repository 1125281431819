import React from "react";
import {useRts} from "src/provider";

export default function SoDetailHeader() {
    const {Redux, Components} = useRts();
    const {Flex, Badge} = Components;
    const {useStore} = Redux();
    const data = useStore.service_order.detail;
    return <Flex direction="row" gap={4}>
        <Badge p={2} colorPalette="gray">
            Owner: {data?.so?.userManager}
        </Badge>
        <Badge p={2} colorPalette="green">
            Status: {data?.so?.docStatus}
        </Badge>
    </Flex>
}