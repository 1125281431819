import {slotRecipe, nativeSelectAnatomy} from "../recipe.anatomy";

export const nativeSelectSlotRecipe = slotRecipe({
    className: "chakra-native-select",
    slots: nativeSelectAnatomy.keys(),
    base: {
        root: {
            borderRadius: "15px",
            width: "100%"
        },
        
    },
});

