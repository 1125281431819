import {TagsInput} from '@ark-ui/react'
import {forwardRef, ReactElement} from "react";
import {createRecipeContext, HTMLChakraProps, RecipeProps, ChakraComponent} from "@chakra-ui/react";
import {LuX, LuTrash2} from "react-icons/lu";


export interface LinkButtonProps
    extends HTMLChakraProps<"div", RecipeProps<"select">> {
}

const {withContext} = createRecipeContext({key: "select"})

function ValidJSXSelectContext(props: TagsInput.ContextProps): ReactElement {
    return TagsInput.Context(props) as ReactElement;
}

export interface TagsInputProps extends TagsInput.RootProps {
}

export const Root = forwardRef<HTMLDivElement, TagsInputProps>(
    function Root(props, ref) {
        return (
            <TagsInput.Root
                {...props}
                ref={ref}
                style={{
                    width: "100%",
                    minWidth: "0",
                    outline: "0",
                    position: "relative",
                    appearance: "none",
                    textAlign: "start",
                    borderRadius: "15px",
                    fontSize: "var(--rts-font-sizes-sm)",
                    lineHeight: "1.25rem",
                    paddingInline: "var(--rts-spacing-3)",
                    paddingBlock: "var(--rts-spacing-2)",
                    scrollPaddingBottom: "var(--rts-spacing-2)",
                    background: "var(--rts-colors-transparent)",
                    borderWidth: "1px",
                    borderColor: "var(--rts-colors-border)",
                }}
            />
        )
    },
)


export interface TagsInputContextProps {
    label?: string | undefined;
    placeholder?: string | undefined;
    clearTriggerElement?: React.ReactNode;
    itemDeleteTriggerElement?: React.ReactNode;
}

export const TagsInputRecipe = withContext<HTMLSpanElement, TagsInputContextProps>("span")

export const Context = forwardRef<
    HTMLSpanElement,
    TagsInputContextProps
>(function Context(props, ref) {
    const {label, clearTriggerElement, itemDeleteTriggerElement, placeholder, ...rest} = props
    return (
        <ValidJSXSelectContext>
            {(tagsInput) => (
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    width: "100%",
                    minWidth: "0",
                    outline: "0",
                    position: "relative",
                    appearance: "none",
                    textAlign: "start",
                    borderRadius: "15px",
                    fontSize: "var(--rts-font-sizes-sm)",
                    lineHeight: "1.25rem",
                    justifyContent: "space-between",
                    // paddingInline: "var(--rts-spacing-3)",
                    // paddingBlock: "var(--rts-spacing-2)",
                    scrollPaddingBottom: "var(--rts-spacing-2)",
                    background: "var(--rts-colors-transparent)",
                }}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        flexWrap: "wrap",
                        minWidth: "0",
                        outline: "0",
                        gap: "var(--rts-spacing-1)",
                        position: "relative",
                        appearance: "none",
                        textAlign: "start",
                        maxWidth: "80%",
                        borderRadius: "15px",
                        fontSize: "var(--rts-font-sizes-sm)",
                        lineHeight: "1.25rem",
                        justifyContent: "space-between",
                        scrollPaddingBottom: "var(--rts-spacing-2)",
                        background: "var(--rts-colors-transparent)",
                    }}>
                        <TagsInput.Control style={{
                            outline: "0",
                            position: "relative",
                            display: "flex",
                            gap: "var(--rts-spacing-1)",
                            flexWrap: "wrap",
                            lineHeight: "1.25rem",
                            background: "var(--rts-colors-transparent)",
                        }}>
                            {tagsInput.value.map((value, index) => (
                                <TagsInput.Item disabled key={index} index={index} value={value} 
                                                style={{
                                                    position: "relative",
                                                    borderRadius: "15px",
                                                    marginInline: "var(--rts-spacing-1)",
                                                    fontSize: "var(--rts-font-sizes-sm)",
                                                    backgroundColor: "var(--rts-colors-border)",
                                                    paddingInline: "var(--rts-spacing-2)",
                                                    //paddingBlock: "var(--rts-spacing-2)",
                                                }}>
                                    <TagsInput.ItemPreview style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "var(--rts-spacing-2)",
                                    }}>
                                        <TagsInput.ItemText>{value}</TagsInput.ItemText>
                                        <TagsInput.ItemDeleteTrigger><LuX/></TagsInput.ItemDeleteTrigger>
                                    </TagsInput.ItemPreview>
                                    <TagsInput.ItemInput/>
                                </TagsInput.Item>
                            ))}
                        </TagsInput.Control>
                        <TagsInput.Input placeholder={placeholder} style={{
                            width: "auto",
                            minWidth: "0",
                            outline: "0",
                            position: "relative",
                            appearance: "none",
                            textAlign: "start",
                            borderRadius: "15px",
                            fontSize: "var(--rts-font-sizes-sm)",
                            lineHeight: "1.25rem",
                            scrollPaddingBottom: "var(--rts-spacing-2)",
                            background: "var(--rts-colors-transparent)",
                        }}/>
                    </div>
                    <TagsInput.ClearTrigger style={{
                        position: "relative",
                    }}><LuTrash2/></TagsInput.ClearTrigger>

                </div>
            )}
        </ValidJSXSelectContext>
    )
})