import {Info21S_ServiceOrderEvent} from "src/types/service_order/event";
import {useRts} from "src/provider";

export function DetailTimeLine() {
    const {Redux, Components} = useRts();
    const {
        Lu,
        Text,
        TimelineConnector,
        TimelineContent,
        TimelineItem,
        TimelineRoot,
        TimelineTitle,
        TimelineDescription,
        useColorModeValue
    } = Components;
    const {useStore} = Redux();
    const data = JSON.parse(JSON.stringify(useStore.service_order.detail.events));
    const bc = useColorModeValue("gray.200", "gray.800");
    const codeIcon = (code: string) => {
        const types = code.split("-")[0];
        if (types === "DATC") return <Lu.LuPen/>;
        if (code === "LABEL-PRT") return <Lu.LuPrinter/>;
        if (code === "HIRR") return <Lu.LuNetwork/>;
        if (code === "SMST-OK") return <Lu.LuNetwork/>;
        if (code === "SMST-ERR") return <Lu.LuNetwork/>;
        if (code === "SYSTEM") return <Lu.LuUserCog2/>;
        return <Lu.LuQuote/>;
    }

    return (
        <TimelineRoot size={"lg"} variant={"subtle"}>
            {data.sort((a: Info21S_ServiceOrderEvent, b: Info21S_ServiceOrderEvent) => new Date(b.eventDate ?? "1970-01-01").getTime() - new Date(a.eventDate ?? "1970-01-01").getTime()).map((item: Info21S_ServiceOrderEvent) => (
                <TimelineItem>
                    <TimelineConnector>
                        {codeIcon(item.eventCode ?? "")}
                    </TimelineConnector>
                    <TimelineContent>
                        <TimelineTitle fontSize={"xs"}>{item.eventUserName ?? "N/A"}</TimelineTitle>
                        <TimelineDescription
                            fontSize={"2xs"}>{new Date(item.eventDate ?? "1970-01-01").toLocaleString()}</TimelineDescription>
                        <Text textStyle="xs">
                            {item.eventDesc}
                        </Text>
                        <Text mt={1} borderBottom={"solid"} borderBottomWidth={".01rem"}
                              borderBottomColor={bc}/>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </TimelineRoot>
    );
}
