import React from "react";
import DatePicker, {DatePickerProps, registerLocale} from "react-datepicker";
import {Lu, useColorModeValue} from "src/components";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker-light.css";
import "./date-picker-dark.css";
import {forwardRef} from "src/core";
import {cs, sk, enUS} from "date-fns/locale";
import {Month} from "date-fns/types";


type ExtendedDatePickerProps = DatePickerProps & {
    excludeWeekend?: boolean;
    excludeToday?: boolean;
};

export const Locale = () => {
   switch (navigator.language) {
        case "cs-CZ":
            return Array.from({length: 12}, (_:any, i ) => cs.localize.month(i as Month));
        case "sk-SK":
            return Array.from({length: 12}, (_:any, i ) => sk.localize.month(i as Month));
        default:
            return Array.from({length: 12}, (_:any, i ) => enUS.localize.month(i as Month));

    }
}

export const DateInput = forwardRef<DatePicker, ExtendedDatePickerProps>(
    function DateInput(props, ref) {
        const {excludeWeekend, excludeToday, locale, filterDate, ...rest} = props;
        const darkMode = useColorModeValue("date-picker-container-light", "date-picker-container-dark");
        let enhancedLocale = locale;
        switch (navigator.language) {
            case "cs-CZ":
                registerLocale("cs", cs);
                enhancedLocale = "cs";
                break;
            case "sk-SK":
                registerLocale("sk", sk);
                enhancedLocale = "sk";
                break;
            default:
                registerLocale("enUS", enUS);
                enhancedLocale = "enUS";
        }

        const isWeekday = (date: Date) => {
            const day = date.getDay();
            return day !== 0 && day !== 6;
        };
        const isToday = (date: Date) => {
            const today = new Date();
            return date >= today;
        };

        return (
            <DatePicker
                ref={ref}
                dateFormat="yyyy-MM-dd"
                locale={enhancedLocale}
                icon={<Lu.LuCalendar/>}                
                className={"date-picker"}
                calendarClassName={darkMode}
                filterDate={(date) => {
                    const isValidWeek = excludeWeekend ? isWeekday(date) : true;
                    const isValidToday = excludeToday ? isToday(date) : true;
                    return isValidToday && isValidWeek && (filterDate ? filterDate(date) : true);
                }}
                {...rest}
            />
        )
    });
    
