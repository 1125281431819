import React from "react";
import rts_v from "../../assets/img/rts_v.png";
import routes from "src/rts.routes";
import {
    useColorModeValue, NavLink, useLocation, Box, Link, Image, Button,
    Flex,
    Stack,
    Text,
    Skeleton
} from "../../items"
import IconBox from "../Icons/IconBox";
import {useRedux} from "src/redux/provider";

function SidebarContent() {
    const {useStore, dispatch, actions} = useRedux();
    const groupList = useStore.users.logged?.group_list ?? [];
    let location = useLocation();
    const app_logo = useColorModeValue(rts_v, rts_v);
    const activeBg = useColorModeValue("bg.panel", "bg.panel");
    const inactiveBg = useColorModeValue("white", "gray.700");
    const activeColor = useColorModeValue("gray.700", "white");
    const inactiveColor = useColorModeValue("gray.400", "gray.400");
    const iconColor = useColorModeValue("gray.50", "gray.50");
    const iconColorHover = useColorModeValue("bg.panel", "bg.panel");
    const iconColorActive = useColorModeValue("gray.50", "gray.50");


    let getRoutes = routes(groupList)

    const activeRoute = (routeName: string) => {
        return location.pathname === routeName ? "active" : "";
    };
    const createLinks = (routes?: any[]) => {
        return getRoutes?.map((prop, key) => {
            if (!prop.sidebar) return;
            if (prop.category) {
                return (
                    <div key={prop?.name ?? ""}>
                        <Text
                            color={activeColor}
                            fontWeight="bold"
                            mb={{
                                xl: "12px",
                            }}

                            mx="auto"
                            ps={{
                                sm: "10px",
                                xl: "16px",
                            }}
                            py="12px"
                        >
                            {prop?.name ?? ""}
                        </Text>
                        {createLinks(prop?.views ?? [])}
                    </div>
                );
            }
            return (
                <NavLink to={prop.path ?? ""} key={prop.name}
                         onClick={() => dispatch(actions.core.setRoute({name: prop.name, path: prop.path}))}>
                    {activeRoute(prop.path ?? "") === "active" ? (
                        <Button
                            boxSize="initial"
                            justifyContent="flex-start"
                            alignItems="center"
                            variant={"surface"}
                            disabled={prop.disabled ?? false}
                            mb={2}
                            py={1}
                            borderRadius="15px"
                            w="100%"
                        >
                            <Flex gap={2}>
                                {typeof prop.icon === "string" ? (
                                    <div>{prop.icon}</div>
                                ) : (
                                    <Box
                                        bg={"gray.500"}
                                        color="white"
                                        h="35px"
                                        w="35px"
                                        borderRadius={"12px"}
                                        me="12px"
                                        alignContent={"center"}
                                        _hover={{
                                            bg: iconColorHover,
                                        }}
                                    >
                                        <Flex justifyContent={"center"}>
                                            {prop.icon}
                                        </Flex>
                                    </Box>
                                )}
                                <Text color={activeColor} my="auto" fontSize="sm">
                                    {document.documentElement.dir === "rtl"
                                        ? prop.name
                                        : prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    ) : (
                        <Button
                            boxSize="initial"
                            disabled={prop.disabled ?? false}
                            justifyContent="flex-start"
                            alignItems="center"
                            variant={"outline"}
                            mb={2}
                            py={1}
                            borderRadius="15px"
                            w="100%"
                        >
                            <Flex gap={2}>
                                {typeof prop.icon === "string" ? (
                                    <div>{prop.icon}</div>
                                ) : (
                                    <Box
                                        bg={"rgba(0,0,0,0.3)"}
                                        color="white"
                                        h="35px"
                                        w="35px"
                                        borderRadius={"12px"}
                                        me="12px"
                                        alignContent={"center"}
                                        _hover={{
                                            bg: iconColorHover,
                                        }}
                                    >
                                        <Flex justifyContent={"center"}>
                                            {prop.icon}
                                        </Flex>
                                    </Box>
                                )}
                                <Text color={inactiveColor} my="auto" fontSize="sm">
                                    {prop.name}
                                </Text>
                            </Flex>
                        </Button>
                    )}
                </NavLink>
            );
        });
    };

    const links = <>{createLinks(getRoutes)}</>;

    const skeletonLink = (
        <Skeleton
            boxSize="initial"
            justifyContent="flex-start"
            alignItems="center"
            mb={{
                xl: "12px",
            }}
            mx={{
                xl: "auto",
            }}
            ps={{
                sm: "10px",
                xl: "16px",
            }}
            py="12px"
            borderRadius="15px"
            w="100%"
            h={"55px"}
        ></Skeleton>
    );

    function SideBarShower() {
        if (getRoutes.length > 0) {
            return links;
        } else {
            return (
                <>
                    {skeletonLink}
                    {skeletonLink}
                    {skeletonLink}
                </>
            );
        }
    }

    return (
        <>
            <Box pt={"25px"} mb="12px">
                <Link
                    target="_blank"
                    display="flex"
                    lineHeight="100%"
                    mb="30px"
                    fontWeight="bold"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="11px"
                >
                    <Image src={app_logo}/>
                </Link>
            </Box>
            <Stack direction="column" mb="40px">
                <Box>
                    <SideBarShower/>
                </Box>
            </Stack>
        </>
    );
};

export default SidebarContent;
