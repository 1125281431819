import {Card as ChakraCard} from "@chakra-ui/react"
import {forwardRef} from "react"
import {CardHover} from "src/theme";

export interface CardProps extends Omit<ChakraCard.RootProps, "title"> {
    title?: React.ReactNode
    bodyElement?: React.ReactNode,
    footerElement?: React.ReactNode,
    headerElement?: React.ReactNode,
    isHoverable?: boolean
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
    function Card(props, ref) {
        const {
            children,
            title,
            bodyElement,
            headerElement,
            footerElement,
            isHoverable,
            ...rest
        } = props
        return (
            <ChakraCard.Root css={isHoverable ? CardHover.card.hover : undefined} ref={ref} {...rest}>
                {title && <ChakraCard.Header>{title}</ChakraCard.Header>}
                {bodyElement && <ChakraCard.Body>{bodyElement}</ChakraCard.Body>}
                {children}
                {footerElement && <ChakraCard.Footer>{footerElement}</ChakraCard.Footer>}
            </ChakraCard.Root>
        )
    },
)
