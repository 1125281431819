import {useRedux} from "src/redux/provider";
import React from "react";
import {Field, Flex, UseForm} from "src/components";
import {InputType} from "src/enums";
import {PriceProposalDefaultTexts} from "src/types/rts";
import SoCardFile, {SoCardFileType} from "src/components/so.card.file";

export default function SoPriceProposalFiles() {
    const {useStore, actions, dispatchAsync} = useRedux();
    const data = useStore.service_order.detail;

    const types = data.texts.map((x: PriceProposalDefaultTexts) => ({
        value: x.id,
        label: x.type,
        text: x.text,
        photo_count: x.photo_count
    }));
    const helperByCount = (count: number) => {
        switch (count) {
            case 0:
                return "Není přiložen žádný soubor skenů poškození.";
            case 1:
                return "Přiložen je 1 soubor skenů poškození.";
            case 2:
            case 3:
            case 4:
                return "Přiloženy jsou 2 soubory skenů poškození.";
            default:
                return `Přiloženo je ${count} souborů skenů poškození.`;
        }
    }
    const spCount = data?.files.filter(x => x.docType === 5).length;
    const reqSpCount = types.find(x => x.value === JSON.parse(data.udf?.alL_PriceText ?? "[]")[0])?.photo_count ?? 0;
    const validatePhoto = () => reqSpCount <= spCount;
    return (<>
        <UseForm
            label={"Fotodokumentace"}
            type={InputType.file}
            required
            files={data.files.filter(x => x.docType === 5)}
            value={""}
            asNoDragDrop={true}
            disabled={data.so?.docStatus !== 8825}
            invalid={!validatePhoto()}
            helperText={helperByCount(spCount)}
            errorText={`Minimální počet přiložených souborů je ${reqSpCount}.`}
            onChange={() => {
            }}/>
        <Field mt={4} label={"Přílohy"}>
            <Flex direction={"column"} gap={2} w={"95%"}>
                <SoCardFile key={"pdf_cn"}
                            disabled={data.so?.docStatus !== 8825}
                            type={SoCardFileType.QpdfPriceProposal}
                            docNo={data.so?.docNo ?? 0}
                            fileNo={0}
                            fileName={`cenova_nabidka_${data.so?.docNo}.pdf`}/>
                <SoCardFile key={"pdf_ir"}
                            type={SoCardFileType.QpdfInspectionReport}
                            docNo={data.so?.docNo ?? 0}
                            disabled={data.so?.docStatus !== 8825}
                            fileNo={0}
                            fileName={`kontrolni_zaznam_${data.so?.docNo}.pdf`}/>
            </Flex>
        </Field>
    </>);
}