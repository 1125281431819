import {
    Lu,
    Text,
    TimelineConnector,
    TimelineContent,
    TimelineItem,
    TimelineRoot,
    TimelineTitle,
    useColorModeValue
} from "src/components";
import {useRedux} from "src/redux/provider";
import {TimelineDescription} from "src/components/ui/timeline";
import {useEffect, useState} from "src/core";

export function DetailTimeLine() {
    const {useStore} = useRedux();
    const [data, setData] = useState(useStore.service_order.detail.events);
    const stateChanges = useStore.service_order.detail.status;
    const bc = useColorModeValue("gray.200", "gray.800");
    const codeIcon = (code: string) => {
        console.log("Status", stateChanges);
        const types = code.split("-")[0];
        if (types === "DATC") return <Lu.LuPen/>;
        if (code === "LABEL-PRT") return <Lu.LuPrinter/>;
        if (code === "HIRR") return <Lu.LuNetwork/>;
        if (code === "SMST-OK") return <Lu.LuNetwork/>;
        if (code === "SMST-ERR") return <Lu.LuNetwork/>;
        if (code === "SYSTEM") return <Lu.LuUserCog2/>;
        return <Lu.LuQuote/>;
    }

    return (
        <TimelineRoot size={"lg"} variant={"subtle"}>
            {data.map((item) => (
                <TimelineItem>
                    <TimelineConnector>
                        {codeIcon(item.eventCode ?? "")}
                    </TimelineConnector>
                    <TimelineContent>
                        <TimelineTitle fontSize={"xs"}>{item.eventUserName ?? "N/A"}</TimelineTitle>
                        <TimelineDescription
                            fontSize={"2xs"}>{new Date(item.eventDate ?? "1970-01-01").toLocaleString()}</TimelineDescription>
                        <Text textStyle="xs">
                            {item.eventDesc}
                        </Text>
                        <Text mt={1} borderBottom={"solid"} borderBottomWidth={".01rem"}
                              borderBottomColor={bc}/>
                    </TimelineContent>
                </TimelineItem>
            ))}
        </TimelineRoot>
    );
}
