import React from "react";
import {Button, Flex, Text, UseForm} from "src/components";
import {InputType} from "src/enums";
import {useRts} from "src/provider";

export default function SoPriceProposalTextDialog(props: { splits: string[], match0: string, text: string }) {
    const {Redux, useEffect, UseDialog} = useRts();
    const [changer, setChanger] = React.useState("");
    useEffect(() => {
        setChanger(props.match0);
    }, []);
    const {useStore, actions, dispatchAsync} = Redux();
    const validate = () => changer.length > 3 && !changer.includes("{{") && !changer.includes("}}");
    const data = useStore.service_order.detail;
    return (<Flex direction={"column"} gap={2}>
        <Text>{props.splits[0]}<b>{changer === "" ? props.match0 : changer}</b>{props.splits[1]}</Text>
        <Text>Nahraď {props.match0.replace("{{", "").replace("}}", "")}:</Text>
        <UseForm
            type={InputType.textarea}
            rows={6}
            invalid={!validate()}
            onKeyDown={(e) => {
                if (changer.includes("{{") || changer.includes("}}"))
                    setChanger("");
            }}
            onFocus={(e) => {
                if (changer.includes("{{") || changer.includes("}}"))
                    setChanger("");
            }}
            errorText={"Text musí obsahovat alespoň 4 znaky a nesmí obsahovat {{ nebo }}."}
            value={changer}
            onChange={(e) => {
                setChanger(e);
            }}/>

        <Button variant={"surface"} disabled={!validate()} autoFocus onClick={() => {
            if (validate()) {
                dispatchAsync(actions.service_order.fetch.changePriceDesc(data.so?.docNo ?? 0, data.udf?.alL_PriceText ?? "[]", props.text.replace(props.match0, changer)));
                UseDialog({open: false})
            }
        }}>Nahradit</Button>
    </Flex>)
}