import {
    Card,
    DialogBackdrop,
    DialogCloseTrigger,
    DialogContent,
    DialogRoot,
    Flex,
    Kbd,
    Text,
    Input, InputGroup, Tag, Lu
} from "src/components";
import {useRedux} from "src/redux/provider";
import {CardHover} from "src/theme/theme.iterfaces";
import {useEffect, useRef, useState} from "src/core";
import {AddPartsType} from "src/enums";
import {ServiceOrderItem} from "src/types/service_order";
import {Info21S_DevTypeSparePart} from "src/types/service_order/dev_type_items";


export default function SoAddParts({type}: { type: AddPartsType }) {
    const {useStore, dispatch, actions, dispatchAsync} = useRedux();
    const {devTypeSparePart, so, items, work} = useStore.service_order.detail;
    const [filter, setFilter] = useState<string>("")
    const filterFunction = (part: Info21S_DevTypeSparePart) => {
        const filtered = JSON.stringify(part).toLowerCase();
        return filtered.indexOf(filter.toLowerCase()) > -1 && !items.map(x => x.idSparePart).includes(part.idSparePart);
    };

    const filterWorkIsUsed = (part: Info21S_DevTypeSparePart) => {
        const used = work.some((x) => x.status === type);
        const isWork = part.isWorkItem;
        return (!used && isWork) || !isWork;
    };

    const filterOnlyWorkUsed = (part: Info21S_DevTypeSparePart) => {
        const used = work.some((x) => x.status === type && x.matNo === null);
        return !used;
    };
    const handleSelectItem = (part: Info21S_DevTypeSparePart) => {
        dispatchAsync(actions.service_order.fetch.addPart(
            so?.docNo ?? 0,
            part.idSparePart ?? -1,
            part.itemCode ?? "",
            type
        ));
        dispatch(actions.service_order.onAddPartOpen(false))
    }

    return (<DialogRoot open={useStore.service_order.hasOpenAddParts}>
        <DialogBackdrop/>
        <DialogContent>
            <InputGroup startElement={<Lu.LuSearch/>} endElement={<DialogCloseTrigger
                onClick={() => dispatch(actions.service_order.onAddPartOpen(false))} position={"absolute"} top={0}
                mt={"2px"} right={1}/>}>
                <Input value={filter} onChange={(e) => setFilter(e.target.value)} autoFocus/>
            </InputGroup>
            <Flex mt={2} direction={"column"} gap={2} overflowY={"auto"} maxHeight={"85vh"}>
                {(devTypeSparePart ?? [])
                    .filter(x => !x.isVirtual)
                    .filter(filterWorkIsUsed)
                    .filter(filterOnlyWorkUsed)
                    .filter(filterFunction).map((part) => (
                        <Card.Root key={part.itemCode} css={CardHover.card.hover}
                                   onClick={() => handleSelectItem(part)}>
                            <Card.Body>
                                <Flex direction={"row"} justifyContent={"space-between"}>
                                    <Flex direction={"column"}>
                                        <Text>{part.itemCode}</Text>
                                        <Text fontSize={"xs"}>{part.itemGroup}</Text>
                                        <Text fontSize={"xs"}>{part.itemName}</Text>
                                    </Flex>
                                    <Flex direction={"column"}>
                                        <Flex direction={"column"} gap={1}>
                                            <Text>{part.price} {part.currency}</Text>

                                            <Flex justifyContent={"end"}>
                                                {(part.isWorkItem || part.isTransport) && (
                                                    <Tag colorPalette={"gray"}>
                                                        Neskladová položka
                                                    </Tag>
                                                )}
                                                {!part.isWorkItem && !part.isTransport && (
                                                    <>
                                                        <Tag
                                                            colorPalette={"gray"}>{(part.positions ?? ["N/A"]).join(",")}</Tag>
                                                        {!part.isOnHand && <Tag colorPalette={"green"}>
                                                            Skladem
                                                        </Tag>}
                                                        {part.isOnHand && <Tag colorPalette={"red"}>
                                                            Není skladem
                                                        </Tag>}
                                                    </>
                                                )}

                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>


                            </Card.Body>
                        </Card.Root>
                    ))}
            </Flex>
        </DialogContent>
    </DialogRoot>)
}