import {
    Card,
    Flex,
    Text,
    Button,
    Lu,
    Input,
    Tag,
} from "src/components";
import {useRedux} from "src/redux/provider";
import React from "react";


export default function SoPriceProposalItems() {
    const {useStore, dispatch, actions, dispatchAsync} = useRedux();
    const parts = useStore.service_order.detail.items;
    const work = useStore.service_order.detail.work;
    const data = useStore.service_order.detail;

    return (

        <Flex w={"100%"} direction={"column"} gap={2}>
            <Button w={"100%"} variant={"surface"} size={"sm"}
                    disabled={data.so?.docStatus !== 8825}
                    onClick={() => dispatch(actions.service_order.onAddPartOpen(true))}>Přidat další
                položku</Button>
            {work.filter(x => x.status === "A" && x.reportingPriceProp).map((work) => (
                <Card.Root key={`work_${work.workNo}`} w={"100%"}>
                    <Card.Body>
                        <Flex justifyContent={"space-between"}>
                            <Flex direction={"column"}>
                                <Text>
                                    {work.workItemCode}
                                </Text>
                                <Text fontSize={"xs"}>{work.workItemCode}</Text>
                            </Flex>
                            <Flex direction={"column"}>
                                <Flex justifyContent={"end"}>
                                    <Tag>{work.pricePropUnitPrice} {useStore.service_order.currency}</Tag>
                                </Flex>
                                <Flex justifyContent={"end"}>
                                    <Button variant={"ghost"}
                                            size={"sm"}
                                            disabled={work.matNo !== null || data.so?.docStatus !== 8825}
                                            onClick={() => dispatchAsync(actions.service_order.fetch.changeQtyPart(work.docNo ?? 0, 0, work.workNo ?? 0, ((work.quantity ?? 0) + 1)))}><Lu.LuPlus/></Button>
                                    <Input w={"20%"} size={"sm"} value={work.quantity} disabled/>
                                    <Button variant={"ghost"}
                                            size={"sm"}
                                            disabled={work.matNo !== null || work.quantity === 1 || data.so?.docStatus !== 8825}
                                            onClick={() => dispatchAsync(actions.service_order.fetch.changeQtyPart(work.docNo ?? 0, 0, work.workNo ?? 0, ((work.quantity ?? 0) - 1)))}><Lu.LuMinus/></Button>
                                    <Button variant={"ghost"}
                                            size={"sm"}
                                            disabled={work.matNo !== null || data.so?.docStatus !== 8825}
                                            onClick={() => dispatchAsync(actions.service_order.fetch.removePart(work.docNo ?? 0, 0, work.workNo ?? 0, ""))}><Lu.LuTrash/></Button>
                                </Flex>
                            </Flex>
                        </Flex>

                    </Card.Body>
                </Card.Root>)
            )}
            {parts.filter(x => x.status === "A").map((part) => (
                <Card.Root key={`parts_${part.matNo}`} w={"100%"}>
                    <Card.Body>
                        <Flex justifyContent={"space-between"}>
                            <Flex direction={"column"}>
                                <Text>
                                    {part.matCode}
                                </Text>
                                <Text fontSize={"xs"}>{part.matName}</Text>
                            </Flex>
                            <Flex direction={"column"}>
                                <Flex justifyContent={"end"}>
                                    <Tag>{part.unitPrice} {useStore.service_order.currency}</Tag>
                                </Flex>
                                <Flex justifyContent={"end"}>
                                    <Button variant={"ghost"}
                                            size={"sm"}
                                            disabled={part.isVirtual || data.so?.docStatus !== 8825}
                                            onClick={() => dispatchAsync(actions.service_order.fetch.changeQtyPart(part.docNo ?? 0, part.matNo ?? 0, 0, ((part.quantity ?? 0) + 1)))}><Lu.LuPlus/></Button>
                                    <Input w={"20%"} size={"sm"} value={part.quantity} disabled/>
                                    <Button variant={"ghost"}
                                            size={"sm"}
                                            disabled={part.isVirtual || part.quantity === 1 || data.so?.docStatus !== 8825}
                                            onClick={() => dispatchAsync(actions.service_order.fetch.changeQtyPart(part.docNo ?? 0, part.matNo ?? 0, 0, ((part.quantity ?? 0) - 1)))}><Lu.LuMinus/></Button>
                                    <Button variant={"ghost"}
                                            size={"sm"}
                                            disabled={part.isVirtual || data.so?.docStatus !== 8825}
                                            onClick={() => dispatchAsync(actions.service_order.fetch.removePart(part.docNo ?? 0, part.matNo ?? 0, 0, ""))}><Lu.LuTrash/></Button>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Card.Body>
                </Card.Root>))}
        </Flex>
    );
}
