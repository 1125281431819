import Enhancer, {DataToQueryParams, Generate} from "src/redux/enhancer";
import {EnhancersFrom, signalR} from "src/enums";
import {RequestModel, SingleRequestModel} from "src/types/core";

export const signalRActions = (storeName: string) => {
    return {
        docStateChange: (id: string, state: boolean) => Generate(storeName, {
            id,
            state
        }, "onStateChange", EnhancersFrom.signalR, signalR.serviceOrder.changeDocState),
        getModelList: (id: number) => Generate(storeName, {
            id
        }, "onModelList", EnhancersFrom.signalR, signalR.serviceOrder.getModelList),
        getDocData: (id: number) => Generate(storeName, {
            id
        }, "onDocData", EnhancersFrom.signalR, signalR.serviceOrder.getDocData),
    }
}

export const fetchActions = (storeName: string) => {
    return {
        retrieveDetail: (data: SingleRequestModel) => Generate(storeName,
            null, "retrieveDetailData", EnhancersFrom.fetch,
            undefined, `/api/ServiceOrder/ServiceOrderDetail${DataToQueryParams(data)}`, "GET"),
        retrievePartialDetail: (data: SingleRequestModel) => Generate(storeName,
            null, "retriveFilesDetailData", EnhancersFrom.fetch,
            undefined, `/api/ServiceOrder/ServiceOrderDetail${DataToQueryParams(data)}`, "GET"),
        addPart: (id: number, partId: number, code: string, status: string) => Generate(storeName,
            {id, partId, code, status, MatNo: 0, WorkNo: 0}, "retrivePartialDetailData", EnhancersFrom.fetch,
            undefined, `/api/ServiceOrder/AddSoPart`, "POST"),
        changePriceDesc: (id: number, priceText: string, descText: string) => Generate(storeName,
            {id, priceText, text: descText}, "retrivePartialDetailData", EnhancersFrom.fetch,
            undefined, `/api/ServiceOrder/ChangeSoPriceDesc`, "PUT"),
        removePart: (id: number, MatNo: number, WorkNo: number, status: string) => Generate(storeName,
            {id, partId: 0, code: "", status, MatNo, WorkNo}, "retrivePartialDetailData", EnhancersFrom.fetch,
            undefined, `/api/ServiceOrder/RemoveSoPart`, "POST"),
        createPriceProposal: (id: number, emailTo: string[], emailCc: string[]) => Generate(storeName,
            {id, emailTo, emailCc}, "retrivePartialDetailData", EnhancersFrom.fetch,
            undefined, `/api/ServiceOrder/CreatePriceProposal`, "POST"),
        changeStatus: (id: number, status: number, is_restrict: boolean) =>
            Generate(storeName,
                {id, status, is_restrict}, "retrivePartialDetailData", EnhancersFrom.fetch,
                undefined, `/api/ServiceOrder/ChangeSoStatus`, "PUT"),
        GetDocs: (data: SingleRequestModel) => Generate(storeName,
            null, "retriveDocsData", EnhancersFrom.fetch,
            undefined, `/api/ServiceOrder/GetDocs${DataToQueryParams(data)}`, "GET"),
        AddOrUpdateComment: (id: number, comment: string, parentId: number) => Generate(storeName,
            {info21_id: id, request: comment, parent_id: parentId}, "retrivePartialDetailData", EnhancersFrom.fetch,
            undefined, `/api/ServiceOrder/AddOrUpdateComment`, "POST"),
        changeQtyPart:
            (id: number, MatNo: number, WorkNo: number, qty: number) => Generate(storeName,
                {
                    id,
                    partId: 0,
                    code: "",
                    status: "",
                    MatNo,
                    WorkNo,
                    quantity: qty
                }, "retrivePartialDetailData", EnhancersFrom.fetch,
                undefined, `/api/ServiceOrder/ChangeSoQtyPart`, "PUT"),
    }
};

export const extraActions = (storeName: string) => {
    return {}
};

export function ActionsEnhancer<T>(action: T, storeName: string) {
    return Enhancer(action, signalRActions(storeName), fetchActions(storeName), extraActions(storeName));
}