import {createRoot} from "react-dom/client";
import {
    redux,
    theme,
    ChakraProvider,
    ColorModeProvider,
    StoreProvider,
    Routes,
    Route,
    HashRouter,
    RtsRoutes
} from "./items";
import {ReduxProvider} from "./redux/provider";
import {SignalRProvider} from "./lib/signalR.provider";
import {RtsProvider} from "src/provider";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
    <SignalRProvider>
        <ChakraProvider value={theme.default}>
            <ColorModeProvider enableSystem defaultTheme="light">
                <StoreProvider store={redux.store}>
                    <ReduxProvider>
                        <HashRouter>
                            <Routes>
                                <Route path="*" element={
                                    <RtsProvider key={"rtsProvider"}>
                                        <RtsRoutes.Core/>
                                    </RtsProvider>}/>
                            </Routes>
                        </HashRouter>

                    </ReduxProvider>
                </StoreProvider>
            </ColorModeProvider>
        </ChakraProvider>
    </SignalRProvider>
);
