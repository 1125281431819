import {AdminItem} from "src/types/admin";
import {CardHover} from "src/theme";
import {Avatar, Card, Fa, Flex, Text} from "src/components";
import React from "react";
import {useRedux} from "src/redux/provider";
import {useRts} from "src/provider";

export default function AdminRenderItem(props: AdminItem) {
    const {UseDrawer} = useRts();
    return <Card.Root key={props.key} css={CardHover.card.hover} variant={"subtle"} size={"sm"}
                      onClick={() => {
                          props.onBeforeLoad?.();
                          UseDrawer(props.dispatcher);
                      }}>
        <Card.Body>
            <Flex
                direction={"row"}
                gap={4}
                align={"center"}
                alignItems={"center"}
                justifyContent={"space-between"}
            >

                <Flex>
                    <Avatar me={6} icon={<></>}>
                        <props.icon/>
                    </Avatar>
                    <Flex direction={"column"}>
                        <Text fontSize={"sm"} fontWeight={"bold"}>
                            {props.title}
                        </Text>
                        <Text fontSize={"xs"}>{props.description}</Text>
                    </Flex>

                </Flex>
                <Flex direction={"column"}>
                    <Text fontSize={"sm"}></Text>
                    <Text fontSize={"xs"}>
                    </Text>
                </Flex>
                <Fa.FaArrowRight/>
            </Flex>
        </Card.Body>
    </Card.Root>
}