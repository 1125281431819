import {Alert, Button, Flex} from "src/components";
export function Finish() {
    return (
        <Flex direction={"column"} gap={4}>
            <Alert status="success">
                Děkujeme. Veškeré informace jsme Vám odeslali na email.
            </Alert>
            <Button variant={"subtle"} onClick={() => window.location.reload()}>
                Zpět na začátek
            </Button>
        </Flex>
    );
}
