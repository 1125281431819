import {createSlice} from "@reduxjs/toolkit";
import * as reducers from "./admin.reducers";
import {initialState} from "./admin.init";
import {ActionsEnhancer} from "./admin.actions";

const name = "admin";

const AdminSlice = createSlice({
    name,
    initialState,
    reducers,
});

export const Reducer = AdminSlice.reducer;
export const Actions = ActionsEnhancer(AdminSlice.actions, name);
