import * as core from "./core/core.slice";
import * as admin from "./admin/admin.slice";
import * as shared from "./shared/shared.slice";
import * as logisticsPackages from "./logistics_packages/logistics_packages.slice";
import * as serviceRequest from "./service_request/servicerequest.slice";
import * as users from "./users/users.slice";
import * as serviceOrder from "./service_order/serviceorder.slice";
export const reducer = {
    admin: admin.Reducer,
    users: users.Reducer,
    core: core.Reducer,
    shared: shared.Reducer,
    service_request: serviceRequest.Reducer,
    logistics_packages: logisticsPackages.Reducer,
    service_order: serviceOrder.Reducer,
}

export const actions = {
    admin: admin.Actions,
    core: core.Actions,
    users: users.Actions,
    shared: shared.Actions,
    service_request: serviceRequest.Actions,
    logistics_packages: logisticsPackages.Actions,
    service_order: serviceOrder.Actions,
};