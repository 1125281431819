import {email} from "../interfaces/interface.validation";
import {useLocation, useNavigate, useEffect, useState} from "src/items";
import {Button, Center, Field, Flex, Input, Text} from "src/components";
import {useRedux} from "src/redux/provider";

export function Verification() {
    const {useStore, dispatch, actions, dispatchAsync} = useRedux();
    const data = useStore.service_request;
    const [isValid, setIsvalid] = useState({valid: false, message: ""});
    const [remainingTime, setRemainingTime] = useState(0);
    const [expireTime, setExpireTime] = useState<Date | null>(null);
    const location = useLocation();
    const navigate = useNavigate();

    //získáme z direct linku emailu token a pin
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const tokenParam = searchParams.get("token");
        const pinParam = searchParams.get("pin");
        if (tokenParam && pinParam) {
            navigate("/serviceRequest");
            dispatchAsync(
                actions.shared.signalR.verifyToken(tokenParam, pinParam));
        }
        
    }, []);

    const handleValidateEmail = (e: string) => {
        try {
            email.parse(e);
            setIsvalid({valid: true, message: ""});
        } catch (error: any) {
            const error_message = JSON.parse(error.message).map(
                (x: any) => x.message
            );
            setIsvalid({valid: false, message: error_message.join(",")});
        }
        dispatch(actions.service_request.setEmail(e));
    };

    useEffect(() => {
        if (useStore.shared.token_verified !== "")
            navigate("/serviceRequest");
    }, [useStore.shared.token_verified]);

    useEffect(() => {
        if (useStore.shared.token_for_verify.length > 0) {
            // Spuštění intervalu pro aktualizaci zbývajícího času
            const interval = setInterval(() => {
                const now = new Date();
                const remaining = expireTime ? expireTime.getTime() - now.getTime() : 0;

                if (remaining <= 0) {
                    // Čas vypršel, obnovení stránky
                    clearInterval(interval);
                    window.location.reload();
                } else {
                    // Aktualizace zbývajícího času
                    setRemainingTime(Math.floor(remaining / 1000)); // Převod na sekundy
                }
            }, 1000);

            // Vyčištění intervalu při odmontování komponenty
            return () => clearInterval(interval);
        }
    }, [useStore.shared.token_for_verify, expireTime]);


    const handleVerification = async () => {
        await dispatchAsync(
            actions.shared.signalR.validateEmail(data.customer.email)
        );

        const expiration = new Date();
        expiration.setMinutes(expiration.getMinutes() + 5);
        setExpireTime(expiration);
    };

    return (
        <Center alignContent={"center"}>
            <Flex direction={"column"}>
                {useStore.shared.token_for_verify !== "" && (
                    <Flex direction={"column"}>
                        <Text fontSize="md" fontWeight={"Bold"}>
                            V žádosti můžete pokračovat kliknutím na odkaz/tlačítko, který Vám
                            dorazil do emailu. Odkaz má platnost 5 minut.
                        </Text>
                        <Text fontSize="sm" color="gray.500">
                            Zbývající čas {remainingTime} sekund
                        </Text>
                    </Flex>
                )}
                {useStore.shared.token_for_verify === "" && (
                    <Flex direction={"column"}>
                        <Text fontSize="md" fontWeight={"Bold"}>
                            Pro vstup do nového požadavku žádosti o opravu a svozu zařízení je
                            nutné ověřit Váš email.
                        </Text>
                        <Field label="" required errorText={isValid.message}>
                            <Input
                                mt={6}
                                placeholder="Email"
                                autoFocus
                                onChange={(e) => handleValidateEmail(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") handleVerification();
                                }}
                            />
                        </Field>

                        <Button
                            mt={4}
                            variant={"subtle"}
                            w={"100%"}
                            onClick={() => handleVerification()}
                            disabled={!isValid.valid}
                            loading={useStore.core.loading}
                        >
                            Ověřit
                        </Button>
                    </Flex>
                )}
            </Flex>
        </Center>
    );
}
