import React from "react";
import {SideBarItem, UseDrawerProps} from "src/types/core";
import {useRts} from "src/provider";

export interface UseDrawerEnhanceProps extends UseDrawerProps {
    internalClose: () => void;
    internalSetSidebarItem: (item: SideBarItem) => void;
}

export function UseDrawerHelper(props: UseDrawerEnhanceProps) {
    const {Components, useEffect, Types} = useRts();
    const {
        DrawerContent,
        DrawerRoot,
        DrawerBody,
        DrawerFooter,
        DrawerHeader,
        DrawerCloseTrigger,
        DrawerTitle, Button,
        Flex, Grid, GridItem, Tag, Text
    } = Components;
    const {
        open,
        onClose,
        bodyElement,
        headerElement,
        closable,
        footerElement,
        contentElement,
        size,
        placement,
        title,
        sidebar,
        sidebarItems,
        sidebarValue,
        onBeforeClose,
        sidebarHeader,
        onClickItem,
        sidebarItem,
        internalClose,
        internalSetSidebarItem
    } = props;

    const handleClose = () => {
        if (onBeforeClose !== undefined)
            onBeforeClose();
        internalClose();
    };

    useEffect(() => {
        if (sidebarItem === null && sidebarItems && sidebarValue) {
            const item = sidebarItems?.find((item) => item.value === sidebarValue);
            if (item)
                internalSetSidebarItem(item);
        }
    }, [sidebarItem, sidebarItems, sidebarValue])

    useEffect(() => {
        if (onClose)
            handleClose();
    }, [onClose]);

    return (<DrawerRoot open={open} size={size} placement={placement}>
        {contentElement === undefined && (
            <DrawerContent backdrop>
                <DrawerHeader>
                    {title !== undefined && <DrawerTitle>
                        {title}
                    </DrawerTitle>}
                    {headerElement !== undefined && headerElement}
                    {closable && <DrawerCloseTrigger onClick={handleClose}/>}
                </DrawerHeader>
                {bodyElement && <DrawerBody>{bodyElement}</DrawerBody>}
                {sidebar && sidebarItems && sidebarItems.length > 0 && (
                    <DrawerBody height={"80vh"} overflow={"hidden"}>
                        <Grid gap={2} templateColumns={"repeat(4, 1fr)"}>
                            <GridItem
                                gap={2}
                                p={4}
                                h={"100vh"}
                                borderRight={"1px solid var(--rts-colors-gray-700)"}

                            >
                                <Flex
                                    direction={"column"}
                                    gap={2}
                                    justify={"center"}
                                    align={"center"}
                                >
                                    {sidebarHeader && sidebarHeader}
                                    {sidebarItems.map((item: SideBarItem) => (!item.hidden &&
                                        <Button
                                            w={"100%"}
                                            size={"md"}
                                            variant={item === sidebarItem ? "subtle" : "outline"}
                                            colorScheme={"blue"}
                                            gap={2}
                                            onClick={() => {
                                                onClickItem && onClickItem(item);
                                                item.onClick && item.onClick();
                                                internalSetSidebarItem && internalSetSidebarItem(item);
                                            }}>
                                            <item.icon/>
                                            <Text>{item.name}</Text>
                                            {item.count && <Tag size={"sm"} colorPalette={"blue"}>
                                                {item.count}
                                            </Tag>}

                                        </Button>
                                    ))}
                                </Flex>
                            </GridItem>
                            <GridItem colSpan={3} p={4} maxHeight={"83vh"} overflowY={"auto"}>
                                {sidebarItem?.element ?? <></>}
                            </GridItem>
                        </Grid>
                    </DrawerBody>
                )}
                {footerElement && <DrawerFooter>{footerElement}</DrawerFooter>}
            </DrawerContent>
        )}
        {contentElement && contentElement}
    </DrawerRoot>)
};