import SoDetailApi from "./components/detail-api";
import SoDetailCustomer from "./components/so.detail.customer";
import SoDetailItems from "./components/so.detail.items";
import SoDetailDocuments from "./components/so.detail.documents";
import React from "react";
import {
    Ai,
    Fa,
    Io,
    Lu,
    Md,
} from "src/components";
import {useEffect} from "src/core";
import {DetailTimeLine} from "./components/detail-timeline";
import SoPriceProposal from "src/views/serviceOrder/components/so.detail.priceproposal";
import SoDetailSidebarHeader from "src/views/serviceOrder/components/generic/so.detail.sidebar.header";
import {DetailWork} from "./components/detail-work";
import {DetailComments} from "./components/detail-comments";
import SoDetailHeader from "src/views/serviceOrder/components/generic/so.detail.header";
import SoDetailFooter from "src/views/serviceOrder/components/generic/so.detail.footer";
import {useRts} from "src/provider";
import {useNavigate, useLocation} from "src/core";


export function SoDetail() {
    const {Redux, Core, UseDrawer} = useRts();
    const navigate = useNavigate();
    const {useStore, dispatch, actions, dispatchAsync} = Redux();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const queryState = searchParams.get("state");
    const id = searchParams.get("doc");
    const data = useStore.service_order.detail;
    const cameraChange = useStore.core.camera.open;
    const nav = (id: any, value: any) => navigate(`/service-orders?doc=${id}&state=${value}`);
    const RetrieveDetail = () => dispatchAsync(actions.service_order.fetch.retrieveDetail({
        page_number: 1,
        page_size: 1,
        search: id ?? ""
    })).then(() => {
        UseDrawer({
            open: true,
            title: `Zakázka č. ${id}`,
            size: "xl",
            headerElement: <SoDetailHeader/>,
            footerElement: <SoDetailFooter/>,
            sidebar: true,
            sidebarValue: queryState ?? "customer",
            sidebarItem: null,
            sidebarHeader: <SoDetailSidebarHeader/>,
            onClickItem: (item: any) => nav(id, item.value),
            sidebarItems:
                [
                    {
                        name: "Zákazník",
                        value: "customer",
                        icon: Lu.LuUsers2,
                        element: <SoDetailCustomer/>
                    },
                    {
                        name: "Zařízení",
                        value: "device",
                        icon: Md.MdOutlineDevices,
                        element: <></>
                    },
                    {
                        name: "Materiál",
                        value: "items",
                        icon: Lu.LuLayers,
                        element: <SoDetailItems/>
                    },
                    {
                        name: "Práce",
                        value: "work",
                        icon: Md.MdWorkOutline,
                        element: <DetailWork/>
                    },
                    {
                        name: "Dokumenty",
                        value: "documents",
                        icon: Ai.AiOutlineFileZip,
                        element: <SoDetailDocuments/>
                    },
                    {
                        name: "Doprava",
                        value: "transport",
                        icon: Ai.AiFillTruck,
                        element: <></>
                    },
                    {
                        name: "Události",
                        value: "timeline",
                        icon: Md.MdTimeline,
                        element: <DetailTimeLine/>
                    },
                    {
                        name: "Stavová osa",
                        value: "history",
                        icon: Lu.LuHistory,
                        element: <></>
                    },
                    {
                        name: "Komentáře",
                        value: "comments",
                        icon: Ai.AiOutlineComment,
                        element: <DetailComments/>
                    },
                    {
                        name: "Notifikace",
                        value: "notifications",
                        icon: Io.IoMdPaperPlane,
                        element: <></>
                    },
                    {
                        name: "API komunikace",
                        value: "api",
                        icon: Fa.FaNetworkWired,
                        element: <SoDetailApi/>
                    },
                    {
                        name: "Cenová nabídka",
                        value: "cn",
                        hidden: true,
                        icon: Fa.FaNetworkWired,
                        element: <SoPriceProposal/>
                    }
                ],
            closable:
                true,
        })
    })

    useEffect(() => {
        if (!cameraChange && (data.so?.docNo !== undefined)) {
            dispatchAsync(actions.service_order.fetch.retrievePartialDetail({
                page_number: 1,
                page_size: 1,
                search: id ?? ""
            }));
        }
    }, [!cameraChange]);

    useEffect(() => {
        if (id) {
            RetrieveDetail();
        }
    }, [id]);
    return (<></>)
}
