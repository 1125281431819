import {useRts} from "src/provider";

export function FilterContent() {
    const {Redux, Components, Enums} = useRts();
    const {useStore, dispatch, actions} = Redux();
    const {Flex, UseForm} = Components;
    const {InputType} = Enums;
    const data = useStore.service_order.filter;
    return (
        <Flex gap={2} direction={"column"} fontSize={"xs"}>
            <UseForm type={InputType.tagsinput}
                     value={data.docs}
                     label={"Čísla zakázek"}
                     onChange={(e) => dispatch(actions.service_order.setFilter({...data, docs: e}))}/>
            <UseForm type={InputType.tagsinput}
                     value={data.users}
                     label={"Uživatelé"}
                     onChange={(e) => dispatch(actions.service_order.setFilter({...data, users: e}))}/>
            <UseForm type={InputType.tagsinput}
                     value={data.status}
                     label={"Stavy zakázek"}
                     onChange={(e) => dispatch(actions.service_order.setFilter({...data, status: e}))}/>
            <UseForm type={InputType.tagsinput} value={data.deviceType} label={"Druhy oprav"} onChange={() => {
            }}/>
            <UseForm type={InputType.tagsinput} value={data.vendors} label={"IMEI"} onChange={() => {
            }}/>
            <UseForm type={InputType.tagsinput} value={data.vendors} label={"S/N"} onChange={() => {
            }}/>
            <UseForm type={InputType.tagsinput} value={data.vendors} label={"Priorita zpracování"} onChange={() => {
            }}/>
            <UseForm type={InputType.tagsinput} value={data.vendors} label={"Výrobci"} onChange={() => {
            }}/>
            <UseForm type={InputType.tagsinput}
                     value={data.deviceType}
                     label={"Typy přístrojů"}
                     onChange={(e) => dispatch(actions.service_order.setFilter({...data, deviceType: e}))}/>
            <UseForm type={InputType.textarea} value={[]} rows={12} label={"Čísla zakázek smart filter"}
                     onChange={() => {
                     }}/>
        </Flex>
    );
}

export function FilterFooter() {
    const {Components, Enums} = useRts();
    const {Flex, UseForm} = Components;
    const {InputType} = Enums;

    return (
        <Flex direction={"row"} justifyContent={"end"}>
            <UseForm btnVariant={"surface"} type={InputType.button} children={"Hledat"} onChange={() => {
            }}/>
        </Flex>

    );
}
