import Enhancer, {DataToQueryParams, Generate} from "src/redux/enhancer";
import {RequestModel, SingleRequestModel} from "src/types/core";
import {PriceProposalCustomer, PriceProposalDefaultTexts} from "src/types/rts";
import {EnhancersFrom} from "src/enums";
import {PriceProposalConfig} from "src/types/admin/price_proposal_config";
import {PriceProposalAdminfetchActions} from "src/redux/features/admin/admin.actions.priceproposal";
import {NotificationAdminfetchActions} from "src/redux/features/admin/admin.actions.notification";

export const signalRActions = (storeName: string) => {
    return {}
}

export const fetchActions = (storeName: string) => {
    return {
        ...PriceProposalAdminfetchActions(storeName),
        ...NotificationAdminfetchActions(storeName),
    }
};
export const extraActions = (storeName: string) => {
    return {}
};

export function ActionsEnhancer<T>(action: T, storeName: string) {
    return Enhancer(action, signalRActions(storeName), fetchActions(storeName), extraActions(storeName));
}