import {DataToQueryParams, Generate} from "src/redux/enhancer";
import {RequestModel, SingleRequestModel} from "src/types/core";
import {PriceProposalCustomer, PriceProposalDefaultTexts} from "src/types/rts";
import {EnhancersFrom} from "src/enums";
import {PriceProposalConfig} from "src/types/admin/price_proposal_config";

export const NotificationAdminfetchActions = (storeName: string) => {
    return {
        GetTemplateDefinition: () => Generate(storeName,
            null, 'setNotificationTemplate',
            EnhancersFrom.fetch, undefined,
            "/api/admin/GetNotificationTemplate", "GET"),
        AddOrUpdateTemplate: (data: string) => Generate(storeName,
            data, 'setPriceProposalConfigState',
            EnhancersFrom.fetch, undefined,
            "/api/admin/AddOrUpdateNotificationTemplate", "POST"),
    }
};
