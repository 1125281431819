import React from "react";
import {CardHover} from "src/theme";
import {initialState} from "src/redux/features/core/core.init";
import {useRts} from "src/provider";


export function CustomerSearch() {
    const {UseDialog, Redux, Enums, Components} = useRts();
    const {useStore, dispatch, actions, dispatchAsync} = Redux();
    const {
        Flex,
        UseForm,
        Card,
        Avatar, Lu,
        Text
    } = Components;
    const {InputType} = Enums;
    const data = useStore.admin.PriceProposalConfig.customers.lookup;
    const [search, setSearch] = React.useState<string>("");
    return (
        <Flex direction={"column"} gap={2} minH={"200px"}>
            <UseForm required
                     key={"search"}
                //label={"Podmínka"}
                     value={search}
                     helperText={"   Začni psát....po zapsání 3tího písměna se spustí vyhledávání"}
                     onChange={setSearch}
                     type={InputType.input}/>
            {search.length > 2 && data.filter(x => x.label.toLowerCase().includes(search.toLowerCase())).map(x => (
                <Card isHoverable={true}
                      onClick={() => {
                          dispatch(actions.admin.setPriceProposalCustomerSelected(x))
                          dispatchAsync(actions.admin.fetch.GetPriceProposalCustomer({
                              page_number: 1,
                              page_size: 50,
                              search: `customer="${x.value}"`
                          }))
                          UseDialog(initialState.useDialog);
                      }}>
                    <Flex direction={"row"} gap={2} align={"center"} p={2}>
                        <Avatar icon={<></>}><Lu.LuUser/></Avatar>
                        <Flex direction={"column"}>
                            <Text fontSize={"xs"}>{x.label}</Text>
                        </Flex>
                    </Flex>
                </Card>))}
            {search.length > 2 && data.filter(x => x.value.toLowerCase().includes(search.toLowerCase())).length === 0 && (
                <Text ms={4}>Nic jsem nenašla.</Text>
            )}
        </Flex>
    )

}