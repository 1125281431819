import {
    TimelineRoot,
} from "src/components";
import {useRedux} from "src/redux/provider";
import SoDetailCommentItem from "./generic/so.detail.comment.item";
import {SoDetailCommentForm} from "./generic/so.detail.comment.form"

export function DetailComments() {
    const {useStore} = useRedux();
    const data = useStore.service_order.detail.comments;

    return (
        <TimelineRoot size={"lg"} height={"95vh"} variant={"subtle"}>
            {data.filter(x=>x.parent === null).map((e) => SoDetailCommentItem(e, data))}
            <SoDetailCommentForm/>
        </TimelineRoot>
    );
}
