import {Avatar, Button, Card, Fa, Flex, Lu, Md, Text} from "src/components";
import React from "react";
import {Info21S_ServiceOrderFile} from "src/types/service_order/file";
import {__server} from "src/config";
import {CardHover} from "src/theme";
import {useRedux} from "src/redux/provider";
import {useRts} from "src/provider";

export enum SoCardFileType {
    SoFile,
    QpdfPriceProposal,
    QpdfInspectionReport
}

export interface SoCardFileProps extends Info21S_ServiceOrderFile {
    type: SoCardFileType
    deletable?: boolean
    disabled?: boolean
}

export default function SoCardFile(props: SoCardFileProps) {
    const {type, fileName, fileSize, docNo, fileNo, deletable, disabled} = props;
    const {UseDialog} = useRts();
    const {actions, dispatchAsync} = useRedux();
    //Spočítáme si velikost souboru a přepočítáme buď na kb nebo mb
    const fileSizeInKb = (fileSize ?? 0) / 1024;
    const fileSizeInMb = Math.round((fileSizeInKb / 1024));
    const fileSizeFormatted = fileSizeInMb > 1 ? `${fileSizeInMb.toFixed(0)} MB` : `${fileSizeInKb.toFixed(0)} KB`;
    const UrlType = () => {
        switch (type) {
            case SoCardFileType.QpdfPriceProposal:
                return `${__server}/api/pdf/priceproposal/`;
            case SoCardFileType.QpdfInspectionReport:
                return `${__server}/api/pdf/InspectionReport/`;
            default:
                return `${__server}/api/ServiceOrder/SoFile/`;
        }
    }


    const UrlGetFile = type === SoCardFileType.SoFile ? `${UrlType()}${docNo}/${fileNo}` :
        `${UrlType()}${docNo}`;

    return (<Card w={"100%"} isHoverable={true} onClick={() => UseDialog({
        open: true,
        title: fileName,
        closable: true,
        size: "xl",
        closeOnEscape: true,
        noPadding: false,
        bodyElement: <Flex direction={"column"} gap={4} height={"100%"} w={"100%"}>
            <iframe key={"file_url_get"} src={UrlGetFile} style={{
                border: "none",
                height: "80vh",
                width: "100%"
            }}/>
        </Flex>
    })}>
        <Flex justifyContent={"space-between"} align={"center"} p={2}>
            <Flex gap={4} align={"center"}>
                <Avatar size={"xl"} borderRadius={"15px"} name={"S P"} src={UrlGetFile}/>
                <Flex direction={"column"}>
                    <Text>{fileName}</Text>
                    <Text fontSize={"xs"}>{fileSizeFormatted}</Text>
                </Flex>
            </Flex>
            <Flex>
                {(deletable ?? false) && <Button size={"xs"} disabled={disabled} variant={"ghost"} onClick={(e) => {
                    e.stopPropagation();
                    UseDialog({
                        open: true,
                        title: <Flex align={"center"} gap={4}><Fa.FaExclamationTriangle style={{
                            color: "orange",
                            fontSize: "1.5rem"
                        }}/>Opravdu smazat soubor?</Flex>,
                        closable: true,
                        size: "sm",
                        closeOnEscape: true,
                        noPadding: false,
                        bodyElement: <Text fontSize={"sm"}>Opravdu chceš spazat soubor {fileName} ze zakázky? </Text>,
                        footerElement: <Flex direction={"row"} justifyContent={"space-between"} gap={6}>
                            <Button size={"xs"} variant={"subtle"} autoFocus onClick={() => {
                            }}>Zpět</Button>
                            <Button size={"xs"} variant={"subtle"} colorPalette={"red"} onClick={() => {
                            }}>Smazat!</Button>

                        </Flex>
                    });
                }}><Lu.LuTrash2/></Button>}
            </Flex>
        </Flex>
    </Card>)
}