import {useDisclosure} from "src/items";
import {
    Button, Card, DrawerBackdrop, DrawerBody, DrawerCloseTrigger, DrawerContent, DrawerHeader, DrawerRoot,
    Fa, Flex, Spinner, Text
} from "src/components";
import {useEffect} from "react";
import {AddressTypes} from "src/enums";
import {CustomerAddress} from "src/types/service_request";
import {useRedux} from "src/redux/provider";

export function AddressSelect(props: { type: AddressTypes }) {
    const {useStore, dispatch, actions} = useRedux();
    const {open, onOpen, onClose} = useDisclosure();

    useEffect(() => {
        if (open)
            dispatch(
                actions.service_request.signalR.retrieveMyAddress(props.type)
            );
    }, [open]);

    const __handleSelect = (e: CustomerAddress) => {
        switch (props.type) {
            case AddressTypes.CUSTOMER:
                dispatch(
                    actions.service_request.saveAddress({
                        ...useStore.service_request.customer,
                        ...e,
                    })
                );
                onClose();
                break;
            case AddressTypes.INVOICE:
                dispatch(
                    actions.service_request.saveAddress({
                        ...useStore.service_request.invoice_address,
                        ...e,
                    })
                );
                onClose();
                break;
            case AddressTypes.DELIVERY:
                dispatch(
                    actions.service_request.saveAddress({
                        ...useStore.service_request.delivery_address,
                        ...e,
                        first_name: e.first_name + ' ' + e.last_name
                    })
                );
                onClose();
                break;
            case AddressTypes.PICKUP:
                dispatch(
                    actions.service_request.saveAddress({
                        ...useStore.service_request.pickup_address,
                        ...e,
                        first_name: e.first_name + ' ' + e.last_name
                    })
                );
                onClose();
                break;
        }
    };

    return (
        <>
            <DrawerRoot open={open} size={"md"}>
                <DrawerBackdrop/>
                <DrawerContent>
                    <DrawerHeader justifyContent={"center"}>
                        <Text>Použité adresy</Text>
                        <DrawerCloseTrigger onClick={onClose}/>
                    </DrawerHeader>
                    <DrawerBody>
                        <Flex direction={"column"} gap={2}>
                            {useStore.core.loading && <Spinner/>}
                            {!useStore.core.loading && (
                                <>
                                    {useStore.service_request.selectable_address.map(
                                        (address, idx) => (
                                            <Card
                                                key={"sr:addr:" + idx}
                                                onClick={() => __handleSelect(address)}
                                                title="Vybrat"
                                                cursor={"pointer"}
                                                _hover={{
                                                    _dark: {bg: "gray.800"},
                                                    _light: {bg: "gray.200"},
                                                }}
                                                bodyElement={<Flex direction={"column"}>
                                                    <Text fontWeight={"bold"}>
                                                        {address.first_name} {address.last_name}
                                                    </Text>
                                                    <Text>
                                                        {address.street}, {address.city},{" "}
                                                        {address.postal_code}, {address.country}
                                                    </Text>
                                                    <Text>{address.phone}</Text>
                                                </Flex>}
                                            />
                                        )
                                    )}
                                </>
                            )}
                        </Flex>
                    </DrawerBody>
                </DrawerContent>
            </DrawerRoot>
            <Flex direction={"row"} justifyContent={"end"} gap={4}>
                <Button variant={"outline"} onClick={() => onOpen()}>
                    <Flex direction={"row"} align={"center"} gap={4}>
                        Vybrat z již použitých adres <Fa.FaArrowRight/>
                    </Flex>
                </Button>
            </Flex>
        </>
    );
}
