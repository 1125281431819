import {ActionEnhancer} from "src/types/redux";
import {EnhancersFrom, signalR} from "src/enums";
import {RequestModel, SingleRequestModel} from "src/types/core";

export default function Enhancer<T, TSignalR, TFetch, TExtra>(action: T, signalRActions: TSignalR, fetchActions: TFetch, extraActions: TExtra) {
    return {...action, signalR: signalRActions, fetch: fetchActions, extra: extraActions};
}

type IGenerateMessageType<T extends signalR.shared | signalR.core | signalR.serviceRequest | signalR.serviceOrder | signalR.users> = T extends signalR.shared | signalR.serviceOrder | signalR.core | signalR.serviceRequest | signalR.users ? T : never;

export function DataToQueryParams<T extends SingleRequestModel | RequestModel<any>>(data: T) {
    let queryParams = "?";
    for (const [key, value] of Object.entries(data)) {
        if (value) {
            queryParams += `${key}=${value}&`;
        }
    }
    return queryParams;
}

export function Generate<T extends signalR.shared | signalR.core | signalR.serviceRequest | signalR.serviceOrder | signalR.users>(storeName: string, payload: any, action: string, from: EnhancersFrom, messageType?: IGenerateMessageType<T>, endpoint?: string, method?: "POST" | "GET" | "PUT" | "DELETE"): ActionEnhancer<any> {
    return {
        type: `${storeName}/${action}`,
        from: from,
        payload: payload,
        messageType: messageType,
        endpoint: endpoint,
        method: method
    } as ActionEnhancer<any>
}