import PanelContainer from "../components/MainPanel/panelContainer";
import {Route, Routes} from "react-router-dom";
import {pages as Page} from "src/views";

export const Iframe = () => {
    return (
        <PanelContainer>
            <Routes>
                <Route path="serviceRequest" element={<Page.ServiceRequest/>}/>
                <Route path="mobile/scan" element={<Page.CameraConnect/>}/>
            </Routes>
        </PanelContainer>
    );
};
