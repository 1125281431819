import {
    DialogBody,
    DialogCloseTrigger,
    DialogHeader,
    DialogRoot,
    DialogTitle,
    DialogContent,
    DialogFooter
} from "src/components/ui/dialog";
import {UseDialogProps} from "src/types/core";

export interface UseDialogEnhancedProps extends UseDialogProps {
    internalClose: () => void;
}

export function UseDialogHelper(props: UseDialogEnhancedProps) {

    const {
        open,
        bodyElement,
        noPadding,
        closable,
        onClose,
        footerElement,
        headerElement,
        title,
        placement,
        size,
        backdrop,
        closeOnEscape,
        internalClose
    } = props;

    const handleClose = () => internalClose();
    const isPadding = noPadding ?? false;
    const isClosable = closable ?? true;
    const isEscape = closeOnEscape ?? true;
    console.log(props)
    return (
        <DialogRoot closeOnEscape={closeOnEscape} onEscapeKeyDown={() => isEscape ? handleClose() : {}} open={open}
                    placement={placement} size={size}>
            <DialogContent backdrop={backdrop ?? true}>
                {!isPadding && headerElement === undefined && <DialogHeader>
                    {isClosable &&
                        <DialogCloseTrigger onClick={handleClose}/>}
                    {title && <DialogTitle fontSize={"sm"}>
                        {title}
                    </DialogTitle>}
                </DialogHeader>}
                {!isPadding && (
                    <>
                        {bodyElement &&
                            <DialogBody>
                                {bodyElement}
                            </DialogBody>}
                        {footerElement &&
                            <DialogFooter>
                                {footerElement}
                            </DialogFooter>}
                    </>)}
                {isPadding && <>{bodyElement}</>}
            </DialogContent>
        </DialogRoot>
    );
}