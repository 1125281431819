import React from "react";
import {CustomerAddOrUpdate}
    from "./customer.addorupdate";
import {CustomerSearch} from "./customer.search";
import {CustomerItem} from "./customer.item";
import {CustomerAddOrUpdateAction}
    from "./customer.addorupdate.action";
import {useRts} from "src/provider";


export function Customer() {
    const {UseDialog, Redux, Components, Enums} = useRts();
    const {useStore, dispatch, actions, dispatchAsync} = Redux();
    const {Flex, Button, UseForm, Navigations, Lu, Text} = Components;
    const {InputType} = Enums;

    const customers = useStore.admin.PriceProposalConfig.customers.lookup
        .map(x => ({value: x.value, label: x.label}))
        .sort((a, b) => a.label.localeCompare(b.label));
    const editState = useStore.admin.PriceProposalConfig.customers.data_addorupdate;
    const selected = useStore.admin.PriceProposalConfig.customers.selected;
    const data = {
        ...useStore.admin.PriceProposalConfig.customers.data,
        onPageSize: (e?: number) => dispatchAsync(actions.admin.fetch.GetPriceProposalCustomer({
            page_number: 1,
            page_size: e || useStore.admin.PriceProposalConfig.customers.data.page_size,
            search: `customer='"${editState.customer}"'`
        })),
        onNext: () => dispatchAsync(actions.admin.fetch.GetPriceProposalCustomer({
            page_number: data.page_number + 1,
            page_size: data.page_size,
            search: `CardCode='"${editState.customer}"`
        })),
        onPrev: () => dispatchAsync(actions.admin.fetch.GetPriceProposalCustomer({
            page_number: data.page_number - 1,
            page_size: data.page_size,
            search: `CardCode='"${editState.customer}"`
        }))
    };
    const handleAdd = () => {
        dispatch(actions.admin.setPriceProposalCustomerData({
            ...editState,
            id: 0,
            query: "",
            emailTo: "[]",
            emailCc: "[]",
            customer: editState.customer
        }));
        UseDialog({
            open: true,
            title: editState.id > 0 ? "Upravit podmínku pro zákazníka" : "Přidat podmínku pro zákaznka",
            size: "xl",
            noPadding: false,
            bodyElement: <CustomerAddOrUpdate/>,
            footerElement: <CustomerAddOrUpdateAction/>,
            closable: true,
        });
    };

    return (
        <>
            <Flex direction={"column"} w={"100%"} gap={2}>
                <UseForm required
                         key={"customer"}
                         label={"Zákazník"}
                         value={""}
                         onChange={() => UseDialog({
                             open: true,
                             title: "Vyber zákazníka",
                             size: "xl",
                             noPadding: true,
                             backdrop: true,
                             closeOnEscape: true,
                             bodyElement: <CustomerSearch/>,
                             closable: false,
                         })}
                         children={<Flex direction={"row"} align={"center"} w={"100%"} justifyContent={"space-between"}>
                             <Flex gap={2} align={"center"}>
                                 <Lu.LuSearch/>
                                 <Text>{selected.value === "" ? "Vyber zákazníka" : selected.label}</Text>
                             </Flex>
                             {selected.value === "" ? <Lu.LuArrowDown/> : <Lu.LuX style={{
                                 cursor: "pointer",
                             }} onClick={(e) => {
                                 e.stopPropagation();
                                 dispatch(actions.admin.setPriceProposalCustomerSelected({value: "", label: ""}))
                             }}/>}
                         </Flex>}
                         btnVariant={"surface"}
                         width={"100%"}
                         type={InputType.button}/>

                <Button size={"sm"} variant={"surface"}
                        disabled={customers.length === 0 || selected.value === ""}
                        onClick={handleAdd}>Přidat
                    podmínku pro zákazníka</Button>

                <Navigations
                    mb={{mb: 2}}
                    type=""
                    data={data}
                    renderComponent={CustomerItem}
                />
            </Flex>
        </>
    );
}