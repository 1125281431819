import {
    Field,
    Input,
    NumberInputField,
    NumberInputRoot, SelectContent, SelectItem,
    SelectRoot,
    SelectTrigger,
    SelectValueText,
    Textarea,
    Checkbox,
    Button,
    TagsInput, FileUploadDropzone, FileUploadRoot, InputGroup,
} from "src/components";
import {InputType} from "src/enums";
import React from "react";
import {createListCollection} from "src/components/index";
import {Info21S_ServiceOrderFile} from "src/types/service_order/file";
import SoCardFile, {SoCardFileType} from "src/components/so.card.file";


export interface Options {
    type: InputType;
    asNoDragDrop?: boolean | undefined;
    mask?: string | undefined;
    placeholder?: string | undefined;
    value?: any;
    onChange?: (e: any) => void;
    onBlur?: (e: any) => void;
    required?: boolean | undefined;
    step?: number | undefined;
    min?: number | undefined;
    max?: number | undefined;
    label?: string | undefined;
    key?: string | undefined;
    options?: Array<any> | undefined;
    rows?: number | undefined;
    disabled?: boolean | undefined;
    helperText?: string | undefined;
    errorText?: string | undefined;
    collection?: any;
    files?: Array<Info21S_ServiceOrderFile> | undefined;
    invalid?: boolean | undefined;
    autoFocus?: boolean | undefined;
    onKeyDown?: (e: any) => void;
    onFocus?: (e: any) => void;
    children?: any | undefined;
    itemDeleteTriggerElement?: React.ReactNode;
    multiple?: boolean | undefined;
    size?: "sm" | "md" | "lg" | "xl" | "2xl" | "2xs" | "xs" | undefined;
    btnVariant?: "outline" | "solid" | "subtle" | "surface" | "ghost" | "plain" | undefined;
    width?: string | undefined;
    endElement?: React.ReactNode | undefined;
}

export function UseForm(props: Options) {
    const contentRef = React.useRef<HTMLDivElement>(null)
    const options = createListCollection({
        items: props.collection ?? []
    })
    return (
        <Field label={props.label} ref={contentRef} key={props.key} required={props.required} invalid={props.invalid}
               helperText={props.helperText}
               errorText={props.errorText}>
            {props.type === InputType.group && <InputGroup w={props.width} endElement={props.endElement}>
                {props.children}
            </InputGroup>}
            {props.type === InputType.input &&
                <Input
                    placeholder={props.placeholder}
                    value={props.value}
                    disabled={props.disabled}
                    autoFocus={props.autoFocus}
                    onKeyDown={(e) => props.onKeyDown ? props.onKeyDown(e) : undefined}
                    onChange={(e) => props.onChange?.(e.target.value)}
                />
            }
            {props.type === InputType.button &&
                <Button size={props.size ?? "sm"}
                        disabled={props.disabled}
                        variant={props.btnVariant}
                        width={props.width}
                        onClick={() => props.onChange?.("click")}>
                    {props.children}
                </Button>}
            {props.type === InputType.textarea &&
                <Textarea
                    rows={props.rows}
                    placeholder={props.placeholder}
                    value={props.value}
                    disabled={props.disabled}
                    autoFocus={props.autoFocus}
                    onBlur={(e) => props.onBlur?.(e.target.value)}
                    onFocus={(e) => props.onFocus ? props.onFocus(e) : undefined}
                    onKeyDown={(e) => props.onKeyDown ? props.onKeyDown(e) : undefined}
                    onChange={(e) => props.onChange?.(e.target.value)}
                />
            }
            {props.type === InputType.mask &&
                <Input mask={props.mask} disabled={props.disabled} value={props.value}
                       onChange={(e) => props.onChange?.(e.target.value)}/>}
            {props.type === InputType.checkbox &&
                <Checkbox onChange={(e: any) => props.onChange?.(e.target.checked)}
                          disabled={props.disabled}
                          checked={props.value}/>}
            {props.type === InputType.tagsinput &&
                <TagsInput.Root
                    defaultValue={props.value}
                    onValueChange={(e: any) => props.onChange?.(e.value)}
                    value={props.value}
                    disabled={props.disabled}
                    onInteractOutside={(e: any) => {
                    }}>
                    <TagsInput.Context
                        placeholder={props.placeholder}/>
                </TagsInput.Root>}
            {props.type === InputType.select &&
                <SelectRoot size={"md"}
                            collection={options}
                            onValueChange={(e: any) => props.onChange?.(e.value)}
                            value={props.value}
                            disabled={props.disabled}
                            multiple={props.multiple}
                            onInteractOutside={(e: any) => {
                            }}>
                    <SelectTrigger clearable>
                        <SelectValueText placeholder={props.placeholder ?? "-- Vyber --"}/>
                    </SelectTrigger>
                    <SelectContent portalRef={contentRef}>
                        {options.items.map((pp: any) => (
                            <SelectItem item={pp} key={pp.value}>
                                {pp.label}
                            </SelectItem>
                        ))}
                    </SelectContent>
                </SelectRoot>}
            {props.type === InputType.label && (props.value)}

            {props.type === InputType.file &&
                <FileUploadRoot borderRadius={"15px"} maxW="xl" alignItems="stretch" maxFiles={100}
                                disabled={props.disabled}>
                    <FileUploadDropzone borderRadius={"15px"}
                                        style={{display: props.asNoDragDrop ? "none" : "flex"}}
                                        label="Přesuň sem soubor nebo a klikni a vyber ho z disku"
                                        description=".png, .jpg up to 5MB"
                    />
                    {(props.files ?? []).map((file: Info21S_ServiceOrderFile) => (
                        <SoCardFile key={file.fileNo} disabled={props.disabled} deletable {...file}
                                    type={SoCardFileType.SoFile}/>
                    ))}
                </FileUploadRoot>}

            {props.type === InputType.number &&
                <NumberInputRoot defaultValue={props.value}
                                 step={props.step}
                                 min={props.min}
                                 max={props.max}
                                 disabled={props.disabled}
                                 value={props.value}
                                 onValueChange={(e: any) => props.onChange?.(e.valueAsNumber)}>
                    <NumberInputField/>
                </NumberInputRoot>
            }
        </Field>
    );
}