import {useRts} from "src/provider";

export function ServiceOrders() {
    const {Redux, Components, Core, View} = useRts();
    const {BreadcrumbLink, Button, Card, Checkbox, Flex, Lu, Navigations} = Components;
    const {useEffect, useState} = Core;
    const {useStore, dispatchAsync, actions} = Redux();

    const [pageSize, setPageSize] = useState<number>(20);

    useEffect(() => {
        dispatchAsync(actions.service_order.fetch.GetDocs({
            page_size: pageSize,
            page_number: 1,
            search: "",
        }));
    }, []);

    const data = useStore.service_order.data;

    return (
        <Flex w={"100%"} direction={"column"}>
            <Card.Root w={"100%"} mb={2}>
                <Card.Body>
                    <Flex
                        gap={2}
                        mb={2}
                        direction={"row"}
                        justifyContent={"space-between"}
                    >
                        <Flex gap={4} direction={"row"} justifyContent={"flex-start"}>
                            <Checkbox me={6}/>
                            <Button toolTip={"Vytvořit novou zakázku"} size={"xs"} variant={"subtle"}>
                                <Lu.LuNewspaper/>
                            </Button>
                            <Button toolTip={"Kopírovat zakázku"} size={"xs"} variant={"subtle"}>
                                <Lu.LuCopy/>
                            </Button>
                            <Button toolTip={"Změnit stav zakázky"} size={"xs"} variant={"subtle"}>
                                <Lu.LuShuffle/>
                            </Button>
                        </Flex>
                        <View.serviceOrder.Filter/>
                    </Flex>
                </Card.Body>
            </Card.Root>
            <Navigations
                key={"pagination"}
                data={data}
                renderComponent={View.serviceOrder.SoItem}/>
        </Flex>
    );
}
