import {
    Button,
    DialogBody,
    DialogCloseTrigger,
    DialogContent,
    DialogHeader,
    DialogRoot,
    Lu, MenuContent, MenuRadioItem, MenuRadioItemGroup, MenuRoot,
    MenuTrigger
} from "src/components";
import {Flex, useEffect, useRef} from "src/items";
import React from "react";
import {useRedux} from "src/redux/provider";
import {CameraFile} from "src/types/camera";

export function ImageEditor() {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const {useStore, dispatch, actions} = useRedux();
    const {file, open, openDoctypeMenu} = useStore.core.camera.editor;
    const [isDrawing, setDrawing] = React.useState(false);
    const [drawingMode, setDrawingMode] = React.useState<"rectangle" | "arrow">("rectangle");
    const [fileNames, setFileName] = React.useState("");
    const [items, setItems] = React.useState<Array<{
        startX: number,
        startY: number,
        endX?: number,
        endY?: number,
        width?: number,
        height?: number
    }>>([]);
    const [lastPos, setLastPos] = React.useState<{ x: number, y: number } | null>(null);
    const [img, setImg] = React.useState(new window.Image());

    const resetEditor = () => {
        setItems([]);
        setLastPos(null);
        setDrawing(false);
        setImg(new window.Image());
        setDrawingMode("rectangle");
    };


    useEffect(() => {
        const loadImage = async () => {
            if (open) {
                if (file) {
                    const canvas = canvasRef.current;
                    if (canvas) {
                        const ctx = canvas.getContext("2d");
                        const image = new window.Image();
                        image.src = file?.content;
                        await new Promise((resolve, reject) => {
                            image.onload = resolve;
                            image.onerror = reject;
                        });
                        if (ctx) {
                            canvas.width = image.width;
                            canvas.height = image.height;
                            ctx.drawImage(image, 0, 0);
                            setImg(image);
                            if (fileNames === "")
                                setFileName(file.name);
                        }
                    }
                }
            }
        };

        setTimeout(() => {
            loadImage().catch(error => {
                console.error("Obrázek se nepodařilo načíst.", error);
            });
        }, 100); // Zpoždění 100 ms
    }, [open, file, openDoctypeMenu]);

    useEffect(() => {
        redrawCanvas();
    }, [items]);


    const handleUndo = () => {
        setItems(items.slice(0, items.length - 1));
    };
    const drawArrow = (ctx: CanvasRenderingContext2D, startX: number, startY: number, endX: number, endY: number) => {
        const headlen = 10; // délka hlavy šipky
        const angle = Math.atan2(endY - startY, endX - startX);
        ctx.beginPath();
        ctx.moveTo(startX, startY);
        ctx.lineTo(endX, endY);
        ctx.lineTo(endX - headlen * Math.cos(angle - Math.PI / 6), endY - headlen * Math.sin(angle - Math.PI / 6));
        ctx.moveTo(endX, endY);
        ctx.lineTo(endX - headlen * Math.cos(angle + Math.PI / 6), endY - headlen * Math.sin(angle + Math.PI / 6));
        ctx.stroke();
    };

    const redrawCanvas = () => {
        const canvas = canvasRef.current;

        if (canvas) {
            const ctx = canvas.getContext("2d");
            if (ctx) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                if (img) {
                    ctx.drawImage(img, 0, 0); // Znovu vykreslit obrázek
                }
                items.filter(x => x.width).forEach((r) => {
                    ctx.strokeStyle = "red";
                    ctx.lineWidth = 1;
                    ctx.strokeRect(r.startX, r.startY, r.width ?? 0, r.height ?? 0);
                });
                items.filter(x => x.endX).forEach((a) => {
                    ctx.strokeStyle = "blue";
                    ctx.lineWidth = 2;
                    drawArrow(ctx, a.startX, a.startY, a.endX ?? 0, a.endY ?? 0);
                });
            }
        }
    };

    const saveCanvasToCameraFile = () => {
        const canvas = canvasRef.current;
        if (canvas) {
            const dataURL = canvas.toDataURL("image/png");
            const updatedFile = {
                ...file,
                content: dataURL,
                name: fileNames
            } as CameraFile;
            dispatch(actions.core.setCameraFile(updatedFile));
            dispatch(actions.core.setEditorState({open: false, file: null}));
            resetEditor();
        }
    };
    const handleMouseDown = (e: React.MouseEvent) => {
        const canvas = canvasRef.current;
        if (canvas) {
            const rect = canvas.getBoundingClientRect();
            setLastPos({x: e.clientX - rect.left, y: e.clientY - rect.top});
            setDrawing(true);
        }
    };
    const handleMouseUp = (e: React.MouseEvent) => {
        const canvas = canvasRef.current;
        if (!isDrawing) return;
        if (canvas) {
            const rect = canvas.getBoundingClientRect();
            const currentX = e.clientX - rect.left;
            const currentY = e.clientY - rect.top;

            if (drawingMode === "rectangle") {
                setItems([...items, {
                    startX: lastPos?.x ?? 0,
                    startY: lastPos?.y ?? 0,
                    width: currentX - (lastPos?.x ?? 0),
                    height: currentY - (lastPos?.y ?? 0)
                }]);
            } else if (drawingMode === "arrow") {
                setItems([...items, {
                    startX: lastPos?.x ?? 0,
                    startY: lastPos?.y ?? 0,
                    endX: currentX,
                    endY: currentY
                }]);
            }
            setDrawing(false);
            setLastPos(null);
        }
    };
    const handleMouseMove = (e: React.MouseEvent) => {
        const canvas = canvasRef.current;
        if (!isDrawing) return;
        if (canvas) {
            const ctx = canvas.getContext("2d");
            const rect = canvas.getBoundingClientRect();
            if (ctx && lastPos) {
                const currentX = e.clientX - rect.left;
                const currentY = e.clientY - rect.top;
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                if (img) {
                    ctx.drawImage(img, 0, 0); // Znovu vykreslit obrázek
                }
                items.forEach((item: any) => {
                    if (item.width) {
                        ctx.strokeStyle = "red";
                        ctx.lineWidth = 1;
                        ctx.strokeRect(item.startX, item.startY, item.width, item.height);
                    } else if (item.endX) {
                        ctx.strokeStyle = "blue";
                        ctx.lineWidth = 2;
                        drawArrow(ctx, item.startX, item.startY, item.endX, item.endY);
                    }
                });
                if (drawingMode === "rectangle") {
                    ctx.strokeStyle = "red";
                    ctx.lineWidth = 1;
                    ctx.strokeRect(lastPos.x, lastPos.y, currentX - lastPos.x, currentY - lastPos.y);
                } else if (drawingMode === "arrow") {
                    ctx.strokeStyle = "blue";
                    ctx.lineWidth = 2;
                    drawArrow(ctx, lastPos.x, lastPos.y, currentX, currentY);
                }
            }
        }
    };


    return <DialogRoot open={open} size={"full"}>
        <DialogContent>
            <DialogHeader>
                <Flex direction={"row"} justifyContent={"space-between"}>

                    <Flex>
                        Editace souboru {fileNames}
                    </Flex>

                    <Flex gap={2}>
                        <Button variant={drawingMode === "rectangle" ? "solid" : "surface"}
                                onClick={() => setDrawingMode("rectangle")}><Lu.LuRectangleHorizontal/></Button>
                        <Button variant={drawingMode === "arrow" ? "solid" : "surface"}
                                onClick={() => setDrawingMode("arrow")}><Lu.LuArrowUp/></Button>
                        <MenuRoot
                            positioning={{placement: "bottom"}}
                            closeOnSelect={false}
                            open={openDoctypeMenu}
                            onEscapeKeyDown={() => dispatch(actions.core.setEditorOpenDoctypeMenu(false))}
                        >
                            <MenuTrigger>
                                <Button
                                    variant="surface"
                                    size={"md"}
                                    onClick={() => openDoctypeMenu ?
                                        dispatch(actions.core.setEditorOpenDoctypeMenu(false)) :
                                        dispatch(actions.core.setEditorOpenDoctypeMenu(true))}
                                >
                                    {openDoctypeMenu ? "X" : fileNames.split("_")[0]}
                                </Button>
                            </MenuTrigger>
                            <MenuContent zIndex={9999}>
                                <MenuRadioItemGroup
                                    value={useStore.core.camera.documentType}
                                    onValueChange={(e: any) => {
                                        dispatch(actions.core.renameCameraFile({
                                            ffile: file ?? {} as CameraFile,
                                            newName: e.value
                                        }))
                                        setFileName(e.value + "_" + fileNames.split("_")[1]);
                                        dispatch(actions.core.setEditorOpenDoctypeMenu(false))
                                    }}
                                >
                                    <MenuRadioItem value="SD">Dokumentace</MenuRadioItem>
                                    <MenuRadioItem value="FA">Faktura</MenuRadioItem>
                                    <MenuRadioItem value="SP">Poškození</MenuRadioItem>
                                    <MenuRadioItem value="BT">Baterie</MenuRadioItem>
                                    <MenuRadioItem value="SL">Servisní list</MenuRadioItem>
                                    <MenuRadioItem value="WT">Watter test</MenuRadioItem>
                                    <MenuRadioItem value="VT">Výstupní test</MenuRadioItem>
                                    <MenuRadioItem value="SN">SN štítek</MenuRadioItem>
                                </MenuRadioItemGroup>
                            </MenuContent>
                        </MenuRoot>
                        <Button ms={6} variant={"surface"}
                                onClick={handleUndo}><Lu.LuUndo/></Button>
                        <Button ms={6} variant={"surface"}
                                onClick={saveCanvasToCameraFile}><Lu.LuSave/></Button>
                    </Flex>
                    <Flex>
                        <DialogCloseTrigger
                            onClick={() => dispatch(actions.core.setEditorState({open: false, file: null}))}/>
                    </Flex>

                </Flex>
            </DialogHeader>
            <DialogBody justifyContent={"center"}>
                <canvas
                    ref={canvasRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    //onMouseLeave={handleMouseUp}
                    style={{
                        cursor: "crosshair",
                        overflow: "auto",
                    }}
                />
            </DialogBody>
        </DialogContent>
    </DialogRoot>
}