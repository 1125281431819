export enum InputType {
    select,
    selectMultiple,
    tagsinput,
    input,
    mask,
    checkbox,
    radio,
    textarea,
    label,
    date,
    time,
    file,
    number,
    password,
    button,
    group
}