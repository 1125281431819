import {Flex, Lu, UseForm} from "src/components";
import {InputType} from "src/enums";
import {useEffect, useState} from "src/core";
import {useRedux} from "src/redux/provider";
import {setNotificationTemplate} from "src/redux/features/admin/admin.reducers";

export function Template() {
    const {useStore, dispatch, actions, dispatchAsync} = useRedux();
    const data = useStore.admin.Notification;
    useEffect(() => {
        if (data.template == "")
            dispatchAsync(actions.admin.fetch.GetTemplateDefinition())
    }, []);
    return (
        <Flex
            direction={"column"}
            gap={4}
            justifyContent={"space-between"}
        >
            <Flex w={"100%"} justifyContent={"end"}>
                <UseForm type={InputType.button}
                         width={"100%"}
                         children={<><Lu.LuSave/>Uložit</>}
                         btnVariant={"surface"}
                         onChange={() => dispatchAsync(actions.admin.fetch.AddOrUpdateTemplate(data.template))}/>
            </Flex>
            <UseForm type={InputType.textarea} rows={20} label={"Definice šablony html"} required
                     onChange={(e) => dispatch(actions.admin.setNotificationTemplate(e))}
                     value={data.template}/>
            <UseForm type={InputType.label} label={"Náhled"}
                     value={<div dangerouslySetInnerHTML={{__html: data.template}}/>}/>

        </Flex>
    )
}