import {Fa, Lu} from "src/components";
import Core from "src/rts.core";
import {Group} from "./types/users";
import {Admin, LogisticPackage, ServiceRequest, ServiceOrders, CameraConnect} from "./views/pages";

export const RtsRoutes = {
    Core,
    Admin,
    ServiceRequest,
    ServiceOrders,
    LogisticPackage,
    CameraConnect
}

function AddRoute(route: any, routes: Array<any>) {
    let exists = routes.some((x) => x.path === route.path);
    if (!exists)
        routes.push(route);
    return routes;
}

function EnhanceRoutes(routes: Array<any>) {
    const hasDefaultRoute = routes.some((x) => x.path === "/");
    if (!hasDefaultRoute) {
        routes.push({
            path: "/",
            name: "Vytvořit žádost",
            icon: <Fa.FaFileContract color="inherit"/>,
            component: <ServiceRequest/>,
            layout: "",
            sidebar: false,
        });
    }
    return routes;
}

function logisticsRoutes(actualRoutes: Array<any>, group_list: Group[]) {
    let r = actualRoutes;
    const some_group = group_list.some((x) => x.name === "RTS Logistika");
    if (some_group) {
        r = AddRoute({
            path: "/packages",
            name: "Balíky",
            icon: <Fa.FaBoxOpen color="inherit"/>,
            component: <LogisticPackage/>,
            layout: "",
            sidebar: true,
        }, r);
    }
    return r
};

function adminRoutes(actualRoutes: Array<any>, group_list: Group[]) {
    let r = actualRoutes;

    const some_group = group_list.some((x) => x.name === "RTS Admin");

    if (some_group) {
        r = AddRoute({
            path: "/admin",
            name: "Administrace",
            icon: <Lu.LuServerCog color="inherit"/>,
            component: <Admin/>,
            layout: "",
            sidebar: true,
        }, r);
    }
    return r
}

function secretRoutes(group_list: Group[]) {
    let r = dashRoutes;
    const some_group = group_list.some((x) => x.name === "BRITEX-CZs.r.o");
    //budeme přidávat pouze pokud neexistuje

    if (some_group) {
        r = [];
        r = AddRoute({
            path: "/camera",
            name: "Kamera",
            icon: <Lu.LuCamera color="inherit"/>,
            component: <CameraConnect/>,
            layout: "",
            sidebar: false,
        }, r);
        r = AddRoute({
            path: "/",
            name: "Servisní zakázky",
            icon: <Fa.FaHome color="inherit"/>,
            component: <ServiceOrders/>,
            layout: "",
            sidebar: false,
        }, r);
        r = AddRoute({
            path: "/service-orders",
            name: "Servisní zakázky",
            icon: <Fa.FaFileContract color="inherit"/>,
            component: <ServiceOrders/>,
            layout: "",
            sidebar: true,
        }, r);
        r = logisticsRoutes(r, group_list);
        r = adminRoutes(r, group_list);
    }
    return EnhanceRoutes(r);
}

const dashRoutes: Array<any> = [
    {
        path: "/create/service/request",
        name: "Vytvořit žádost",
        icon: <Fa.FaFileContract color="inherit"/>,
        component: <ServiceRequest/>,
        layout: "",
        sidebar: true,
    },
    {
        path: "/display/service/request",
        name: "Správa žádostí",
        icon: <Fa.FaRegEdit color="inherit"/>,
        component: <ServiceRequest/>,
        layout: "",
        sidebar: true,
        disabled: true,
    },
    {
        path: "/camera",
        name: "Kamera",
        icon: <Lu.LuCamera color="inherit"/>,
        component: <CameraConnect/>,
        layout: "",
        sidebar: false,
    }
];

export default secretRoutes;
