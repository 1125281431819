import {itemdetail} from "../types";
import {dpd_link_tracking} from "src/config";
import type {IconType} from "react-icons";
import {ReactElement} from "react";
import {Avatar, Button, Fa, Flex, Io, Lu, Md, Tag, Text} from "src/components";
import {Package} from "src/types/logistics_packages";

export function DetailSidebar({
                                  item,
                                  state,
                                  onChangeState,
                              }: {
    item: Package;
    state: string;
    onChangeState: (state: string) => void;
}) {

    const ButtonLayer = (props: {
        value: string;
        label: string;
        icon: ReactElement<IconType>;
    }) => <Button
        w={"100%"}
        size={"md"}
        mt={1}
        variant={props.value === state ? "subtle" : "outline"}
        colorScheme={"blue"}
        gap={2}
        onClick={() => onChangeState(props.value)}
    >
        {props.icon}
        {props.label}
    </Button>

    return (
        <Flex direction={"column"} gap={2} position={"fixed"}>
            <Flex gap={2} align={"center"} direction={"column"} mb={6}>
                <Avatar size={"sm"} src={null}/>
                <Text fontWeight={"bold"}>{item.carrier}</Text>
                <Text fontSize={"xs"}>{item.pack_code}</Text>
                <Tag colorPalette={"green"}>{item.status}</Tag>
                <Button
                    w={"100%"}
                    size={"md"}
                    variant={"ghost"}
                    colorScheme={"blue"}
                    gap={2}
                    onClick={() =>
                        window.open(dpd_link_tracking + item.pack_code, "_blank")
                    }
                >
                    <Lu.LuExternalLink/>
                    Zobrazit na webu
                </Button>
            </Flex>
            <ButtonLayer value={"customer"} label={"Zákazník"} icon={<Lu.LuUser2/>}/>
            <ButtonLayer value={"entries"} label={"Řádky dodacího listu"} icon={<Fa.FaListUl/>}/>
            <ButtonLayer value={"prepared"} label={"Očekávané zařízení"} icon={<Fa.FaMobileAlt/>}/>
            <ButtonLayer value={"timeline"} label={"Události"} icon={<Md.MdTimeline/>}/>
            <ButtonLayer value={"comments"} label={"Komentáře"} icon={<Lu.LuUser2/>}/>
            <ButtonLayer value={"notifications"} label={"Notifikace"} icon={<Io.IoMdPaperPlane/>}/>
            <ButtonLayer value={"documents"} label={"Dokumenty"} icon={<Lu.LuFileArchive/>}/>
        </Flex>
    );
}
