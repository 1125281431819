import {useEffect} from "src/items";
import {Flex, Card, SimpleGrid, Text, Checkbox} from "src/components";
import {
    DeliveryAddress,
    DeliveryContact,
    InvoiceAddress,
    InvoiceICO,
    InvoiceName,
    PickupAddress,
    PickupContact,
} from "./form-controll";
import {CheckedChangeDetails} from "@zag-js/checkbox";
import {useRedux} from "src/redux/provider";

export function Summary() {
    const {useStore, dispatch, actions} = useRedux();
    const data = useStore.service_request;
    useEffect(() => {
    }, [data]);
    return (
        <>
            <SimpleGrid columns={{base: 1, md: 4}} gap={6}>
                <Card headerElement={"Zákazník"} bodyElement={<Flex direction={"column"}>
                    <Flex direction={"row"} align={"center"} gap={6}>
                        {data.customer.first_name} {data.customer.last_name}
                    </Flex>
                    <Text>
                        {data.customer.street}, {data.customer.city},{" "}
                        {data.customer.postal_code}, {data.customer.country}
                    </Text>
                    <Text>{data.customer.email}</Text>
                    <Text>{data.customer.phone}</Text>
                </Flex>}/>
                <Card headerElement={"Fakturace"} bodyElement={<Flex direction={"column"}>
                    <Flex direction={"row"} align={"center"} gap={6}>
                        {InvoiceName()}
                    </Flex>
                    <Text>{InvoiceAddress()}</Text>
                    <Text>{InvoiceICO()}</Text>
                    <Text>{data.invoice_address?.email}</Text>
                    <Text>{data.invoice_address?.phone}</Text>
                </Flex>}/>
                <Card headerElement={"Svozová adresa"} bodyElement={<Flex direction={"column"}>
                    <Flex direction={"row"} align={"center"} gap={6}>
                        {PickupContact()}
                    </Flex>
                    <Text>{PickupAddress()}</Text>
                </Flex>}/>
                <Card headerElement={"Adresa doručení"} bodyElement={<Flex direction={"column"}>
                    <Flex direction={"row"} align={"center"} gap={6}>
                        {DeliveryContact()}
                    </Flex>
                    <Text>{DeliveryAddress()}</Text>
                </Flex>}/>

                {data.devices.map((device, index) => (
                    <Card headerElement={`Zařízení č.${index + 1}`} bodyElement={<Flex direction={"column"}>
                        <Flex direction={"row"} align={"center"} gap={6}>
                            {
                                useStore.shared.vendors.filter(
                                    (x) => x.value === device.vendor
                                )[0].label
                            }{" "}
                            - {device.model}
                        </Flex>
                        <Text>{device.imei}</Text>
                        <Text>{device.num_at_card}</Text>
                        <Text>
                            {device.is_warranty
                                ? "Záruční"
                                : `Nezáruční (Limit: ${device.estimated_repair_cost},- s DPH)`}
                        </Text>
                        <Text>{device.is_backup ? "ZÁLOHOVAT!!!" : ""}</Text>
                    </Flex>}/>
                ))}
            </SimpleGrid>
            <Flex direction={"row"} gap={6}>
                <Checkbox
                    onCheckedChange={(e: CheckedChangeDetails) =>
                        dispatch(actions.service_request.setAllOk(!!e.checked))
                    }
                    checked={data.all_is_ok}
                />
                <Text fontSize={"xs"}>Potvrzuji správnost zadaných údajů</Text>
            </Flex>
        </>
    );
}
