import {Core} from "src/types/core";

export const initialState: Core = {
    lookups: {countries: null, vendors: null},
    useDrawer: {
        open: false,
        closable: false,
        bodyElement: undefined,
        contentElement: undefined,
        footerElement: undefined,
        headerElement: undefined,
        title: undefined,
        backdrop: true,
        placement: "end",
        size: "lg",
        sidebar: false,
        sidebarItems: [],
        sidebarItem: null,
    },
    useDialog:{
        open: false,
        closable: false,
        bodyElement: undefined,
        noPadding: false,
        footerElement: undefined,
        headerElement: undefined,
        title: undefined,
        backdrop: true,
        placement: "center",
        size: "lg"
    },
    loading: false,
    iframe: false,
    sidebar: false,
    loading_text: "Načítám prostředí",
    signalr: {state: false, connection_id: "", message: ""},
    route: {
        path: "",
        name: ""
    },
    camera: {
        editor: {
            open: false,
            file: null,
            openDoctypeMenu: false
        },
        open: false,
        openFileViewer: false,
        openDoctypeMenu: false,
        openMobile: false,
        parentId: null,
        asChild: false,
        asNoClose: false,
        isConnectParent: false,
        isConnectChild: false,
        documentType: window.localStorage.getItem("scan-doc-type") ?? "SD",
        showEffect: false,
        loading: false,
        files: [],
        resolution: {
            height: 900,
            width: 1440, // 1440, 900
            facingMode: "environment",
        }
    },
    messages: []
};