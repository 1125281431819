import {Card, Fa, Flex, Tag, Text} from "src/components";

export function DetailEntries() {
    return <Flex
        direction={"column"}
        gap={2}
        align={"center"}
        w="100%"
    >
        <Card w={"100%"} bodyElement={<Flex gap={4}>
            <Fa.FaPlus/>
            <Text fontSize={"sm"}>Přidat další řádek</Text>
        </Flex>}/>
        <Card w={"100%"} cursor={"pointer"} bodyElement={<Flex direction={"row"}>
            <Flex
                width={"20%"}
                fontSize={"7xl"}
                display={{base: "none", md: "block"}}
            >
                <Fa.FaMobileAlt fontSize={72}/>
            </Flex>
            <Flex w={"80%"} direction={"column"}>
                <Flex
                    direction={{base: "column", md: "row"}}
                    gap={4}
                    justifyContent={"space-between"}
                    mb={2}
                >
                    <Text fontSize={"sm"} fontWeight={"bold"}>
                        4412515
                    </Text>
                    <Tag fontSize={"xs"} colorPalette={"green"}>
                        Opravuje se
                    </Tag>
                    <Text fontSize={"xs"}>fridrich</Text>
                </Flex>
                <Flex
                    direction={{base: "column", md: "row"}}
                    gap={4}
                >
                    <Text fontSize={"xs"}>Výrobce a typ:</Text>
                    <Text fontSize={"xs"} fontWeight={"bold"}>
                        Samsung
                    </Text>
                    <Text fontSize={"xs"} fontWeight={"bold"}>
                        SM-G988BLKETL
                    </Text>
                </Flex>
                <Flex
                    direction={{base: "column", md: "row"}}
                    gap={4}
                >
                    <Text fontSize={"xs"}>IMEI / SN:</Text>
                    <Text fontSize={"xs"} fontWeight={"bold"}>
                        35214558421541
                    </Text>
                    <Text fontSize={"xs"} fontWeight={"bold"}>
                        RF8215450
                    </Text>
                </Flex>
                <Flex
                    direction={{base: "column", md: "row"}}
                    gap={4}
                >
                    <Text fontSize={"xs"}>
                        Datum Ext.Př. / Datum příjmu:
                    </Text>
                    <Text fontSize={"xs"} fontWeight={"bold"}>
                        17.11.2024
                    </Text>
                    <Text fontSize={"xs"} fontWeight={"bold"}>
                        20.11.2024
                    </Text>
                </Flex>
            </Flex>
        </Flex>}/>
        <Card w={"100%"} bodyElement={<Text fontSize={"xs"}>Zakázka XXXXX</Text>}/>
    </Flex>;
}