import {ServiceOrder} from "src/types/service_order";


export const initialState: ServiceOrder = {
    id: 0,
    detail: {
        totalPriceProposalPrice: 0,
        items: [],
        work: [],
        files: [],
        events: [],
        comments: [],
        commentsText: "",
        status: [],
        transp: [],
        texts: [],
        lookup: [],
        priceProposal: {
            id: 0,
            info21_id: 0,
            emailTo: [],
            emailCc: [],
            currency: "",
            description: "",
        },
    },
    data: {
        data: [],
        page_size: 20,
        page_number: 1,
        total: 0,
        count: 0,
        is_next: false,
        is_prev: false,
    },
    filter: {
        docs: [],
        users: [],
        status: [],
        deviceType: [],
        vendors: [],
        smartFilter: "",
    },
    hasOpen: false,
    hasOpenAddParts: false,
    state: "",
    items: [],
    emailTo: "",
    emailCc: "",
    reseller: "",
    resellerId: "",
    resellerEmailTo: "",
    resellerEmailCc: "",
    deviceRepairType: "",
    deviceRepairTypeId: 0,
    priceProposalDesc: "",
    photoCount: 0,
    currency: "CZK",
    totalPrice: 0,
    priceProposalReason: [],
    customerName: "",
    priceProposalReasons: [],
    actionState: {
        otherAction: false
    },
    device: {
        id: 0,
        type: "",
        imei: "",
        vendor: "",
        model: "",
        serial_number: "",
        warranty: false,
        purchase_at: "",
        warranty_until: "",
        warranty_type: "",
        warranty_duration: "",
        warranty_extended: false,
        warranty_extended_until: "",
        warranty_extended_type: "",
    },
    lookup: {
        parts: []
    }
};