import {createSlice} from "@reduxjs/toolkit";
import * as reducers from "./servicerequest.reducers";
import {initialState} from "./servicerequest.init";
import {ActionsEnhancer} from "./servicerequest.actions";

const name = "serviceRequest";

const serviceRequestSlice = createSlice({
    name,
    initialState,
    reducers,
});
export const Reducer = serviceRequestSlice.reducer;
export const Actions = ActionsEnhancer(serviceRequestSlice.actions, name);

