import {
    Avatar,
    Card,
    Flex,
    Text,
    DialogRoot,
    DialogContent,
    DialogBackdrop,
    DialogBody,
    DialogHeader,
    DialogCloseTrigger,
    Input,
    useDisclosure, Button
} from "src/components";
import {CardHover} from "src/theme";
import CustomerSearch from "src/views/customers/search";

function LpModalCustomer() {
    const {open, onOpen, onClose} = useDisclosure();
    return <>
        <Card w={"100%"}
              onClick={onOpen}
              isHoverable={true}
              bodyElement={<><Text fontSize={"sm"}
                                   fontWeight={"bold"}>
                  Zákazník
              </Text>
                  <Flex
                      mt={4}
                      direction={"row"}
                      justifyContent={"space-between"}
                  >
                      <Avatar size={"sm"}/>
                      <Text fontSize={"sm"}>Jméno Příjmení</Text>
                      <Text fontSize={"sm"}></Text>
                      <Text fontSize={"sm"}>Email</Text>
                      <Text fontSize={"sm"}>Mobil</Text>
                  </Flex></>}/>

        <CustomerSearch onSearch={() => {
        }} type={"Zákazník"} open={open} onClose={onClose} onOpen={onOpen} onSelect={() => {
        }}/>
    </>
}


export function DetailCustomer() {
    return <Flex direction={"column"}
                 gap={4}
                 align={"center"}
                 w="100%">
        <LpModalCustomer/>
        <Card w={"100%"}
              isHoverable={true} bodyElement={<><Text fontSize={"sm"}
                                                      fontWeight={"bold"}>
            Fakturační adresa
        </Text>
            <Flex
                mt={4}
                direction={"row"}
                justifyContent={"space-between"}
            >
                <Avatar size={"sm"}/>
                <Text fontSize={"sm"}>Jméno Příjmení</Text>
                <Text fontSize={"sm"}></Text>
                <Text fontSize={"sm"}>Email</Text>
                <Text fontSize={"sm"}>Mobil</Text>
            </Flex></>}/>

        <Card w={"100%"}
              isHoverable={true} bodyElement={<><Text fontSize={"sm"}
                                                      fontWeight={"bold"}>
            Dodací adresa
        </Text>
            <Flex
                mt={4}
                direction={"row"}
                justifyContent={"space-between"}
            >
                <Avatar size={"sm"}/>
                <Text fontSize={"sm"}>Jméno Příjmení</Text>
                <Text fontSize={"sm"}></Text>
                <Text fontSize={"sm"}>Email</Text>
                <Text fontSize={"sm"}>Mobil</Text>
            </Flex></>}>
        </Card>
    </Flex>;
}