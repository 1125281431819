import {
    Flex,
    UseForm,
} from "src/components";
import {useRedux} from "src/redux/provider";
import React from "react";
import {InputType} from "src/enums";


export function WordsAddOrUpdate() {
    const {useStore, dispatch, actions} = useRedux();
    const data = useStore.admin.PriceProposalConfig.words.data_addorupdate;
    return (
        <Flex direction={"column"} gap={2}>
            <UseForm required
                     key={"jazyk"}
                     label={"Jazyk"}
                     value={data.language}
                     disabled={true}
                     onChange={(e) => dispatch(actions.admin.setPriceProposalWordsData({...data, language: e}))}
                     type={InputType.input}/>
            <UseForm required
                     key={"nazev"}
                     label={"Název"}
                     value={data.type}
                     onChange={(e) => dispatch(actions.admin.setPriceProposalWordsData({...data, type: e}))}
                     type={InputType.input}/>
            <UseForm required
                     key={"query"}
                     label={"Podmínka pro zobrazení"}
                     value={data.query}
                     onChange={(e) => dispatch(actions.admin.setPriceProposalWordsData({...data, query: e}))}
                     type={InputType.input}/>
            <UseForm required
                     key={"pocet_fotek"}
                     label={"Počet fotek poškození"}
                     value={data.photo_count}
                     step={1}
                     min={0}
                     max={5}
                     onChange={(e) => dispatch(actions.admin.setPriceProposalWordsData({...data, photo_count: e}))}
                     type={InputType.number}/>
            <UseForm required
                     key={"textace"}
                     label={"Textace"}
                     value={data.text}
                     rows={12}
                     onChange={(e) => dispatch(actions.admin.setPriceProposalWordsData({...data, text: e}))}
                     type={InputType.textarea}/>
        </Flex>
    )

}