import one_login_light from "src/assets/img/logo.svg";
import bg_light from "src/assets/img/bg_light.jpg";
import bg_dark from "src/assets/img/bg_dark.jpg";
import {
    Flex,
    Image,
    ColorModeButton,
    Text,
    useColorModeValue,
    Avatar,
    Button,
    Input,
    Fa,
    Tooltip,
    Field, useDisclosure,
    Box, InputGroup, Lu, Alert
} from "src/components"
import {useLocation, useEffect, useNavigate, useState} from "src/core";
import {useRedux} from "src/redux/provider";
import React from "react";
import esko_dark from "src/assets/img/seznam-logo-esko-18-cerna.svg";
import esko_light from "src/assets/img/seznam-logo-esko-18-bila.svg";
import {__server} from "src/config";
import {FlexProps} from "@chakra-ui/react";
import {QrCode} from "@ark-ui/react";
import RtsOverlay from "src/assets/img/carriers/favicon.png";
import {chakra} from "@chakra-ui/react"
import registerBiometric from "src/components/biometric";

export function Unauthorized() {
    const web_app_logo = useColorModeValue(one_login_light, one_login_light);
    const bg = useColorModeValue(bg_light, bg_dark);
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    const activated = queryParams.get("activated");
    const client_id = queryParams.get("client_id") ?? "b65c3da3-7b1c-48b4-80d7-b0fdd3da87f4";
    const redirect_uri = queryParams.get("redirect_uri") ?? "https://localhost:3000/auth/local/";
    const {useStore, dispatch, actions, dispatchAsync} = useRedux();
    const esko = useColorModeValue(esko_light, esko_dark);
    const photo = useStore.users.logged?.id ? `data:image/png;base64,${useStore.users.logged.id}` : "";
    const [qrCode, setQrCode] = useState("");
    const {username, password} = useStore.users;
    const {open, onToggle} = useDisclosure();
    const [state, setState] = useState(false);

    useEffect(() => {

        if (!useStore.users.isAuth && document.cookie.indexOf("token") > -1) {
            dispatchAsync(actions.users.fetch.GetUserDetail());
        }

        if (code && location.pathname.indexOf("auth/seznam") > -1) {
            if (code.length > 0) {
                navigate(localStorage.getItem("lastPath") ?? "/");
                dispatchAsync(actions.users.fetch.GetFromCodeSzn(code)).then(() => dispatchAsync(actions.users.fetch.GetUserDetail()));
            }
        }
        NewQrCode();
        setTimeout(() => {
            NewQrCode();
        }, 30000);
    }, []);

    const NewQrCode = () => {
        const date = new Date();
        const time = date.getTime();
        setQrCode(`${__server}/oauth/ExternalService/qr?time=${time}`);
    };

    useEffect(() => {
        if (useStore.users.statusCode === 201) {
            setTimeout(() => {
                setState(true);
            }, 3000000)
        }
    }, [state]);

    useEffect(() => {
        if (useStore.users.statusCode === 200)
            dispatchAsync(actions.users.fetch.GetUserDetail());
    }, [useStore.users.token]);

    const LogIn = () => {
        dispatchAsync(actions.users.fetch.GetAuthToken(username, password, client_id, "code", "state")).then(() => {
            setState(false);
            if (useStore.users.statusCode === 200)
                dispatchAsync(actions.users.fetch.GetUserDetail()).then(() => {
                    //registerBiometric({displayName: username, name: username})
                });
        });

    }


    const QRLogin = () => <Box bg={"white"} width={"30%"} mt={6} mb={4}>
        <QrCode.Root
            value={qrCode}
            encoding={{ecc: "H"}}
        >
            <QrCode.Frame>
                <QrCode.Pattern/>
            </QrCode.Frame>
            <QrCode.Overlay>
                <img src={RtsOverlay} alt="RtsOverlay"/>
            </QrCode.Overlay>
        </QrCode.Root>
    </Box>

    const Title = (props: FlexProps) => (
        <Flex direction={"column"} {...props}>
            <Flex justifyContent={"center"} align={"center"} direction={"column"}>
                <Image src={web_app_logo} minH={"120px"} maxH={"150px"}/>
                <Text fontSize={"6xl"}>One Login</Text>
                <Text color={"gray.500"}>Jedno přihlášení od crossui.eu</Text>
            </Flex>
            <Flex justifyContent={"center"}>
                <Text>Veškeré další funkcionality vyžadují přihlášení. Pokud zde nemáte účet tak zadejte Váš
                    email. Po zadání emailu Vám bude zaslána na zadaný email autorizační email, ve kterém
                    obdržíte odkaz, který ověří váš email. Další možností je přihlásit se pomocí jiného účtu dle nabídky
                    níže pod tlačítkem přihlásit se.</Text>
            </Flex>
        </Flex>)

    return (
        <Flex backgroundSize={"cover"}
              w={"100vw"}
              h={"100vh"}>
            <Image h={"100vh"} w={"100vw"} src={bg}/>
            <Title display={{base: "none", md: "flex"}}
                   gap={6}
                   position={"absolute"}
                   left={20}
                   top={20}
                   mt={{base: "0px", md: "10%"}}
                   w={"50%"}
            />
            <Flex position={"absolute"} direction={"column"} boxShadow={"md"} right={0} top={0}
                  w={{base: "100%", md: "40%"}}
                  h={{base: undefined, md: "100%"}}
                  p={22} px={22} background={"rgba(0,0,0,.3)"}>
                <Flex position={"absolute"} top={5} right={5}>
                    <ColorModeButton/>
                </Flex>
                <Title display={{base: "flex", md: "none"}}
                       gap={6}
                />
                <Flex direction={"column"} pt={6} gap={6} m={6}>
                    <Avatar src={photo} size={"2xl"} alignSelf={"center"}/>
                    {useStore.users.statusCode === 401 && <Flex direction={"column"} gap={6}>
                        <Alert status={"error"}>
                            Autorizační token vypršel. Je nutné se znovu přihlásit.
                        </Alert>
                    </Flex>}
                    {useStore.users.statusCode === 400 && <Flex direction={"column"} gap={6}>
                        <Alert status={"error"}>
                            Neplatné přihlašovací údaje. Zkuste to prosím znovu.
                        </Alert>
                    </Flex>}
                    {useStore.users.statusCode === 201 && <Flex direction={"column"} gap={6}>
                        <Alert status={"success"}>
                            Ověřovací email byl odeslán na Váš email: {useStore.users.username}. Ověřte svůj email a
                            poté se přihlašte.
                        </Alert>
                        <Button disabled={!state} size={"sm"} variant={"plain"} onClick={LogIn}>
                            Znovu odeslat ověřovací email
                        </Button>
                    </Flex>}
                    {useStore.users.statusCode !== 201 && (
                        <>
                            {(activated ?? "false") === "true" && (
                                <Alert status={"success"}>Váš email byl ověřen. Nyní se můžete přihlásit</Alert>)}

                            <Field required label={"E-mail / ID"}>
                                <Input onChange={(e) => dispatch(actions.users.setUserName(e.target.value))}
                                       bg={"bg.subtle"}
                                       value={username}
                                />
                            </Field>
                            <Field required label={"Heslo"}>
                                <InputGroup w={"100%"} endElement={<Button variant={"subtle"} size={"xs"}
                                                                           onClick={onToggle}>{open ? <Lu.LuEyeOff/> :
                                    <Lu.LuEye/>}</Button>}>
                                    <Input onChange={(e) => dispatch(actions.users.setPassWord(e.target.value))}
                                           bg={"bg.subtle"}
                                           type={open ? "text" : "password"}
                                           onKeyDown={(e) => {
                                               if (e.key === "Enter") {
                                                   LogIn();
                                               }
                                           }}
                                           value={password}/>
                                </InputGroup>

                            </Field>
                            <Button onClick={LogIn} variant={"subtle"}>Přihlásit / Registrovat</Button>
                            <Text color={"red"}>Neaktivní provideři pro ověření budou implementovány postupně. Zatím lze
                                použít pouze aktivní providery pro ověření.</Text>
                            <Flex direction={"row"} gap={4} w={"100%"}
                                  justifyContent={"center"}>
                                <Tooltip content={"Přihlásit se přes Google"}>
                                    <Button disabled
                                            onClick={() => window.location.assign(`${__server}/oauth/ExternalService/google/redirect`)}><Fa.FaGoogle/></Button>
                                </Tooltip>
                                <Tooltip content={"Přihlásit se přes Apple"}>
                                    <Button disabled><Fa.FaApple/></Button>
                                </Tooltip>
                                <Tooltip content={"Přihlásit se přes Facebook"}>
                                    <Button disabled><Fa.FaFacebook/></Button>
                                </Tooltip>
                                <Tooltip content={"Přihlásit se přes Microsoft"}>
                                    <Button disabled><Fa.FaMicrosoft/></Button>
                                </Tooltip>
                                <Tooltip content={"Přihlásit se přes Seznam"}>
                                    <Button onClick={() => {
                                        window.location.assign(`${__server}/oauth/ExternalService/seznam/redirect`)
                                    }}><Image height={"18px"} src={esko}/></Button>
                                </Tooltip>
                            </Flex>
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
}

export default Unauthorized;
