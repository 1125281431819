import React from "react";
import {Flex, Input, InputGroup, Lu} from "src/components";
import {AdminItem} from "src/types/admin";
import AdminPriceProposalConfig from "src/views/admin/components/PriceProposal/config";
import {Words} from "src/views/admin/components/PriceProposal/words";
import AdminRenderItem from "../admin/item";
import {Customer} from "src/views/admin/components/PriceProposal";
import {useRedux} from "src/redux/provider";
import {Template} from "src/views/admin/components/Notification";

const Items: Array<AdminItem> = [
    {
        icon: Lu.LuUser2,
        title: "Uživatelé",
        description: "Správa uživatelů",
        dispatcher: {
            open: true,
            title: "Nastavení uživatelů",
            size: "xl",
            sidebar: true,
            sidebarValue: "internal",
            sidebarItem: null,
            sidebarItems: [
                {
                    name: "Interní",
                    value: "internal",
                    icon: Lu.LuUsers,
                    element: <div>Uživatelé</div>
                },
                {
                    name: "Externí",
                    value: "external",
                    icon: Lu.LuUsers2,
                    element: <div></div>
                }
            ],
            closable: true,
        }
    },
    {
        icon: Lu.LuShield,
        title: "Aplikace a oprávnění",
        description: "Správa aplikací a oprávnění pro autorizační server",
        dispatcher: {
            open: true,
            sidebarItem: null,
            title: "Nastavení cenových nabídek",
            size: "xl",
            sidebar: true,
            sidebarValue: "config",
            sidebarItems: [
                {
                    name: "Aplikace",
                    value: "apps",
                    icon: Lu.LuAppWindow,
                    element: <></>
                },
                {
                    name: "Api klíče",
                    value: "apikeys",
                    icon: Lu.LuCode2,
                    element: <></>
                }
            ],
            closable: true,
        }
    },
    {
        icon: Lu.LuBell,
        title: "Notifikace",
        description: "Správa notifikací",
        dispatcher: {
            open: true,
            sidebarItem: null,
            title: "Nastavení notifikací, reportů a šablony pro emailové notifikace",
            size: "xl",
            sidebar: true,
            sidebarValue: "config",
            sidebarItems: [
                {
                    name: "Proměnné",
                    value: "config",
                    icon: Lu.LuVariable,
                    element: <></>
                },
                {
                    name: "Triggery",
                    value: "words",
                    icon: Lu.LuFlagTriangleLeft,
                    element: <></>
                },
                {
                    name: "Šablona",
                    value: "template",
                    icon: Lu.LuLayoutTemplate,
                    element: <Template/>
                }
            ],
            closable: true,
        }
    },
    {
        icon: Lu.LuFileSpreadsheet,
        title: "Cenové nabídky",
        description: "Správa cenových nabídek a konfigurace cenových nabídek",
        dispatcher: {
            open: true,
            sidebarItem: null,
            title: "Nastavení cenových nabídek",
            size: "xl",
            sidebar: true,
            sidebarValue: "config",
            sidebarItems: [
                {
                    name: "Obecné",
                    value: "config",
                    icon: Lu.LuCog,
                    element: <AdminPriceProposalConfig/>
                },
                {
                    name: "Dle výrobce",
                    value: "words",
                    icon: Lu.LuWholeWord,
                    element: <Words/>
                },
                {
                    name: "Dle zákazníka",
                    value: "words",
                    icon: Lu.LuUsers,
                    element: <Customer/>
                }
            ],
            closable: true,
        }
    },
]

export function Admin() {
    const {dispatchAsync, actions} = useRedux();

    const priceProposalConfig = () => dispatchAsync(actions.admin.fetch.GetConfig());

    return (
        <Flex direction={"column"} gap={4}>
            <Flex justifyContent={"flex-end"} w={"100%"}>
                <InputGroup w={"30%"} startElement={<Lu.LuSearch/>}>
                    <Input placeholder={"Co budeme hledat ?..."}/>
                </InputGroup>
            </Flex>
            <Flex direction={"column"} gap={2}>
                {Items.map((item: AdminItem) => (
                    <AdminRenderItem key={item}
                                     onBeforeLoad={() => item.title === "Cenové nabídky" ? priceProposalConfig() : {}} {...item} />
                ))}
            </Flex>
        </Flex>
    );
}