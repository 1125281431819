import {Avatar, Button, Lu, TimelineConnector, TimelineContent, TimelineItem, UseForm} from "src/components";
import {InputType} from "src/enums";
import {useRedux} from "src/redux/provider";
import {TimelineItemProps} from "@chakra-ui/react";

export interface SoDetailCommentFormProps extends TimelineItemProps {
    parent?: number | undefined;
}

export function SoDetailCommentForm(props: SoDetailCommentFormProps) {
    const {useStore, dispatchAsync, actions, dispatch} = useRedux();
    const {commentsText, so} = useStore.service_order.detail;
    const user = useStore.users.logged?.name;
    return (<TimelineItem {...props}>
        <TimelineConnector>
            <Avatar size="full" name={user}/>
        </TimelineConnector>
        <TimelineContent gap="4" w="full">
            <UseForm label={user ?? "Anonymní uživatel"}
                     type={InputType.group}
                     width={"100%"}
                     helperText={"Stiskněte Enter pro odeslání. Pro nový řádek stiskněte Shift + Enter."}
                     endElement={<Button disabled={commentsText.length < 3} onClick={() => {
                         dispatchAsync(actions.service_order.fetch.AddOrUpdateComment(so?.docNo ?? 0, commentsText, props.parent ?? -1));
                     }} variant={"subtle"} size={"xs"}><Lu.LuSend/></Button>}>
                <UseForm size={"xs"}
                         rows={2}
                         value={commentsText}
                         onChange={(e) => dispatch(actions.service_order.retrivePartialDetailData({commentsText: e}))}
                         type={InputType.textarea}
                         onKeyDown={(e) => {
                             if (e.key === "Enter" && !e.shiftKey) {
                                 dispatchAsync(actions.service_order.fetch.AddOrUpdateComment(so?.docNo ?? 0, commentsText, props.parent ?? -1));
                             }
                         }}
                         placeholder={"Napiš komentář...."}/>
            </UseForm>

        </TimelineContent>
    </TimelineItem>);
}