import type {ServiceRequest} from "src/types/service_request";
import {AddressTypes} from "src/enums";

export const initialState: ServiceRequest = {
    id: 0,
    activeStep: 0,
    signature: null,
    is_signature: false,
    is_veryfied: false,
    count_invalid_pin: 0,
    pin_no: 0,
    is_send: false,
    token_for_verify: "",
    token_verified: "",
    vendors: [],
    any_error: "",
    all_is_ok: false,
    device_edit: {
        is_open: false,
        idx: -1,
    },
    invoice_address: null,
    delivery_address: null,
    pickup_address: null,
    customer: {
        type: AddressTypes.CUSTOMER,
        street: "",
        city: "",
        postal_code: "",
        country: "CZ",
        company_id: "",
        contact_person: "",
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
    },
    devices: [],
    pickup_date: null,
    pickup_type: null,
    delivery_type: null,
    invoice_type: "1",
    invoice_company_open: false,
    selectable_address: [],
    address_dialog: {
        open: false,
        isPickup: false,
    },
};