import {
    Flex,
    UseForm,
} from "src/components";
import {useRedux} from "src/redux/provider";
import React from "react";
import {InputType} from "src/enums";


export function CustomerAddOrUpdate() {
    const {useStore, dispatch, actions} = useRedux();
    const data = useStore.admin.PriceProposalConfig.customers.data_addorupdate;
    const selected = useStore.admin.PriceProposalConfig.customers.selected;
    return (
        <Flex direction={"column"} gap={2}>
            <UseForm required
                     key={"query"}
                     label={"Podmínka"}
                     value={(data.query ?? "") === "" ? `(CardCode="${selected.value}" || DeviceReseller="${selected.value}")` : data.query}
                     helperText={"Zadej podmínku pro zákazníka '||' = nebo, '&&' = a, '!' = ne, '()' = priorita"}
                     rows={12}
                     onChange={(e) => dispatch(actions.admin.setPriceProposalCustomer({...data, query: e}))}
                     type={InputType.textarea}/>
            <UseForm key={"is_blocked"}
                     label={"Neodesílat nabídku emailem"}
                     value={data.is_block_email}
                     onChange={(e) => dispatch(actions.admin.setPriceProposalCustomer({...data, is_block_email: e}))}
                     type={InputType.checkbox}/>
            <UseForm required
                     key={"emailTo"}
                     label={"Komu"}
                     value={JSON.parse(data.emailTo ?? "[]")}
                     placeholder={"Zadej email a stiskni enter"}
                     helperText={"Zadaní příjemci obdrží cenovou nabídku. Původní příjemci budou odstraněny."}
                     onChange={(e) => dispatch(actions.admin.setPriceProposalCustomer({
                         ...data,
                         emailTo: JSON.stringify(e)
                     }))}
                     type={InputType.tagsinput}/>
            <UseForm required
                     key={"emailCc"}
                     label={"Kopie"}
                     value={JSON.parse(data.emailCc ?? "[]")}
                     placeholder={"Zadej email a stiskni enter"}
                     onChange={(e) => dispatch(actions.admin.setPriceProposalCustomer({
                         ...data,
                         emailCc: JSON.stringify(e)
                     }))}
                     helperText={"Zadaní příjemci budou v kopii emailu s cenovou nabídku. Původní příjemci budou odstraněny."}
                     type={InputType.tagsinput}/>
        </Flex>
    )

}