import {Address} from "./address-form";
import {Card_type} from "./form-controll";
import {Alert, Button, Flex, Lu, RadioCardRoot, Text,} from "src/components";
import {useRedux} from "src/redux/provider";

export function Card() {
    const {useStore, dispatch, actions} = useRedux();
    const data = useStore.service_request;
    return <Flex direction={"column"} gap={4}>
        <Address data={data.customer} isValid={() => true}/>
        <Flex direction={{base: "column", md: "row"}} gap={4}>
            <RadioCardRoot
                w={"100%"}
                value={data.invoice_type}
                onChangeCapture={(e: any) => {
                    e.stopPropagation();
                    dispatch(
                        actions.service_request.setInvoiceType(e.target.value)
                    );
                }}
            >
                <Flex
                    direction={{base: "column", md: "row"}}
                    w={"100%"}
                    gap={4}
                >
                    <Card_type
                        label={"Fakturovat běžně"}
                        descriptionTop={"Fakturační údaje zákazníka"}
                        value={"1"}
                        isPrice={false}
                    />
                    <Card_type
                        label={"Fakturovat podle IČO"}
                        descriptionTop={"Automaticky vyplníme podle IČO"}
                        descriptionBottom={
                            <>
                                <Text ms={2} mt={2} fontSize={"xs"}>
                                    <Flex direction={"column"}>
                                        <Text fontSize={"xs"}>
                                            {useStore.shared.api_ico?.subject}
                                        </Text>
                                        <Text fontSize={"xs"}>
                                            {useStore.shared.api_ico?.street}
                                        </Text>
                                        <Text fontSize={"xs"}>
                                            {useStore.shared.api_ico?.city},{" "}
                                            {useStore.shared.api_ico?.postal_code},{" "}
                                            {useStore.shared.api_ico?.country}
                                        </Text>
                                    </Flex>
                                </Text>
                                <Flex
                                    position={"absolute"}
                                    right={3}
                                    bottom={3}
                                    fontSize={"xx-large"}
                                >
                                    <Button
                                        variant={"ghost"}
                                        disabled={data.invoice_type !== "2"}
                                        onClick={() =>
                                            dispatch(
                                                actions.service_request.useInvoiceCompany(true)
                                            )
                                        }
                                    >
                                        {!data.invoice_address?.company_id ? (
                                            <Lu.LuPlus/>
                                        ) : (
                                            <Lu.LuPenLine/>
                                        )}
                                    </Button>
                                </Flex>
                            </>
                        }
                        value={"2"}
                        isPrice={false}
                    />
                    <Card_type
                        label={"Fakturovat na jinou adresu"}
                        descriptionTop={
                            data.invoice_address?.first_name ? (
                                `${data.invoice_address?.street}, ${data.invoice_address?.city}, ${data.invoice_address?.postal_code}, ${data.invoice_address?.country}`
                            ) : (
                                <Flex>
                                    <Alert
                                        title={"Adresa není doplněna"}
                                        status="warning"
                                        icon={<Lu.LuAlertTriangle/>}
                                    >
                                        Očekáváme doplnění další adresy, kterou v případě
                                        nezáruční opravy použijeme pro následnou fakturaci
                                    </Alert>
                                </Flex>
                            )
                        }
                        descriptionBottom={
                            <Flex
                                position={"absolute"}
                                right={3}
                                bottom={3}
                                fontSize={"xx-large"}
                            >
                                <Button
                                    variant={"ghost"}
                                    disabled={data.invoice_type !== "3"}
                                    onClick={() =>
                                        dispatch(
                                            actions.service_request.setAddressDialog({
                                                open: true,
                                                isPickup: false,
                                                isInvoice: true,
                                            })
                                        )
                                    }
                                >
                                    {!data.invoice_address?.first_name ? (
                                        <Lu.LuPlus/>
                                    ) : (
                                        <Lu.LuPenLine/>
                                    )}
                                </Button>
                            </Flex>
                        }
                        value={"3"}
                        isFill={true}
                    />
                </Flex>
            </RadioCardRoot>
        </Flex>
    </Flex>
}
