import {Flex, UseForm} from "src/components";
import {useRedux} from "src/redux/provider";
import {InputType} from "src/enums";
import {useState, useEffect} from "src/core";


export default function AdminPriceProposalConfig() {
    const {useStore, dispatch, actions, dispatchAsync} = useRedux();
    const data = useStore.admin.PriceProposalConfig.config;
    const time_to_expired_sec_init = data.find(x => x.vendor?.vendor_code === "-1")?.time_to_expired_sec ?? 1209600;
    const time_to_urgent_sec_init = data.find(x => x.vendor?.vendor_code === "-1")?.time_to_urgent_sec ?? 432000;
    const time_to_expired_sec = time_to_expired_sec_init === 0 ? 1209600 : time_to_expired_sec_init;
    const time_to_urgent_sec = time_to_urgent_sec_init === 0 ? 432000 : time_to_urgent_sec_init;
    const [hoursExpired, setHoursExpired] = useState(convertForSimple(time_to_expired_sec).hours);
    const [minutesExpired, setMinutesExpired] = useState(convertForSimple(time_to_expired_sec).mnutes);
    const [daysExpired, setDaysExpired] = useState(convertForSimple(time_to_expired_sec).days);
    const [secsExpired, setSecsExpired] = useState(convertForSimple(time_to_expired_sec).secs);
    const [hoursUrgent, setHoursUrgent] = useState(convertForSimple(time_to_urgent_sec).hours);
    const [minutesUrgent, setMinutesUrgent] = useState(convertForSimple(time_to_urgent_sec).mnutes);
    const [daysUrgent, setDaysUrgent] = useState(convertForSimple(time_to_urgent_sec).days);
    const [secsUrgent, setSecsUrgent] = useState(convertForSimple(time_to_urgent_sec).secs);

    useEffect(() => {
        setHoursExpired(convertForSimple(time_to_expired_sec).hours);
        setMinutesExpired(convertForSimple(time_to_expired_sec).mnutes);
        setDaysExpired(convertForSimple(time_to_expired_sec).days);
        setSecsExpired(convertForSimple(time_to_expired_sec).secs);
        setHoursUrgent(convertForSimple(time_to_urgent_sec).hours);
        setMinutesUrgent(convertForSimple(time_to_urgent_sec).mnutes);
        setDaysUrgent(convertForSimple(time_to_urgent_sec).days);
        setSecsUrgent(convertForSimple(time_to_urgent_sec).secs);
    }, [time_to_expired_sec, time_to_urgent_sec]);
    const handleChange = (e: any) => {
        dispatch(actions.admin.setPriceProposalConfigItem({
            ...data.find(x => x.vendor?.vendor_code === "-1") ?? {},
            ...e
        }))
    };

    function convertForSimple(seconds: number) {
        const days = Math.floor(seconds / (24 * 3600));
        seconds %= 24 * 3600;
        const hours = Math.floor(seconds / 3600);
        seconds %= 3600;
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return {
            days: days,
            hours: hours,
            mnutes: minutes,
            secs: secs,
        }
    }


    function convertForSeconds(days: number, hours: number, minutes: number, secs: number) {
        return days * 24 * 3600 + hours * 3600 + minutes * 60 + secs
    }

    return (<Flex direction={"column"} gap={4}>
        <UseForm onChange={(e) => {
            handleChange({time_to_expired_sec: e})
        }}
                 type={InputType.number}
                 label={"Platnost nabídky (Výchozí hodnota = 1209600) /sec"}
                 value={time_to_expired_sec}
        />
        <Flex direction={"row"} gap={2}>
            <UseForm onChange={(e) => {
                setDaysExpired(e);
                handleChange({time_to_expired_sec: convertForSeconds(e, hoursExpired, minutesExpired, secsExpired)});
            }}
                     type={InputType.number}
                     label={""}
                     value={daysExpired}
                     helperText={"den"}
            />
            <UseForm onChange={(e) => {
                setHoursExpired(e);
                handleChange({time_to_expired_sec: convertForSeconds(daysExpired, e, minutesExpired, secsExpired)});
            }}
                     type={InputType.number}
                     label={""}
                     value={hoursExpired}
                     helperText={"hodina"}
            />
            <UseForm onChange={(e) => {
                setMinutesExpired(e)
                handleChange({time_to_expired_sec: convertForSeconds(daysExpired, hoursExpired, e, secsExpired)})
            }}
                     type={InputType.number}
                     label={""}
                     value={minutesExpired}
                     helperText={"minuta"}
            />
            <UseForm onChange={(e) => {
                setSecsExpired(e);
                handleChange({time_to_expired_sec: convertForSeconds(daysExpired, hoursExpired, minutesExpired, e)})
            }}
                     type={InputType.number}
                     label={""}
                     value={secsExpired}
                     helperText={"sekunda"}
            />
        </Flex>

        <UseForm onChange={(e) => {
            handleChange({time_to_urgent_sec: e})
        }}
                 type={InputType.number}
                 label={"Urgenci odeslat po (Výchozí hodnota = 432000) /sec"}
                 value={time_to_urgent_sec}
        />
        <Flex direction={"row"} gap={2}>
            <UseForm onChange={(e) => {
                setDaysUrgent(e);
                handleChange({time_to_urgent_sec: convertForSeconds(e, hoursUrgent, minutesUrgent, secsUrgent)})
            }}
                     type={InputType.number}
                     label={""}
                     value={daysUrgent}
                     helperText={"den"}
            />
            <UseForm onChange={(e) => {
                setHoursUrgent(e);
                handleChange({time_to_urgent_sec: convertForSeconds(daysUrgent, e, minutesUrgent, secsUrgent)})
            }}
                     type={InputType.number}
                     label={""}
                     value={hoursUrgent}
                     helperText={"hodina"}
            />
            <UseForm onChange={(e) => {
                setMinutesUrgent(e);
                handleChange({time_to_urgent_sec: convertForSeconds(daysUrgent, hoursUrgent, e, secsUrgent)})
            }}
                     type={InputType.number}
                     label={""}
                     value={minutesUrgent}
                     helperText={"minuta"}
            />
            <UseForm onChange={(e) => {
                setSecsUrgent(e);
                handleChange({time_to_urgent_sec: convertForSeconds(daysUrgent, hoursUrgent, minutesUrgent, e)})
            }}
                     type={InputType.number}
                     label={""}
                     value={secsUrgent}
                     helperText={"sekunda"}
            />

        </Flex>
        <Flex direction={"row"} w={"100%"} justifyContent={"end"}>
            <UseForm onChange={() => {
                dispatchAsync(actions.admin.fetch.AddOrUpdateConfig(
                    data.find(x => x.vendor?.vendor_code === "-1") ?? {
                        id: 0,
                        vendor: {id: 0, vendor_code: "-1"},
                        time_to_expired_sec: 1209600,
                        time_to_urgent_sec: 432000,
                        is_not_use_default_text: false
                    }
                ))
            }}
                     type={InputType.button}
                     label={""}
                     width={"100%"}
                     value={secsUrgent}
                     btnVariant={"surface"}
                     children={"Uložit"}
            />
        </Flex>

    </Flex>)
}