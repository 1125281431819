import {ServiceOrder, ServiceOrderItem} from "src/types/service_order";

export const onStateChange = (
    state: ServiceOrder,
    action: { payload: { state: boolean, id: number } }
) => {
    state.hasOpen = action.payload.state;
    state.id = action.payload.id;
};

export const onModelList = (
    state: ServiceOrder,
    action: { payload: Array<ServiceOrderItem> }) => {
    state.lookup.parts = action.payload;
};

export const retriveDocsData = (state: ServiceOrder, action: { payload: typeof state.data }) => {
    state.data = action.payload;
};

export const setFilter = (state: ServiceOrder, action: { payload: typeof state.filter }) => {
    state.filter = action.payload;
}
export const onAddPart = (state: ServiceOrder, action: { payload: ServiceOrderItem }) => {
    state.items.push(action.payload);
}

export const onIncrementPart = (state: ServiceOrder, action: { payload: number }) => {
    const part = state.detail.items.find(x => x.matNo === action.payload);
    if (part) {
        if (part.quantity === undefined)
            part.quantity = 1;
        else
            part.quantity += 1;
    }
}

export const onDecrementPart = (state: ServiceOrder, action: { payload: number }) => {
    const part = state.items.find(x => x.id === action.payload);
    if (part) {
        part.quantity--;
    }
}

export const onChangeReason = (state: ServiceOrder, action: { payload: string[] }) => {
    state.priceProposalReason = action.payload;
}

export const setState = (state: ServiceOrder, action: { payload: typeof state.actionState }) => {
    state.actionState = action.payload;
}

export const retriveOcrdData = (state: ServiceOrder, action: { payload: typeof state.lookup.ocrd }) => {
    state.lookup.ocrd = action.payload;
}

export const retrieveDetailData = (state: ServiceOrder, action: { payload: typeof state.detail }) => {
    console.log(action.payload);
    state.detail = action.payload;
    state.detail.commentsText = "";
};

export const retriveFilesDetailData = (state: ServiceOrder, action: { payload: any }) => {
    console.log(action.payload);
    state.detail = {
        ...state.detail,
        files: action.payload.files,
    }
};

export const retrivePartialDetailData = (state: ServiceOrder, action: { payload: any }) => {
    state.detail = {
        ...state.detail,
        ...action.payload
    }
}
export const onDocData = (state: ServiceOrder, action: { payload: any }) => {
    state.id = action.payload.id;
    state.state = action.payload.status;
    state.device.vendor = action.payload.vendor;
    state.device.model = action.payload.model;
    state.items = action.payload.items;
    state.emailTo = action.payload.email;
    state.emailCc = action.payload.emailCc;
    state.resellerId = action.payload.resellerId;
    state.reseller = action.payload.reseller;
    state.deviceRepairType = action.payload.RepairType;
    state.deviceRepairTypeId = action.payload.RepairTypeId;
    state.resellerEmailTo = action.payload.resellerEmail;
    state.resellerEmailCc = action.payload.resellerEmailCc;
    state.priceProposalDesc = action.payload.description;
    state.photoCount = action.payload.photoCount;
    state.currency = action.payload.currency;
    state.totalPrice = action.payload.totalPrice;
    state.customerName = action.payload.customerName;
}

export const onRemovePart = (state: ServiceOrder, action: { payload: number }) => {
    state.items = state.items.filter(x => x.id !== action.payload);
};

export const onAddPartOpen = (state: ServiceOrder, action: { payload: boolean }) => {
    state.hasOpenAddParts = action.payload;
}
