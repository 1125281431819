import * as z from "zod";

export const address = z.object({
    country: z.string().min(2, "Země musí být vyplněna"),
    first_name: z.string().min(2, "Jméno musí obsahovat minimálně 2 znaky"),
    phone: z.string().min(9, "Telefonní číslo musí obsahovat minimálně 9 znaků"),
    street: z
        .string().min(2, "Ulice musí obsahovat minimálně 2 znaky").max(35, "Ulice musí obsahovat maximálně 35 znaků"),
    city: z
        .string()
        .min(2, "Město musí být vyplněno")
        .max(36, "Město musí být vyplněno"),
    email: z.string().email({message: "Email nemá správný formát"}),
    postal_code: z.string().min(5, "PSČ musí obsahovat 5 znaků"),
});


export const device_w = z.object({
    vendor: z
        .string({
            required_error: "Výrobce musí být vybrán.",
            invalid_type_error: "Výrobce musí být vybrán.",
            description: "Výrobce musí být vybrán z číselníku",
        })
        .min(1, "Výrobce musí být vybrán z číselníku")
        .max(3, "Výrobce musí být vybrán z číselníku"),
    model: z.string(),
    imei: z
        .string()
        .min(4, "IMEI/SN musí obahovat minimálně 4 znaky")
        .max(25, "IMEI/SN musí obahovat maximálně 25 znaků"),
    type: z.string(),
    serial_number: z.string(),
    damage: z.string().min(10, "Popis stavu musí mít více jak 9 znaků").max(5000),
    defect: z
        .string()
        .min(10, "Popis závady musí mít více jak 9 znaků")
        .max(5000),
    purchase_date: z
        .string({required_error: "Datum prodeje není vyplněn", invalid_type_error: "Datum prodeje není vyplněn"})
        .min(10, "Datum zakoupení musí být vyplněno"),
    is_warranty: z.boolean(),
    num_at_card: z.string(),
    is_backup: z.boolean(),
    is_failed_backup_return: z.boolean(),
    warranty_files: z
        .array(
            z.object({
                name: z.string(),
                size: z.number(),
                type: z.string(),
            })
        )
        .min(1, "Musí být vybrán alespoň jeden soubor"),
});

export const device_oow = z.object({
    vendor: z
        .string({
            required_error: "Výrobce musí být vybrán.",
            invalid_type_error: "Výrobce musí být vybrán.",
            description: "Výrobce musí být vybrán z číselníku",
        })
        .min(1, "Výrobce musí být vybrán z číselníku")
        .max(3, "Výrobce musí být vybrán z číselníku"),
    model: z.string(),
    imei: z
        .string()
        .min(4, "IMEI/SN musí obahovat minimálně 4 znaky")
        .max(25, "IMEI/SN musí obahovat maximálně 25 znaků"),
    type: z.string(),
    serial_number: z.string(),
    damage: z.string().min(10).max(5000),
    defect: z.string().min(10).max(5000),
    is_warranty: z.boolean(),
    estimated_repair_cost: z.number().min(500),
    num_at_card: z.string(),
    is_backup: z.boolean(),
    is_failed_backup_return: z.boolean(),
});

export const email = z.string().email({message: "Email není správný"});
