import React from "react";
import {useRts} from "src/provider";


export function CustomerAddOrUpdateAction() {
    const {UseDialog, Redux, Components} = useRts();
    const {Button} = Components;
    const {useStore, dispatchAsync, actions} = Redux();
    const editState = useStore.admin.PriceProposalConfig.customers.data_addorupdate;
    const data = useStore.admin.PriceProposalConfig.customers.data;
    return (
        <Button size={"sm"} variant={"surface"}
                onClick={() => {
                    dispatchAsync(actions.admin.fetch.AddOrUpdateCustomers({
                        data: editState,
                        page_size: data.page_size,
                        page_number: data.page_number,
                        search: `customer="${editState.customer}"`
                    })).then(() => {
                        UseDialog({...useStore.core.useDialog, onClose: true});
                    });

                }}>{editState.id > 0 ? "Uložit a zavřít" : "Přidat a zavřít"}
        </Button>
    )

}