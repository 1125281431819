import {Avatar, Flex, Text} from "src/components";
import React from "react";
import {useRedux} from "src/redux/provider";

export default function SoDetailSidebarHeader() {
    const {useStore} = useRedux();
    const data = useStore.service_order.detail;
    return <Flex direction={"column"} justifyContent={"center"} align={"center"} gap={2} mb={6}>
        <Avatar
            size={"2xl"}
            borderRadius={"12px"}
            src={"https://bit.ly/broken-link"}
        />
        <Text fontSize={"sm"} fontWeight={"bold"}>
            {data?.omrc?.firmName}
        </Text>
        <Text fontSize={"sm"}>{data?.deviceType?.typeName}</Text>
    </Flex>
}