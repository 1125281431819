import * as signalR from "@microsoft/signalr";
import Cookies from 'js-cookie';

const __host = window.location.host;
let __access_token = Cookies.get("token")??"";
let __reconnect = false;
let __hub = "/rtsconnect";
if (__host === "localhost:3000") __hub = "https://localhost:7082/rtsconnect";

export const hubConnect = new signalR.HubConnectionBuilder()
    .withUrl(__hub, {
        withCredentials: true,
        accessTokenFactory: () => __access_token,
    })
    //.configureLogging(signalR.LogLevel.Information)
    .build();

let connectStateCallback: (state: signalR.HubConnection) => void;
let changeConnectionCallback: (state: boolean, stateMsg: string, connectionId: string) => void;

export const onConnectState = (
    callback: (state: signalR.HubConnection) => void
) => {
    connectStateCallback = callback;
};

export const onChangeConnection = (
    callback: (state: boolean, stateMsg: string, connectionId: string) => void
) => {
    changeConnectionCallback = callback;
};

const startConnection = () => {
    hubConnect
        .start()
        .then(() => {
            __reconnect = false;
            if (connectStateCallback) connectStateCallback(hubConnect);
            if (changeConnectionCallback) changeConnectionCallback(true, "Připojení k aplikačnímu serveru bylo navázáno.", hubConnect.connectionId ?? "");
        })
        .catch((err: any) => {
            __reconnect = false;
            if (connectStateCallback) connectStateCallback(hubConnect);
            if (changeConnectionCallback) changeConnectionCallback(false, "Nepodařilo se navázat připojení k aplikačnímu serveru.", "");
            setTimeout(startConnection, 5000);
        });
};

startConnection();

hubConnect.onclose(() => {
    if (changeConnectionCallback && !__reconnect) changeConnectionCallback(false, "Připojení k aplikačnímu serveru bylo ukončeno.", "");
    if (changeConnectionCallback && __reconnect) changeConnectionCallback(false, "Probíhá přihlašování uživatele k aplikačnímu serveru.", "");
    setTimeout(startConnection, 1000);
});
hubConnect.onreconnected(() => {
    if (changeConnectionCallback) changeConnectionCallback(true, "Připojení k aplikačnímu serveru bylo obnoveno.", hubConnect.connectionId ?? "");
});
hubConnect.onreconnecting(() => {
    if (changeConnectionCallback) changeConnectionCallback(false, "Probíhá pokus o obnovení připojení k aplikačnímu serveru.", "");
});

const Login = (message: any) => {
    if (message === null)
        return;

    if (!message.is_success)
        return;

    if (message.response === null)
        return;

    if (message.response.length !== 1)
        return;

    __access_token = message.response[0].access_token;
    window.localStorage.setItem("token", __access_token);
    if (hubConnect.state === signalR.HubConnectionState.Connected) {
        __reconnect = true;
        hubConnect.stop();
    } else {
        startConnection();
    }
};

hubConnect.on("VerifyToken", (message: any) => {
    Login(message);
});
hubConnect.on("UsersLoginOAuth2", (message: any) => {
    Login(message);
});

export const sendMessage = (methodName: string, message: any) => {
    hubConnect.send(methodName, message).catch((err: any) => console.error(err));
};

export const sendMessageWithTimeout = (
    methodName: string,
    message: any,
    timeout: number = 30000
): Promise<any> => {
    return new Promise((resolve, reject) => {

        const timer = setTimeout(() => {
            hubConnect.off(methodName);
            reject(new Error("Timeout"));
        }, timeout);

        hubConnect.on(methodName, (response: any) => {
            clearTimeout(timer);
            hubConnect.off(methodName);
            resolve(response);
        });

        hubConnect.send(methodName, message).catch((err) => {
            clearTimeout(timer);
            hubConnect.off(methodName);
            reject(err);
        });
    });
};

// Sledování změn cookie
const observeCookieChange = (cookieName: string, callback: (newValue: string) => void) => {
    let lastValue = Cookies.get(cookieName);
    setInterval(() => {
        const currentValue = Cookies.get(cookieName);
        if (currentValue !== lastValue) {
            lastValue = currentValue;
            callback(currentValue ?? "");
        }
    }, 1000); // Kontrola každou sekundu
};

observeCookieChange("token", (newToken) => {
    __access_token = newToken;
    if (hubConnect.state === signalR.HubConnectionState.Connected) {
        __reconnect = true;
        hubConnect.stop();
    } else {
        startConnection();
    }
});

export const onMessageReceived = (
    methodName: string,
    callback: (message: any) => void
) => {
    hubConnect.on(methodName, callback);
};

export const offMessageReceived = (
    methodName: string,
    callback: (message: any) => void
) => {
    hubConnect.off(methodName, callback);
};

export default hubConnect;
