import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import type {AppDispatch, StoreState} from "src/redux";
import {actions} from "src/redux";
import React, {useContext, createContext, useEffect} from "react";
import {Dispatch, UnknownAction} from "@reduxjs/toolkit";
import {useSignalR} from "src/lib/signalR.provider";

export const useAppSelector: TypedUseSelectorHook<StoreState> = useSelector;

export type ReduxContextType = {
    useStore: StoreState;
    dispatch: <A extends UnknownAction, T extends A>(action: T, ...extraArgs: any[]) => Promise<void>;
    dispatchAsync: <A extends UnknownAction, T extends A>(action: T, ...extraArgs: any[]) => Promise<void>;
    actions: typeof actions;
}

const ReduxContext = createContext<ReduxContextType | undefined>(
    undefined
);

export const useRedux = () => {
    const context = useContext(ReduxContext);
    if (!context) {
        throw new Error("useRedux must be used within an DispatchContext");
    }
    return context;
};
export const ReduxProvider:
    React.FC<{ children: React.ReactNode }> = (props) => {
    const {onConnectState} = useSignalR();
    const dispatcher = useDispatch<AppDispatch>();


    async function dispatchAsync<A extends UnknownAction, T extends A>(action: T, ...extraArgs: any[]): Promise<void> {
        try {
            dispatcher(actions.core.setState(true));
            await dispatcher(action, ...extraArgs);
            dispatcher(actions.core.setState(false));
        } catch (e) {
            dispatcher(actions.core.newMessage({
                message: JSON.stringify(e),
                serverity: "error",
                title: "Error",
                duration: 5000
            }));
            dispatcher(actions.core.setState(false));
        }
    }

    async function dispatch<A extends UnknownAction, T extends A>(action: T, ...extraArgs: any[]): Promise<void> {
        try {
            await dispatcher(action, ...extraArgs);
        } catch (e) {
            dispatcher(actions.core.newMessage({
                message: JSON.stringify(e),
                serverity: "error",
                title: "Error",
                duration: 5000
            }));
        }
    }

    useEffect(() => {
        onConnectState((state) => {
            dispatcher(actions.core.setConnection({
                state: state.state,
                connectionId: state.connectionId,
                message: ""
            }))
        });
    }, []);

    const store = useAppSelector((state: StoreState) => state);

    return (
        <ReduxContext.Provider value={{useStore: store, dispatch, dispatchAsync, actions}}>
            {props.children}
        </ReduxContext.Provider>
    );
};
