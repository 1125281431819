import {createSlice} from "@reduxjs/toolkit";
import * as reducers from "./serviceorder.reducers";
import {initialState} from "./serviceorder.init";
import {ActionsEnhancer} from "./serviceorder.actions";

const name = "service-order";

const serviceOrderSlice = createSlice({
    name,
    initialState,
    reducers,
});
export const Reducer = serviceOrderSlice.reducer;
export const Actions = ActionsEnhancer(serviceOrderSlice.actions, name);

