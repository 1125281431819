import {Result} from "src/types/signalR";
import {CoreMessage} from "src/types/core";
import {__sandBoxEndpoint, __server} from "src/config";
import {ConvertToIFiles} from "src/lib";
import {IFile} from "src/types/shared";

async function ErrorMessages<T>(action: any, store: any, response: Result<T>) {
    if (!response.is_success)
        store.dispatch({
            type: "core/newMessage", result: null, payload: {
                title: response.is_success ? "Úspěch" : "Chyba",
                message: response.message,
                serverity: response.is_success ? "success" : "error",
                duration: 5000
            } as CoreMessage
        })
}

async function fetchHelper<T>(action: any, store: any) {
    return await fetch(__server + action.endpoint, {
        method: (action.method ?? "GET"),
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: action.payload ? JSON.stringify(action.payload) : null
    }).then((response) => {
        if (response.status === 401)
            throw new Error(JSON.stringify({
                message: "Autorizační token vypršel. Je nutné se znovu přihlásit.",
                statusCode: 401
            }));
        if (response.status === 400)
            throw new Error(JSON.stringify({message: "Špatné zadání", statusCode: 400}));
        if (response.status === 403)
            throw new Error(JSON.stringify({message: "Přístup odmítnut", statusCode: 403}));
        if (response.status === 500)
            throw new Error(JSON.stringify({
                message: "Chyba na straně serveru. Kontaktujte nás na emailu: IT@britex.cz",
                statusCode: 500
            }));
        return response.json() as T;
    }).catch((err: any) => {
        const result = JSON.parse(err.message);
        ErrorMessages(action, store, {
            is_success: false,
            message: result.message,
            response: null,
            statusCode: result.statusCode ?? 500,
            exception: null
        });
        return {
            is_success: false,
            message: result.message,
            response: null,
            statusCode: result.statusCode ?? 500,
            exception: null
        }
    }) as Result<T>;
}

async function SandBoxHelper<T>(action: any, store: any) {
    let converted = await ConvertToIFiles(action.payload);
    const result = await fetchHelper({
        ...action,
        method: "POST",
        endpoint: __sandBoxEndpoint,
        payload: {files: converted}
    }, store).then((response: any) => response);
    let org: IFile[] = [];
    let timeouted: IFile[] = [];
    result.response?.map((file: any) => {
        org.push({
            name: file.fileName,
            record_id: file.fileId,
            size: 0,
            type: "",
        } as IFile);
        timeouted.push({
            name: file.fileName,
            record_id: -999,
            size: 0,
            type: "",
        } as IFile);
    });
    return {
        origin: org,
        timeouted: timeouted
    };
}

export function fetchMiddleware(store: any) {
    return (next: any) => async (action: any) => {
        if (action) {
            if ((action.from ?? "") === "fetch") {
                const result = await fetchHelper<Result<any>>(action, store).then((response) => {
                    if (response.statusCode === 201)
                        store.dispatch({
                            type: "core/newMessage", result: null, payload: {
                                title: response.is_success ? "Úspěch" : "Chyba",
                                message: (response.response ?? "").toString(),
                                serverity: response.is_success ? "success" : "error",
                                duration: 5000
                            } as CoreMessage
                        })
                    
                    if (action.type === "users/GetAuthToken")
                        store.dispatch({
                            type: "users/setStatusCode", result: null, payload: response.statusCode
                        })

                    return response;
                });
                if (result.statusCode < 400)
                    return next({
                        ...action,
                        payload: result?.response
                    });
            } else if ((action.from ?? "") === "sandbox") {
                next(action);
                const result = await SandBoxHelper<Result<any>>(action, store).then((response) => response);
                setTimeout(() => {
                    next({
                        ...action,
                        payload: result.origin
                    });
                }, 3000);
                return next({
                    ...action,
                    payload: result.timeouted
                });
            } else
                return next(action);
        }
    }
}