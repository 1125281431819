import {useDisclosure} from "src/items";
import {
    Checkbox,
    Input,
    Button,
    Lu,
    DrawerRoot,
    DrawerBackdrop,
    DrawerContent,
    DrawerHeader,
    DrawerBody,
    Flex,
    Tabs,
    Tooltip,
    Fa,
    Fieldset,
    Field,
    SelectRoot,
    createListCollection,
    SelectLabel,
    RadioCardRoot,
    RadioCardItem,
    DrawerFooter,
    Card,
    Text,
    RadioCardLabel,
    Ai,
    InputGroup
} from "src/components";

export function Create() {
    const {open, onOpen, onClose} = useDisclosure();
    return (
        <>
            <Button aria-label="Objednat přepravu" onClick={onOpen} size={"sm"} variant={"subtle"}>
                <Lu.LuTruck/>
            </Button>
            <DrawerRoot open={open} size={"md"}>
                <DrawerBackdrop/>
                <DrawerContent>
                    <DrawerHeader fontSize={"md"}>
                        Objednávka přepravy
                    </DrawerHeader>
                    <DrawerBody>
                        <Flex gap={2} direction={"column"} w={"100%"}>
                            <Tabs.Root position="relative" w={"100%"}>
                                <Tabs.List
                                    fontSize={"sm"}
                                    w="100%"
                                    justifyContent={"space-around"}
                                >
                                    <Tabs.Trigger value="customer" fontSize={"sm"}>
                                        <Tooltip content="Zákazník">
                                            <Fa.FaUser/>
                                        </Tooltip>
                                    </Tabs.Trigger>
                                    <Tabs.Trigger value="entries">
                                        <Tooltip content="Řádky dodacího listu">
                                            <Fa.FaListUl/>
                                        </Tooltip>
                                    </Tabs.Trigger>
                                </Tabs.List>

                                <Tabs.Content value="customer">
                                    <Fieldset.Root>
                                        <Fieldset.Content>
                                            <Field label="Země">
                                                <SelectRoot
                                                    collection={createListCollection({
                                                        items: [{value: "CZ", label: "Česká Republika"}],
                                                    })}
                                                >
                                                    <SelectLabel>Select framework</SelectLabel>
                                                </SelectRoot>
                                            </Field>
                                            <Field label="Kontaktní osoba">
                                                <Input/>
                                            </Field>
                                            <Field label="Ulice a ČP">
                                                <Input/>
                                            </Field>
                                            <Field label="Město">
                                                <Input/>
                                            </Field>
                                            <Field label="PSČ">
                                                <Input mask={"999 99"} w="100"/>
                                            </Field>
                                            <Field label="Telefon">
                                                <Input mask={"+499 999 999 999"} w="100"/>
                                            </Field>
                                            <Field label="Email">
                                                <Input/>
                                            </Field>
                                            <Field label="Reference">
                                                <Input/>
                                            </Field>
                                            <Field label="">
                                                <RadioCardRoot>
                                                    <RadioCardLabel>Typ přepravy</RadioCardLabel>
                                                    <RadioCardItem
                                                        label={"Svozová přeprava"}
                                                        icon={<Ai.AiOutlineUpload/>}
                                                        indicator={false}
                                                        key={"pickup"}
                                                        value={"1"}
                                                    />
                                                    <RadioCardItem
                                                        label={"Rozvozová přeprava"}
                                                        icon={<Ai.AiOutlineUpload/>}
                                                        indicator={false}
                                                        key={"pickup"}
                                                        value={"2"}
                                                    />
                                                </RadioCardRoot>
                                                <Flex direction={"row"} mt={4} gap={2}>
                                                    <Checkbox/>
                                                    <Text fontSize={"xs"}>Dobírka</Text>
                                                </Flex>
                                                <Flex direction={"row"} mt={4} gap={2}>
                                                    <InputGroup endElement={<Fa.FaSearch/>}>
                                                        <Input placeholder="Cena dobírky"/>
                                                    </InputGroup>
                                                </Flex>
                                            </Field>
                                        </Fieldset.Content>
                                    </Fieldset.Root>
                                </Tabs.Content>
                                <Tabs.Content value="entries">
                                    <Flex
                                        direction={"column"}
                                        gap={2}
                                        align={"center"}
                                        w="100%"
                                    >
                                        <Card.Root w={"100%"} bg={""}>
                                            <Card.Body justifyContent={"center"}>
                                                <Flex gap={4}>
                                                    <Fa.FaPlus/>
                                                    <Text fontSize={"sm"}>
                                                        Přidat další řádek
                                                    </Text>
                                                </Flex>
                                            </Card.Body>
                                        </Card.Root>
                                        <Card.Root w={"100%"}>
                                            <Card.Body>
                                                <Text fontSize={"xs"}>Zakázka XXXXX</Text>
                                            </Card.Body>
                                        </Card.Root>
                                        <Card.Root w={"100%"}>
                                            <Card.Body>
                                                <Text fontSize={"xs"}>Zakázka XXXXX</Text>
                                            </Card.Body>
                                        </Card.Root>
                                    </Flex>
                                </Tabs.Content>
                            </Tabs.Root>
                        </Flex>
                    </DrawerBody>
                    <DrawerFooter>
                        <Flex direction={"row"} gap={4} justify={"space-between"}>
                            <Button variant={"ghost"} onClick={onClose}>
                                Zrušit
                            </Button>
                            <Button variant={"outline"} onClick={onClose}>
                                Objednat
                            </Button>
                        </Flex>
                    </DrawerFooter>
                </DrawerContent>
            </DrawerRoot>
        </>
    );
}
