import {Admin} from "src/types/admin";
import {PriceProposalConfig} from "src/types/admin/price_proposal_config";


export const setPriceProposalConfigState = (state: Admin, action: { payload: any }) => {
    state.PriceProposalConfig.config = action.payload ?? [];
}

export const setNotificationTemplate = (state: Admin, action: { payload: string }) => {
    state.Notification.template = action.payload;
}
export const setPriceProposalConfig = (
    state: Admin,
    action: { payload: any }
) => {
    if (state.PriceProposalConfig.config.length === 0) {
        state.PriceProposalConfig.config = [{
            id: 0,
            time_to_expired_sec: 1209600,
            time_to_urgent_sec: 432000,
            is_not_use_default_text: false,
            vendor: {
                id: 0,
                vendor_code: "-1",
            }
        }];
    }
    state.PriceProposalConfig.config = action.payload.config ?? [];
    state.PriceProposalConfig.customers.lookup = action.payload.customers;
}

export const RetrievePriceProposalConfigCustomers = (state: Admin, action: {
    payload: typeof state.PriceProposalConfig.customers.data
}) => {
    state.PriceProposalConfig.customers.data = action.payload;
}

export const setPriceProposalConfigItem = (
    state: Admin,
    action: { payload: PriceProposalConfig }
) => {
    if (state.PriceProposalConfig.config.length === 0) {
        state.PriceProposalConfig.config = [{
            id: 0,
            time_to_expired_sec: 1209600,
            time_to_urgent_sec: 432000,
            is_not_use_default_text: false,
            vendor: {
                id: 0,
                vendor_code: "-1",
            }
        }];
    }

    //vyhledáme si podle vendor_code
    const index = state.PriceProposalConfig.config.findIndex(x => x.vendor?.vendor_code === action.payload.vendor?.vendor_code);

    if (index === -1) {
        state.PriceProposalConfig.config.push(action.payload);
    } else {
        state.PriceProposalConfig.config[index] = action.payload;
    }
}
export const RetrievePriceProposalConfigWords = (state: Admin, action: {
    payload: typeof state.PriceProposalConfig.words.data
}) => {
    state.PriceProposalConfig.words.data = action.payload;
}

export const setPriceProposalConfigWordsSelectedVendor = (state: Admin, action: { payload: string }) => {
    state.PriceProposalConfig.words.data_addorupdate.vendor.vendor_code = action.payload;
};

export const setPriceProposalWords = (state: Admin, action: { payload: typeof state.PriceProposalConfig.words }) => {
    state.PriceProposalConfig.words = action.payload;
}

export const setPriceProposalCustomerSelected = (state: Admin, action: {
    payload: typeof state.PriceProposalConfig.customers.selected
}) => {
    state.PriceProposalConfig.customers.selected = action.payload;
    state.PriceProposalConfig.customers.data_addorupdate.customer = action.payload.value;
}

export const setPriceProposalCustomer = (state: Admin, action: {
    payload: typeof state.PriceProposalConfig.customers.data_addorupdate
}) => {
    state.PriceProposalConfig.customers.data_addorupdate = action.payload;
}

export const setPriceProposalWordsData = (state: Admin, action: {
    payload: typeof state.PriceProposalConfig.words.data_addorupdate
}) => {
    state.PriceProposalConfig.words.data_addorupdate = action.payload;
}

export const setPriceProposalCustomerData = (state: Admin, action: {
    payload: typeof state.PriceProposalConfig.customers.data_addorupdate
}) => {
    state.PriceProposalConfig.customers.data_addorupdate = action.payload;
}

