import React from "react";
import SoPriceProposalTextDialog from "./so.detail.priceproposal.text.dialog";
import {useRts} from "src/provider";

export default function SoPriceProposalText() {
    const {UseDialog, Redux, useEffect} = useRts();
    const {useStore} = Redux();
    const data = useStore.service_order.detail;
    const state = useStore.core.useDialog.open;
    let text = data.so?.priceProposalDefectDesc ?? "";

    useEffect(() => {
        const regex = /{{(.*?)}}/g;
        const found = text.match(regex);
        let match = regex.exec(text);
        let match0 = match?.[0] ?? "";
        let splits = text.split(match0);
        if (found && !state)
            UseDialog({
                open: true,
                backdrop: true,
                noPadding: false,
                bodyElement: <SoPriceProposalTextDialog splits={splits} match0={match0} text={text}/>,
                title: "Uprav textaci",
                closable: false,
            });
    }, [text]);


    return (<></>)
}