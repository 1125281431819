import {Admin} from "src/types/admin";

export const initialState: Admin = {
    Notification: {
        template: ""
    },
    PriceProposalConfig: {
        state: false,
        config: [],
        customers: {
            lookup: [],
            selected: {value: "", label: ""},
            data: {
                page_size: 50,
                page_number: 1,
                data: [],
                total: 0,
                count: 0,
                is_next: false,
                is_prev: false,
            },
            data_addorupdate: {
                id: 0,
                is_block_email: false,
                query: "",
                emailTo: "[]",
                emailCc: "[]",
            }
        },
        words: {

            data_addorupdate: {
                id: 0,
                text: "",
                language: "CZ",
                type: "",
                photo_count: 0,
                vendor: {
                    id: 0,
                    vendor_code: "0",
                }
            },
            data: {
                data: [],
                page_number: 1,
                page_size: 20,
                total: 0,
                count: 0,
                is_next: false,
                is_prev: false,
            }
        },

    }
};