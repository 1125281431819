import {DataToQueryParams, Generate} from "src/redux/enhancer";
import {RequestModel, SingleRequestModel} from "src/types/core";
import {PriceProposalCustomer, PriceProposalDefaultTexts} from "src/types/rts";
import {EnhancersFrom} from "src/enums";
import {PriceProposalConfig} from "src/types/admin/price_proposal_config";

export const PriceProposalAdminfetchActions = (storeName: string) => {
    return {
        AddOrUpdateDefaultTexts: (data: RequestModel<PriceProposalDefaultTexts>) => Generate(storeName,
            data, 'RetrievePriceProposalConfigWords',
            EnhancersFrom.fetch, undefined,
            "/api/admin/AddOrUpdatePriceProposalTexts", "POST"),
        AddOrUpdateCustomers: (data: RequestModel<PriceProposalCustomer>) => Generate(storeName,
            data, 'RetrievePriceProposalConfigCustomers',
            EnhancersFrom.fetch, undefined,
            "/api/admin/AddOrUpdatePriceProposalCustomer", "POST"),
        GetPriceProposalDefaultText: (data: SingleRequestModel) => Generate(storeName,
            null, 'RetrievePriceProposalConfigWords',
            EnhancersFrom.fetch, undefined,
            `/api/admin/GetPriceProposalDefaultText${DataToQueryParams(data)}`, "GET"),
        GetPriceProposalCustomer: (data: SingleRequestModel) => Generate(storeName,
            null, 'RetrievePriceProposalConfigCustomers',
            EnhancersFrom.fetch, undefined,
            `/api/admin/GetPriceProposalCustomer${DataToQueryParams(data)}`, "GET"),
        GetConfig: () => Generate(storeName,
            null, 'setPriceProposalConfig',
            EnhancersFrom.fetch, undefined,
            "/api/admin/GetConfig", "GET"),
        AddOrUpdateConfig: (data: PriceProposalConfig) => Generate(storeName,
            data, 'setPriceProposalConfigState',
            EnhancersFrom.fetch, undefined,
            "/api/admin/AddOrUpdateConfig", "POST"),
    }
};
