import {
    Flex,
    Button, UseForm, Navigations
} from "src/components";
import React from "react";
import {WordsAddOrUpdate} from "./words.addorupdate";
import {InputType} from "src/enums";
import {WordsAddOrUpdateAction}
    from "./words.addorupdate.action";
import {WordsItem} from "./words.item";
import {useRts} from "src/provider";


export function Words() {
    const {UseDialog, Redux} = useRts();
    const {useStore, dispatch, actions, dispatchAsync} = Redux();

    const vendors = useStore.shared.vendors
        .filter(x => Number(x.value) > -99)
        .map(x => x.value === "-1" ? {...x, label: "-> Všichni výrobci"} : x)
        .sort((a, b) => a.label.localeCompare(b.label));
    const editState = useStore.admin.PriceProposalConfig.words.data_addorupdate;
    const config = useStore.admin.PriceProposalConfig.config
        .find(x => x.vendor?.vendor_code === editState.vendor.vendor_code) ?? {
        id: 0,
        is_not_use_default_text: false,
        vendor: editState.vendor,
        time_to_expired_sec: 0,
        time_to_urgent_sec: 0
    };
    const data = {
        ...useStore.admin.PriceProposalConfig.words.data,
        onPageSize: (e?: number) => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: 1,
            page_size: e || useStore.admin.PriceProposalConfig.words.data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        })),
        onNext: () => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: data.page_number + 1,
            page_size: data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        })),
        onPrev: () => dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: data.page_number - 1,
            page_size: data.page_size,
            search: `vendor.vendor_code="${editState.vendor.vendor_code}"`
        }))
    };
    const handleAdd = () => {
        dispatch(actions.admin.setPriceProposalWordsData({
            ...editState,
            id: 0,
            text: "",
            type: "",
            photo_count: 0,
            vendor: editState.vendor
        }));
        UseDialog({
            open: true,
            title: editState.id > 0 ? "Upravit větu pro výrobce" : "Přidat větu pro výrobce",
            size: "xl",
            noPadding: false,
            bodyElement: <WordsAddOrUpdate/>,
            footerElement: <WordsAddOrUpdateAction/>,
            closable: true,
        });
    };


    const handleRetrieve = (e: any) => {
        dispatch(actions.admin.setPriceProposalConfigWordsSelectedVendor(e[0]))
        dispatchAsync(actions.admin.fetch.GetPriceProposalDefaultText({
            page_number: data.page_number,
            page_size: data.page_size,
            search: `vendor.vendor_code="${e[0]}"`
        }));
    }

    return (
        <>
            <Flex direction={"column"} gap={2}>
                <UseForm required
                         key={"vendors"}
                         label={"Výrobce"}
                         value={(editState.vendor.vendor_code ?? "0") == "0" ? undefined : [editState.vendor.vendor_code]}
                         onChange={handleRetrieve}
                         placeholder={"-- Vyber výrobce --"}
                         collection={vendors}
                         type={InputType.select}/>
                {editState.vendor.vendor_code !== "-1" && editState.vendor.vendor_code !== "0" && editState.vendor.vendor_code !== undefined && (
                    <>
                        <UseForm key={"default_text"}
                                 disabled={editState.vendor.vendor_code === "0" || editState.vendor.vendor_code === undefined}
                                 label={"Základní texty"}
                                 required={true}
                                 helperText={!(config.is_not_use_default_text ?? false) ? "Základní texty se nyní používají." : "Základní texty se nyní nepoužívají."}
                                 children={!(config.is_not_use_default_text ?? false) ? "Nepoužívat základní texty" : "Používat základní texty"}
                                 value={(config.is_not_use_default_text ?? false)}
                                 btnVariant={"surface"}
                                 width={"100%"}
                                 onChange={(e) => {
                                     dispatchAsync(actions.admin.fetch.AddOrUpdateConfig({
                                         ...config,
                                         id: config.id,
                                         is_not_use_default_text: !(config.is_not_use_default_text ?? false),
                                         vendor: editState.vendor,
                                         time_to_expired_sec: config?.time_to_expired_sec ?? 0,
                                         time_to_urgent_sec: config?.time_to_urgent_sec ?? 0
                                     }))
                                 }}
                                 type={InputType.button}/>
                        <UseForm required
                                 key={"whss"}
                                 label={"Sklady"}
                                 value={config.whs ?? []}
                                 placeholder={"Zapiš sklad a stiskni enter"}
                                 helperText={"Skladová zásoba se dohledává ve skladech výše definovaných."}
                                 onChange={(e) => dispatchAsync(actions.admin.fetch.AddOrUpdateConfig({
                                     ...config,
                                     id: config.id,
                                     whs: e,
                                     is_not_use_default_text: !(config.is_not_use_default_text ?? false),
                                     vendor: editState.vendor,
                                     time_to_expired_sec: config?.time_to_expired_sec ?? 0,
                                     time_to_urgent_sec: config?.time_to_urgent_sec ?? 0
                                 }))}
                                 type={InputType.tagsinput}/>
                    </>
                )}

                <Button size={"sm"} variant={"surface"}
                        mt={4}
                        disabled={editState.vendor.vendor_code === "0" || editState.vendor.vendor_code === undefined}
                        onClick={handleAdd}>Přidat
                    větu pro výrobce</Button>

                <Navigations
                    mb={{mb: 2}}
                    type=""
                    data={data}
                    renderComponent={WordsItem}
                />
            </Flex>
        </>
    );
}