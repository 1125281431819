import React from "react";
import {CardHover} from "src/theme";
import {PriceProposalDefaultTexts} from "src/types/rts";
import {WordsAddOrUpdate} from "./words.addorupdate";
import {WordsAddOrUpdateAction}
    from "./words.addorupdate.action";
import {useRts} from "src/provider";

export function WordsItem(props: { item: PriceProposalDefaultTexts }) {
    const {UseDialog, Redux, Components} = useRts();
    const {Card, Flex, Avatar, Text, Lu, Fa} = Components;
    const {useStore, dispatch, actions} = Redux();
    return (
        <Card.Root key={props.item.id} css={CardHover.card.hover} variant={"subtle"} size={"sm"}
                   onClick={() => {
                       dispatch(actions.admin.setPriceProposalWordsData(props.item))
                       UseDialog({
                           open: true,
                           title: "Upravit větu pro výrobce",
                           size: "xl",
                           noPadding: false,
                           bodyElement: <WordsAddOrUpdate/>,
                           footerElement: <WordsAddOrUpdateAction/>,
                           closable: true,
                       })
                   }}>
            <Card.Body>
                <Flex
                    direction={"row"}
                    gap={4}
                    align={"center"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Flex direction={"row"} gap={4}>
                        <Avatar icon={<></>}><Lu.LuPencil/></Avatar>
                        <Flex direction={"column"}>
                            <Text fontSize={"sm"} fontWeight={"bold"}>
                                {props.item.type}
                            </Text>
                            <Text fontSize={"xs"}>{props.item.vendor.vendor_name}</Text>
                        </Flex>
                    </Flex>
                    <Fa.FaArrowRight/>
                </Flex>
            </Card.Body>
        </Card.Root>
    )

}