import {FileUploadDropzone, FileUploadList, FileUploadRoot, Flex, UseForm} from "src/components";
import {useRedux} from "src/redux/provider";
import {InputType} from "src/enums";

export default function SoDetailDocuments() {
    const {useStore, dispatch, actions} = useRedux();
    const {files} = useStore.service_order.detail;

    return (
        <Flex direction={"column"} gap={2}>
            <UseForm
                key={"files_docno"}
                label={"Dokumenty"}
                files={files}
                value=""
                onChange={() => {
                }}
                type={InputType.file}/>

        </Flex>
    );
}
