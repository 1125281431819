import Enhancer, {Generate} from "src/redux/enhancer";
import {EnhancersFrom} from "src/enums";
import {signalR} from "src/enums";
import {CameraFile} from "src/types/camera";
import {LoginOAuth2} from "src/redux/features/users/users.reducers";

export const signalRActions = (storeName: string) => {
    return {
        LogIn: (code: string) =>
            Generate(storeName, {token: code}, "LogIn", EnhancersFrom.signalR, signalR.users.usersLoginOAuth2),
        Auth: () =>
            Generate(storeName, {is_authorize: true}, "Auth", EnhancersFrom.signalR, signalR.users.isAuthorize),

    }
}
export const fetchActions = (storeName: string) => {
    return {
        GetFromCodeSzn: (code: string) =>
            Generate(storeName, null, "GetAuthToken", EnhancersFrom.fetch, undefined, "/oauth/ExternalService/seznam/token/" + code, "GET"),
        LogOut: () =>
            Generate(storeName, null, "LogOut", EnhancersFrom.fetch, undefined, "/api/v4/user/logout", "GET"),
        GetUserDetail: () =>
            Generate(storeName, null, "GetUserDetail", EnhancersFrom.fetch, undefined, "/api/v4/user", "GET"),
        GetAuthToken: (user: string, password: string, client_id: string, response_type: string, state: string) =>
            Generate(storeName, {
                username: user,
                password,
                client_id,
                response_type,
                state
            }, "GetAuthToken", EnhancersFrom.fetch, undefined, "/api/login", "POST"),
    }
};

export function ActionsEnhancer<T>(action: T, storeName: string) {
    return Enhancer(action, signalRActions(storeName), fetchActions(storeName), {});
}