import {Button, Card, Flex, Input, Lu, Tag, Text} from "src/components";
import React from "react";
import {useRedux} from "src/redux/provider";

export function DetailWork() {
    const {useStore, dispatchAsync, actions} = useRedux();
    const data = useStore.service_order.detail.work;
    const items = useStore.service_order.detail.devTypeSparePart;
    return (
        <Flex w={"100%"} direction={"column"} gap={2}>
            <Button variant={"subtle"} aria-label="Přidat položku" gap={2} alignContent={"center"}>
                <Lu.LuPlus/> Přidat položku
            </Button>
            {data.map((item) => (
                <Card key={`items_${item.workNo}`} w={"100%"} bodyElement={<Flex justifyContent={"space-between"}>
                    <Flex direction={"column"}>
                        <Text>
                            {item.workItemCode}
                        </Text>
                        <Text fontSize={"xs"}>{item.itemName}</Text>
                    </Flex>
                    <Flex direction={"column"}>
                        <Flex justifyContent={"end"}>
                            <Tag>{item.pricePropUnitPrice} {useStore.service_order.currency}</Tag>
                        </Flex>
                        <Flex justifyContent={"end"}>
                            <Tag
                                colorPalette={"green"}>{item.statusName}</Tag>
                        </Flex>
                        <Flex justifyContent={"end"}>
                            <Button variant={"ghost"}
                                    size={"sm"}
                                    disabled={true}
                                    onClick={() => dispatchAsync(actions.service_order.fetch.changeQtyPart(item.docNo ?? 0, 0, item.workNo ?? 0, ((item.quantity ?? 0) + 1)))}><Lu.LuPlus/></Button>
                            <Input w={"20%"} size={"sm"} value={item.quantity} disabled/>
                            <Button variant={"ghost"}
                                    size={"sm"}
                                    disabled={true}
                                    onClick={() => dispatchAsync(actions.service_order.fetch.changeQtyPart(item.docNo ?? 0, 0, item.workNo ?? 0, ((item.quantity ?? 0) - 1)))}><Lu.LuMinus/></Button>
                            <Button variant={"ghost"}
                                    size={"sm"}
                                    disabled={true}
                                    onClick={() => dispatchAsync(actions.service_order.fetch.removePart(item.docNo ?? 0, 0, item.workNo ?? 0, ""))}><Lu.LuTrash/></Button>
                        </Flex>
                    </Flex>
                </Flex>}/>
            ))}
        </Flex>
    );
}
