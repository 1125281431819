import {useEffect, useLocation} from "src/items";
import {useRedux} from "src/redux/provider";
import {setCameraStandalone} from "src/redux/features/core/core.reducers";

/*
    Tento komponent slouží k připojení kamery. Získá connectionId z query parametru a uloží ho do reduxu jako parentId kamery.
    Spustí kameru a zobrazí ji. Následně se zobrazí CameraActionPanel, který obsahuje tlačítko pro pořízení snímku kamery.
    Tento sníme se přepošle na server. A server vrátí sha256 hashe souboru, který si následně parent komponenta stáhne.
    
    FROM QueryString {connectionId} - id nadřízeného kamery
    
*/
export function CameraConnect() {
    //zjistíme si connectionId z query parametru a uložíme ho do reduxu jako parentId kamery.
    //Spustíme kameru
    const {dispatch, actions} = useRedux();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const connectionId = searchParams.get("connectionId");
    const docNo = searchParams.get("docNo");

    useEffect(() => {
        if (connectionId)
            dispatch(actions.core.setCameraParentId(connectionId));

        if (docNo)
            dispatch(actions.core.setCameraStandalone());
    }, []);
    return (
        <></>
    );
}
